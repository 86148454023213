import { useCallback, useRef } from "react";
import { Tween } from "@tweenjs/tween.js";
import { ANIMATION_DURATION, EASING_FUNCTION } from "./animation";
import { Level } from "../../store/types";
import { DateTime, Interval } from "luxon";
import { useAtom, useSetAtom } from "jotai";
import { animationTargetAtom, positionAtom, staticPositionAtom } from "./atoms/atoms";
import { Position } from "./types";

export type AnimationFunc = (animationLevel: Level, target: { interval: Interval; level: Level }) => void;

export const useAnimateAll = (onSetStaticPosition: (position: Position) => void): AnimationFunc => {
  const [staticPosition] = useAtom(staticPositionAtom);
  const setPosition = useSetAtom(positionAtom);
  const setAnimationTarget = useSetAtom(animationTargetAtom);

  const tween = useRef<Tween<any>>();
  const frameCounter = useRef(0);
  const startTime = useRef<number>();

  const animate = useCallback(() => {
    window.requestAnimationFrame(() => {
      if (!tween.current) {
        return;
      }
      frameCounter.current += 1;
      tween.current.update();
      animate();
    });
  }, []);

  return useCallback<AnimationFunc>(
    (animationLevel, animationTarget) => {
      if (tween.current) {
        console.log("Animation already running, cannot start");
        return;
      }
      setAnimationTarget(animationTarget);
      if (!staticPosition) {
        return;
      }
      setPosition({ interval: staticPosition?.interval, level: animationLevel });
      startTime.current = performance.now();
      tween.current = new Tween({
        start: staticPosition.interval.start.toMillis(),
        end: staticPosition.interval.end.toMillis(),
      })
        .to(
          {
            start: animationTarget.interval.start.toMillis(),
            end: animationTarget.interval.end.toMillis(),
          },
          ANIMATION_DURATION
        )
        .easing(EASING_FUNCTION)
        .onUpdate((frame) => {
          const frameStart = DateTime.fromMillis(frame.start);
          const frameEnd = DateTime.fromMillis(frame.end);
          setPosition({ interval: Interval.fromDateTimes(frameStart, frameEnd), level: animationLevel });
        })
        .onComplete(() => {
          const diff = performance.now() - (startTime.current || 0);
          console.log(
            `FPS ${Math.round((frameCounter.current / diff) * 1000)} (${
              frameCounter.current
            } / ${ANIMATION_DURATION}ms)`
          );
          frameCounter.current = 0;
          startTime.current = 0;
          tween.current = undefined;

          setAnimationTarget(undefined);
          onSetStaticPosition({ interval: animationTarget.interval, level: animationTarget.level });
        })
        .start();
      animate();
    },
    [animate, staticPosition, setAnimationTarget, setPosition, onSetStaticPosition]
  );
};

import { Dispatch, useEffect, useRef, useState } from "react";
import { SensorBrowserActions, SensorBrowserState } from "./reducer";
import { getExtendedFromNowTimeframe } from "../../utils/interval";

export const useRealtimeInterval = (
  sensorBrowserState: SensorBrowserState,
  sensorBrowserDispatch: Dispatch<SensorBrowserActions>
) => {
  const [refresh, setRefresh] = useState(0);
  const prevRefresh = useRef(0);

  useEffect(() => {
    // Only react to changes in refresh counter
    if (refresh === prevRefresh.current) {
      return;
    }
    prevRefresh.current = refresh;

    sensorBrowserDispatch({
      type: "setTimeframe",
      payload: {
        interval: getExtendedFromNowTimeframe(sensorBrowserState.timeframe),
        level: sensorBrowserState.level,
        timeframe: sensorBrowserState.timeframe,
        viewType: sensorBrowserState.viewType,
      },
    });
  }, [
    refresh,
    sensorBrowserDispatch,
    sensorBrowserState.level,
    sensorBrowserState.timeframe,
    sensorBrowserState.viewType,
  ]);

  useEffect(() => {
    // Run the handler in a timeout because otherwise document.hidden
    // may still be set when switching back to this tab again
    const handler = () =>
      setTimeout(() => {
        if (document.hidden) {
          return;
        }
        setRefresh((prev) => prev + 1);
      }, 100);

    window.addEventListener("focus", handler);
    const timer = setInterval(handler, 60 * 1000);

    return () => {
      window.removeEventListener("focus", handler);
      clearInterval(timer);
    };
  }, []);
};

import { Form, Input, Button, Select } from "antd";
import {
  ORG_TYPE_VESIOSUUSKUNTA,
  ORG_TYPE_VESILAITOS,
  ORG_TYPE_YRITYS,
  ORG_TYPE_YKSITYINEN,
  ORG_TYPE_MUU,
  orgTypeString,
} from "../constants";
import { Organization } from "../../../store/types";

export const OrganizationEditor = ({
  onSubmit,
  initialValues,
}: {
  onSubmit: (org: Organization) => void;
  initialValues?: Organization;
}) => {
  return (
    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} onFinish={onSubmit} initialValues={initialValues}>
      <Form.Item
        label={"Nimi"}
        name={"name"}
        rules={[{ required: true, message: "Nimi on pakollinen" }]}
        valuePropName={"value"}
      >
        <Input />
      </Form.Item>

      <Form.Item label={"Tyyppi"} name={"typeId"} rules={[{ required: true, message: "Tyyppi on pakollinen" }]}>
        <Select>
          <Select.Option value={ORG_TYPE_VESIOSUUSKUNTA}>{orgTypeString(ORG_TYPE_VESIOSUUSKUNTA)}</Select.Option>
          <Select.Option value={ORG_TYPE_VESILAITOS}>{orgTypeString(ORG_TYPE_VESILAITOS)}</Select.Option>
          <Select.Option value={ORG_TYPE_YRITYS}>{orgTypeString(ORG_TYPE_YRITYS)}</Select.Option>
          <Select.Option value={ORG_TYPE_YKSITYINEN}>{orgTypeString(ORG_TYPE_YKSITYINEN)}</Select.Option>
          <Select.Option value={ORG_TYPE_MUU}>{orgTypeString(ORG_TYPE_MUU)}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label={"Y-Tunnus"} name={"businessId"}>
        <Input />
      </Form.Item>

      <Form.Item label={"Kuvaus"} name={"description"}>
        <Input />
      </Form.Item>

      <Form.Item label={"Osoite"} name={"address1"}>
        <Input placeholder={"Katuosoite"} />
      </Form.Item>

      <Form.Item label={"Osoite"} name={"address2"}>
        <Input placeholder={"Postinumero ja toimipaikka"} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 10 }}>
        <Button type="primary" htmlType="submit">
          Tallenna
        </Button>
      </Form.Item>
    </Form>
  );
};

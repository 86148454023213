import { Dispatch, useCallback } from "react";
import { Select } from "antd";
import { SensorBrowserActions, SensorBrowserState } from "./reducer";
import { getExtendedFromNow } from "../../utils/interval";
import { Level, ViewType } from "../../store/types";
import { TimeFrameOptions } from "./constants";

export const TimeFrameSelect = ({
  sensorBrowserState,
  sensorBrowserDispatch,
}: {
  sensorBrowserState: SensorBrowserState;
  sensorBrowserDispatch: Dispatch<SensorBrowserActions>;
}): JSX.Element | null => {
  const handleSelect = useCallback(
    (value: string) => {
      if (value === "24h") {
        sensorBrowserDispatch({
          type: "setTimeframe",
          payload: {
            interval: getExtendedFromNow(24, "hour"),
            level: Level.hour,
            timeframe: "24h",
            viewType: ViewType.Auto,
          },
        });
      }
      if (value === "30d") {
        sensorBrowserDispatch({
          type: "setTimeframe",
          payload: {
            interval: getExtendedFromNow(30, "day"),
            level: Level.day,
            timeframe: "30d",
            viewType: ViewType.Auto,
          },
        });
      }
      if (value === "30d-min") {
        sensorBrowserDispatch({
          type: "setTimeframe",
          payload: {
            interval: getExtendedFromNow(30, "day"),
            level: Level.day,
            timeframe: "30d-min",
            viewType: ViewType.Min,
          },
        });
      }
      if (value === "12m") {
        sensorBrowserDispatch({
          type: "setTimeframe",
          payload: {
            interval: getExtendedFromNow(12, "month"),
            level: Level.month,
            timeframe: "12m",
            viewType: ViewType.Auto,
          },
        });
      }
      if (value === "20y") {
        sensorBrowserDispatch({
          type: "setTimeframe",
          payload: {
            interval: getExtendedFromNow(20, "year"),
            level: Level.year,
            timeframe: "20y",
            viewType: ViewType.Auto,
          },
        });
      }
    },
    [sensorBrowserDispatch]
  );

  return (
    <Select
      defaultValue={TimeFrameOptions.find((o) => o.value === sensorBrowserState.timeframe)?.label}
      options={TimeFrameOptions}
      style={{ width: 200 }}
      onSelect={handleSelect}
    />
  );
};

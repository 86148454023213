import { baseUrl } from "../../constants";
import { KeyType2, Level, Sensor } from "../types";
import { JSInterval } from "../utils";

// Backend expects this format for query parameters when requesting values
// The time needs to be in UTC
const getApiTimeFormat = (time: number): string => {
  const date = new Date(time);
  const yyyy = date.getUTCFullYear();
  const MM = String(date.getUTCMonth() + 1).padStart(2, "0");
  const dd = String(date.getUTCDate()).padStart(2, "0");
  const HH = String(date.getUTCHours()).padStart(2, "0");
  const mm = String(date.getUTCMinutes()).padStart(2, "0");
  const ss = String(date.getUTCSeconds()).padStart(2, "0");
  return yyyy + MM + dd + HH + mm + ss;
};

export const apiFetch = async (url: string) => {
  const response = await window.fetch(url, {
    credentials: "include",
  });
  return await response.json();
};

export const getUrl = (sensor: Sensor, level: Level, interval: JSInterval): string => {
  const url = new URL(`/api/devices/${sensor.deviceId}/keys/${sensor.key}/values`, baseUrl);

  if (sensor.keyType2 === KeyType2.Accumulating) {
    if (level === Level.raw) {
      url.searchParams.append("function", "lmin");
    } else {
      if (level === Level.day) {
        url.searchParams.append("function", "totalWithStats");
      } else {
        url.searchParams.append("function", "total");
      }
      url.searchParams.append("interval", level);
    }
  } else if (sensor.keyType2 === KeyType2.TimeSeries) {
    if (level === Level.raw) {
      url.searchParams.append("function", "raw");
    } else {
      url.searchParams.append("function", "avg");
      url.searchParams.append("interval", level);
    }
  }

  url.searchParams.append("from", getApiTimeFormat(interval.start));
  url.searchParams.append("to", getApiTimeFormat(interval.end));
  return url.toString();
};

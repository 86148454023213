import { Table } from "../../../components/Table/Table";
import { useGetDevicesQuery } from "../../../store/api";
import { dateRenderer, checkRenderer } from "../../../components/Table/cellRenderer";
import { DeviceCreateModal } from "./DeviceCreateModal";
import { MutableRefObject, useCallback, useMemo, useState } from "react";
import { ColDef } from "ag-grid-community";
import { LinkButton } from "../../../components/LinkButton";
import { getAdminDeviceInfoRoute } from "../../../utils/routes";
import { useOutletContext } from "react-router-dom";
import { TableState } from "../../../components/Table/interfaces";

export const AdminDeviceList = () => {
  const devices = useGetDevicesQuery();
  const [addDeviceModal, setAddDeviceModal] = useState(false);

  const tableState = useOutletContext<MutableRefObject<TableState | undefined>>();

  const columns = useMemo<ColDef[]>(
    () => [
      {
        field: "deviceId",
        headerName: "ID",
        cellRenderer: (cell: any) => <LinkButton path={getAdminDeviceInfoRoute(cell.value)} text={cell.value} />,
      },
      {
        field: "name",
        headerName: "Nimi",
        width: 400,
      },
      {
        field: "initialized",
        headerName: "Alustettu",
        cellRenderer: checkRenderer,
      },
      {
        field: "created",
        headerName: "Luotu",
        sort: "desc",
        cellRenderer: dateRenderer,
        getQuickFilterText: dateRenderer,
      },
    ],
    []
  );

  const handleAdd = useCallback(() => setAddDeviceModal(true), []);
  return (
    <>
      <Table
        tableState={tableState}
        rowData={devices.data}
        loading={devices.isFetching}
        onAdd={handleAdd}
        columns={columns}
      />
      {addDeviceModal && <DeviceCreateModal onCancel={() => setAddDeviceModal(false)} />}
    </>
  );
};

import { Button, Form, Input, message, Select } from "antd";
import { Device } from "../../../../store/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useEditDeviceMutation, useGetDevicesQuery, useGetDeviceTypesQuery } from "../../../../store/api";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";

export const DeviceEditorForm = ({ device, mode }: { device: Device; mode: "admin" | "user" }) => {
  const deviceTypes = useGetDeviceTypesQuery(undefined, { skip: mode !== "admin" });
  const devices = useGetDevicesQuery();
  const [editDevices] = useEditDeviceMutation();
  const [dirty, setDirty] = useState<Device | undefined>();
  const [reset, setReset] = useState(0);

  const deviceTypeOptions = useMemo(() => {
    if (mode === "admin") {
      return deviceTypes.data?.map((dt) => ({ label: dt.name, value: dt.deviceTypeId }));
    }
    if (!device.deviceType) {
      return [];
    }
    return [{ label: device.deviceType?.name, value: device.deviceType?.deviceTypeId }];
  }, [device.deviceType, deviceTypes.data, mode]);

  const handleReset = useCallback(() => {
    setDirty(undefined);
    setReset((prev) => prev + 1);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!dirty) {
      return;
    }
    try {
      await editDevices(dirty).unwrap();
      message.success("Tiedot tallennettu");
    } catch (err) {
      console.error("Saving device", err);
    }
  }, [dirty, editDevices]);

  // After saving, the cache invalidation will trigger re fetch of devices and when that's done we reset this
  useEffect(() => {
    if (!devices.isFetching) {
      handleReset();
    }
  }, [devices.isFetching, handleReset]);

  if (!device || deviceTypes.isLoading) {
    return null;
  }

  const dirtyDevice = dirty || device;

  return (
    <Form key={reset} labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} onFinish={handleSubmit}>
      <Form.Item label={"Nimi"}>
        <Input value={dirtyDevice.name} onChange={(e) => setDirty({ ...dirtyDevice, name: e.target.value })} />
      </Form.Item>

      <Form.Item label={"Kuvaus"}>
        <Input
          value={dirtyDevice.description}
          onChange={(e) => setDirty({ ...dirtyDevice, description: e.target.value })}
        />
      </Form.Item>

      <Form.Item label={"Osoite"}>
        <Input value={dirtyDevice.address} onChange={(e) => setDirty({ ...dirtyDevice, address: e.target.value })} />
      </Form.Item>

      <Form.Item label={"Paikkakunta"}>
        <Input value={dirtyDevice.locality} onChange={(e) => setDirty({ ...dirtyDevice, locality: e.target.value })} />
      </Form.Item>

      <Form.Item label={"Käyttöpaikka"}>
        <Input value={dirtyDevice.siteId} onChange={(e) => setDirty({ ...dirtyDevice, siteId: e.target.value })} />
      </Form.Item>

      <Form.Item label={"Laitetyyppi"}>
        <Select
          disabled={mode !== "admin"}
          allowClear
          defaultValue={deviceTypeOptions?.find((o) => o.value === device?.deviceType?.deviceTypeId)}
          onSelect={(idx, option) =>
            setDirty({ ...dirtyDevice, deviceType: { deviceTypeId: option.value, name: option.label } })
          }
          onClear={() => setDirty({ ...dirtyDevice, deviceType: null })}
          options={deviceTypeOptions}
        />
      </Form.Item>

      <Form.Item label={"Muistiinpanot"}>
        <TextArea
          rows={4}
          value={dirtyDevice.notes}
          onChange={(e) => setDirty({ ...dirtyDevice, notes: e.target.value })}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 10 }}>
        <Buttons>
          <Button disabled={!dirty} onClick={handleReset}>
            Peruuta
          </Button>
          <Button disabled={!dirty} type="primary" htmlType="submit">
            Tallenna
          </Button>
        </Buttons>
      </Form.Item>
    </Form>
  );
};

const Buttons = styled.div`
  display: flex;
  gap: 10px;
`;

import { useGetOrganizationsQuery } from "../../../store/api";
import { useMemo } from "react";
import { Select } from "antd";

export const OrganizationSelect = ({
  defaultOrganizationId,
  onSelect,
  onClear,
}: {
  defaultOrganizationId: string | null;
  onSelect: (organizationId: string) => void;
  onClear: () => void;
}): JSX.Element | null => {
  const organizations = useGetOrganizationsQuery();

  const options = useMemo<{ label: string; value: string }[]>(() => {
    if (!organizations.data) {
      return [];
    }
    return organizations.data
      .map((o) => ({
        value: o.organizationId,
        label: o.name,
      }))
      .slice()
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
  }, [organizations.data]);

  if (!options.length) {
    return null;
  }

  return (
    <Select
      defaultValue={options.find((o) => o.value === defaultOrganizationId)?.value}
      showSearch
      placeholder={"Organisaatio"}
      allowClear
      options={options}
      style={{ width: 200 }}
      filterOption={(input, option) => (option?.label || "").toLowerCase().includes(input.toLowerCase())}
      onSelect={onSelect}
      onClear={onClear}
    />
  );
};

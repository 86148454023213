import { GraphState } from "../reducer";
import { AXIS_LINE_COLOR_ZERO_ONLY } from "./constants";
import { getY } from "../utils";

export const renderZeroAxis = (
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  graphState: GraphState
): void => {
  ctx.beginPath();

  ctx.strokeStyle = AXIS_LINE_COLOR_ZERO_ONLY;
  ctx.textAlign = "right";
  ctx.textBaseline = "middle";
  ctx.fillStyle = "black";

  const series = graphState.series.find((s) => s.scaleSide === "left");
  if (!series || !series.sensor.keyType) {
    return;
  }

  for (
    let yValue = graphState.yAxis["left"].start;
    yValue < graphState.yMaxValue["left"];
    yValue += graphState.yAxis["left"].step
  ) {
    const yPixel = getY(graphState, "left", yValue);
    if (yPixel > graphState.height - graphState.padding.bottom) {
      // too low to draw
      continue;
    }
    if (yValue === 0) {
      ctx.moveTo(graphState.padding.left, yPixel);
      ctx.lineTo(graphState.width - graphState.padding.right, yPixel);
    }
  }

  ctx.stroke();
};

import { Level, Sensor } from "../types";
import { DateTime, Duration, Interval } from "luxon";
import { apiFetch, getUrl } from "./fetch";
import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getGaps } from "./utils";
import { sensorLevel2ValueEnum } from "../utils";
import { getReduxId } from "./types";
import { fetchIntervalBegin, fetchIntervalError, fetchIntervalSuccess, refreshLevel } from "./reducer";

export const fetchValues =
  (
    sensor: Sensor,
    level: Level,
    interval: Interval,
    nowThreshold?: Duration
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const intervalUntilNow = interval.splitAt(DateTime.now())[0];
    let requestedIntervals = getState().values.sensors[getReduxId(sensor)]?.requests?.[level];
    const lastRequestEnd = requestedIntervals?.at(-1)?.end || 0;
    const thresholdToRefresh = Date.now() - (nowThreshold?.toMillis() || 0);
    if (!nowThreshold || lastRequestEnd < thresholdToRefresh) {
      // Cleanup requests that may since have been covered with new data
      // Do this often, since reloading only on sse event is not 100% reliable
      dispatch(refreshLevel(sensor, level));
      requestedIntervals = getState().values.sensors[getReduxId(sensor)]?.requests?.[level];
    }

    const gaps = getGaps(
      { start: intervalUntilNow.start.toMillis(), end: intervalUntilNow.end.toMillis() },
      requestedIntervals
    ).filter(
      (gap) =>
        // Don't do micro increments each time the graph is scrolled by just a tiny fraction
        !nowThreshold || gap.start < thresholdToRefresh
    );

    for (const gap of gaps) {
      try {
        if (!sensor.keyType2) {
          throw new Error(`keyType2 is not defined for sensor ${sensor.deviceId}-${sensor.key}`);
        }

        dispatch(fetchIntervalBegin(sensor, level, gap));

        const url = getUrl(sensor, level, gap);
        const valueEnum = sensorLevel2ValueEnum(sensor.keyType2, level);
        const data = (await apiFetch(url)) as any[];
        const values = data.map((v) => {
          const start = new Date(v.from);
          const end = new Date(v.to);
          const half = (end.getTime() - start.getTime()) / 2;
          return {
            _type: valueEnum,
            start: v.from,
            startMs: start.getTime(),
            end: v.to,
            endMs: end.getTime(),
            timestampMs: start.getTime() + half,
            value: v.value,
            avg: v.avg,
            min: v.min,
            max: v.max,
            samples: v.samples,
            rawValue: v.rawValue,
          };
        });
        dispatch(fetchIntervalSuccess(sensor, level, gap, values));
      } catch (err) {
        console.error(err);
        dispatch(fetchIntervalError(sensor, level, gap));
      }
    }
  };

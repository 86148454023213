import { SeriesData } from "../types";
import { GraphState } from "../reducer";
import { getX, getY } from "../utils";
import { ValueEnum } from "../../../store/types";

export const renderLine = (
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  graphState: GraphState,
  series: SeriesData
) => {
  series.visibleValues.forEach((value) => {
    if (value._type === ValueEnum.AVG) {
      return;
    }
    const x = getX(graphState, value.timestampMs);
    const y = getY(graphState, series.scaleSide, value.value);

    ctx.lineTo(x, y);
  });

  ctx.strokeStyle = series.color;
  ctx.stroke();
};

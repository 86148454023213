import { useParams, Link, Outlet } from "react-router-dom";
import { Breadcrumb } from "antd";
import { useGetDevicesQuery } from "../../../store/api";
import { LayoutContentGrid } from "../../../utils/styled";
import { useMemo, useRef } from "react";
import { TableState } from "../../../components/Table/interfaces";

export const AdminDevice = () => {
  const { deviceId } = useParams();
  const devices = useGetDevicesQuery();
  const device = devices.data?.find((d) => d.deviceId === deviceId);
  const deviceListTableState = useRef<TableState>();

  const breadcrumbs = useMemo(() => {
    if (device) {
      return [{ title: <Link to={"../devices"}>Laitteet</Link> }, { title: `${device.deviceId}: ${device.name}` }];
    }
    return [
      {
        title: "Laitteet",
      },
    ];
  }, [device]);

  return (
    <LayoutContentGrid>
      <Breadcrumb items={breadcrumbs} />
      <Outlet context={deviceListTableState} />
    </LayoutContentGrid>
  );
};

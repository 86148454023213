import { notification } from "antd";
import { useEffect } from "react";
import { useGetCurrentUserQuery } from "./store/api";
import { DEMO_USER_ID } from "./components/constants";

export const ReleaseNotification = () => {
  const [api, contextHolder] = notification.useNotification();
  const currentUser = useGetCurrentUserQuery();

  useEffect(() => {
    if (localStorage.getItem("newUiNotification")) {
      return;
    }
    if (!currentUser.data?.userId) {
      return;
    }
    if (currentUser.data.userId === DEMO_USER_ID) {
      return;
    }

    localStorage.setItem("newUiNotification", "yes");
    api.info({
      message: `Tervetuloa uudistettuun palveluun`,
      description: (
        <>
          Olemme uusineet käyttöliittymän.
          <br />
          <br />
          <a target="_blank" rel="noreferrer" href="https://www.vilkase.fi/2024/12/05/uuden-kayttoliittyman-julkaisu/">
            Lue lisää tiedotteesta
          </a>
          .
        </>
      ),
      placement: "top",
      duration: 0,
    });
  }, [api, currentUser.data?.userId]);

  return <>{contextHolder}</>;
};

import { ContentCard } from "../../../../utils/styled";
import { useAddDeviceTypeMutation, useGetDeviceTypesQuery } from "../../../../store/api";
import styled from "styled-components";
import { DeviceTypeInput } from "./DeviceTypeInput";
import { Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export const DeviceTypes = () => {
  const deviceTypes = useGetDeviceTypesQuery();
  const [addDeviceType] = useAddDeviceTypeMutation();

  const handleAdd = async () => {
    await addDeviceType("").unwrap();
    message.success("Lisätty");
  };

  if (deviceTypes.isLoading) {
    return null;
  }

  return (
    <ContentCard title={"Laitetyypit"}>
      <Layout>
        {deviceTypes.data?.map((dt) => (
          <DeviceTypeInput key={dt.deviceTypeId} deviceTypeId={dt.deviceTypeId} defaultName={dt.name} />
        ))}
        <Button icon={<PlusOutlined />} shape={"circle"} onClick={handleAdd} type={"primary"} />
      </Layout>
    </ContentCard>
  );
};

const Layout = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

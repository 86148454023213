import { GraphState } from "./reducer";
import { DateTimeUnit, Interval } from "luxon";
import { levelToLuxonUnit } from "../../store/utils";
import { AvgValue, KeyType, Level, ValueEnum, ValueTypes } from "../../store/types";
import { Padding, ScaleSide, SeriesData } from "./types";

export const getX = (
  state: { interval: Interval; padding: Padding; xPixelFactor: number },
  timestamp: number
): number => {
  const diff = timestamp - state.interval.start.toMillis();
  return state.padding.left + diff * state.xPixelFactor;
};

export const getUnitIntervals = (unit: DateTimeUnit, interval: Interval): Interval[] => {
  const duration = { [unit]: 1 };

  let start = interval.start.startOf(unit);
  const intervals: Interval[] = [];
  while (start < interval.end) {
    const end = start.plus(duration);
    intervals.push(Interval.fromDateTimes(start, end));
    start = end;
  }
  return intervals;
};

export const getLevelIntervals = (level: Level, interval: Interval, prevIntervals?: Interval[]): Interval[] => {
  const luxonUnit = levelToLuxonUnit(level);
  if (!luxonUnit) {
    return [interval];
  }

  if (!prevIntervals?.length) {
    return getUnitIntervals(luxonUnit, interval);
  }

  const prevFirst = prevIntervals[0];
  const prevLast = prevIntervals[prevIntervals.length - 1];

  if (prevLast.end <= interval.start || prevFirst.start >= interval.end) {
    return getUnitIntervals(luxonUnit, interval);
  }

  const head = (
    interval.start < prevFirst.start
      ? getUnitIntervals(luxonUnit, Interval.fromDateTimes(interval.start, prevFirst.start))
      : []
  ) as Interval[];
  const tail = (
    prevLast.end < interval.end ? getUnitIntervals(luxonUnit, Interval.fromDateTimes(prevLast.end, interval.end)) : []
  ) as Interval[];
  head.push(...prevIntervals);
  head.push(...tail);
  return head.filter((i) => !(i.end <= interval.start || i.start >= interval.end));
};

export const getY = (state: GraphState, scaleSide: ScaleSide, value: number): number =>
  state.height -
  state.padding.bottom -
  state.yPixelFactor[scaleSide] * value +
  state.yPixelFactor[scaleSide] * state.yMinValue[scaleSide];

const BLUE = "#4096ff";
const RED = "#f29d9d";
const GREEN = "#53ad55";
const ORANGE = "#ffab51";

const COLOR_BY_SENSOR_TYPE: { [key in KeyType]: string } = {
  [KeyType.Temperature]: RED,
  [KeyType.Energy]: GREEN,
  [KeyType.Voltage]: ORANGE,
  [KeyType.Accumulator]: ORANGE,
  [KeyType.Pressure]: BLUE,
  [KeyType.WaterLevel]: BLUE,
  [KeyType.Water]: BLUE,
  [KeyType.TimeSeconds]: ORANGE,
};

export const getColor = (sensorType: KeyType | null) => (sensorType ? COLOR_BY_SENSOR_TYPE[sensorType] : RED);

export const getXPixelFactor = (interval: Interval, padding: Padding, width: number) => {
  const xDiffMs = interval.toDuration().milliseconds;
  const xPadding = padding.left + padding.right;
  return (width - xPadding) / xDiffMs;
};

// Return values that are visible in the screen plus one extra from each side
export const getVisibleValues = (values: ValueTypes[], interval: Interval) => {
  const startMs = interval.start.toMillis();
  const endMs = interval.end.toMillis();
  let startIdx = 0;
  let endIdx = 0;

  for (let i = 1; i < values.length - 1; i++) {
    const v = values[i];
    if (v._type === ValueEnum.PLAIN) {
      if (v.endMs <= startMs) {
        startIdx = i + 1;
        continue;
      }
      if (v.startMs >= endMs && !endIdx) {
        endIdx = i;
      }
    } else {
      // avg may have empty values, and we want to ensure a smooth line over the edges of the graph
      if (v._type === ValueEnum.AVG && !(v as AvgValue).samples) {
        continue;
      }
      if (v.endMs <= startMs) {
        startIdx = i;
        continue;
      }
      if (v.startMs >= endMs && !endIdx) {
        endIdx = i + 1;
      }
    }
  }
  endIdx = endIdx || values.length;
  return values.slice(startIdx, endIdx);
};

export const getYMinMax = (series: SeriesData[], scaleSide: ScaleSide) => {
  const valuesPerSide = series
    .filter((s) => s.scaleSide === scaleSide)
    .reduce((all, s) => all.concat(s.visibleValues), [] as ValueTypes[]);
  const minPerSide = valuesPerSide.map((v) => {
    if (v._type === ValueEnum.AVG) {
      return v.min;
    } else {
      return v.value;
    }
  });
  const maxPerSide = valuesPerSide.map((v) => {
    if (v._type === ValueEnum.AVG) {
      return v.max;
    } else {
      return v.value;
    }
  });

  const min = Math.min(...minPerSide, 0);
  const max = Math.max(...maxPerSide, 0);
  return [min, max];
};

export const getYPixelFactor = (min: number, max: number, height: number, padding: Padding) => {
  const diff = max - min;
  if (diff === 0) {
    return 0;
  } else {
    const reserveSpaceForRenderingThickLines = 1;
    return (height - padding.top - padding.bottom - reserveSpaceForRenderingThickLines) / diff;
  }
};

// get an interval on y-scale, that matches rounded numbers, 100, 500, 1000, etc..
export const getYAxisSteps = (graphState: GraphState, scaleSide: ScaleSide): { start: number; step: number } => {
  const series = graphState.series.find((s) => s.scaleSide === scaleSide);
  if (!series) {
    return { start: 0, step: 0 };
  }
  if (series.sensor.keyType === KeyType.TimeSeconds) {
    return getYAxisStepsTime(graphState, scaleSide);
  } else {
    return getYAxisStepsNumber(graphState, scaleSide);
  }
};

const getYAxisStepsNumber = (graphState: GraphState, scaleSide: ScaleSide): { start: number; step: number } => {
  let step = 0.1;
  const maxIntervals = 10;
  let factor = 5;
  while (step * maxIntervals < graphState.yMaxValue[scaleSide] - graphState.yMinValue[scaleSide]) {
    step = step * factor;

    // multiply by 5, or 2 to get interval * 5 * 2 * 5 * 2 * 5 ...
    if (factor === 5) {
      factor = 2;
    } else {
      factor = 5;
    }
  }

  // drop down the y axis below zero if needed
  let start = 0;
  while (graphState.yMinValue[scaleSide] < 0 && start > graphState.yMinValue[scaleSide]) {
    start -= step;
  }
  return { start, step };
};

export const getYAxisStepsTime = (graphState: GraphState, scaleSide: ScaleSide): { start: number; step: number } => {
  const maxIntervals = 10;
  const max = Math.max(graphState.yMaxValue[scaleSide], 0);
  const min = Math.min(graphState.yMinValue[scaleSide], 0);
  const range = max - min;
  let step = 1;
  if (range >= 60 * 60) {
    step = 60 * 60;
  } else if (range >= 60) {
    step = 60;
  }

  let factor = 5;
  while (step * maxIntervals < range) {
    step = step * factor;

    // multiply by 5, or 2 to get interval * 5 * 2 * 5 * 2 * 5 ...
    if (factor === 5) {
      factor = 2;
    } else {
      factor = 5;
    }
  }

  // drop down the y axis below zero if needed
  let start = 0;
  while (graphState.yMinValue[scaleSide] < 0 && start > graphState.yMinValue[scaleSide]) {
    start -= step;
  }

  return { start, step };
};

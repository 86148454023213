import { Outlet } from "react-router-dom";
import { useGetCurrentUserQuery } from "./store/api";
import styled from "styled-components";
import { FullSizeLayout } from "./utils/styled";
import { useEffect, useState } from "react";
import { AppMenu } from "./AppMenu";
import { Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";

export const AppLayout = () => {
  const currentUser = useGetCurrentUserQuery();

  const [width, setWidth] = useState(window.window.innerWidth);
  const narrowLayout = width < 1024;
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    const listener = () => {
      setWidth(window.window.innerWidth);
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  if (currentUser.isLoading) {
    return null;
  }

  return (
    <TopLevelLayout>
      {narrowLayout && (
        <TopMenu>
          <SmallLogo>
            <img alt="logo" src="/assets/logo_minimal_small.png" />
            <span>Vilkase</span>
          </SmallLogo>
          <Button icon={<MenuOutlined onClick={() => setDrawer(true)} />} />
          <Drawer width={250} open={drawer} onClose={() => setDrawer(false)} placement={"left"} destroyOnClose>
            <AppMenu narrowLayout={narrowLayout} close={() => setDrawer(false)} />
          </Drawer>
        </TopMenu>
      )}
      <ContentLevelLayout>
        {!narrowLayout && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <BigLogo>
              <img alt="logo" src="/assets/logo_minimal_small.png" />
              <span>Vilkase</span>
            </BigLogo>
            <AppMenu narrowLayout={narrowLayout} />
          </div>
        )}

        <ContentCol>
          <FullSizeLayout>
            <Outlet />
          </FullSizeLayout>
        </ContentCol>
      </ContentLevelLayout>
    </TopLevelLayout>
  );
};

const TopLevelLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TopMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

const ContentLevelLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  overflow: auto;
  width: 100%;
  height: 100%;
`;

const ContentCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  align-items: center;
  height: 100%;
  width: 100%;
  @media only screen and (min-width: 1200px) {
    width: 1200px;
  }
  margin-left: 10px;
  margin-right: 10px;
`;

const BigLogo = styled.div`
  display: flex;
  font-size: 20px;

  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 30px;

  img {
    margin-right: 10px;
  }
`;

const SmallLogo = styled.div`
  display: flex;
  font-size: 20px;

  img {
    margin-right: 10px;
  }
`;

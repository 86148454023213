import { useEditDeviceTypeMutation } from "../../../../store/api";
import { Button, Input, message, Space } from "antd";
import { useState } from "react";

export const DeviceTypeInput = ({ deviceTypeId, defaultName }: { deviceTypeId: number; defaultName: string }) => {
  const [editDeviceType] = useEditDeviceTypeMutation();
  const [name, setName] = useState(defaultName);

  const handleEdit = async () => {
    await editDeviceType({ deviceTypeId, name }).unwrap();
    message.success("Tallennettu");
  };

  return (
    <Space.Compact style={{ width: "100%" }}>
      <Input style={{ width: "20%" }} disabled value={deviceTypeId} />
      <Input defaultValue={name} onChange={(e) => setName(e.target.value)} onPressEnter={handleEdit} />
      <Button type="primary" onClick={handleEdit}>
        Tallenna
      </Button>
    </Space.Compact>
  );
};

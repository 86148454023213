import { SensorBrowserState } from "./reducer";
import { useMemo } from "react";
import {
  useGetCurrentUserQuery,
  useGetDevicesQuery,
  useGetOrganizationsQuery,
  useGetPermissionsQuery,
  useGetSensorsQuery,
} from "../../store/api";
import { Sensor } from "../../store/types";

export interface ExtendedSensor extends Sensor {
  deviceName: string;
}

export const useVisibleSensors = (sensorBrowserState: SensorBrowserState) => {
  const currentUser = useGetCurrentUserQuery();
  const permissions = useGetPermissionsQuery(undefined, { skip: !currentUser.data?.isAdmin });
  const devices = useGetDevicesQuery();
  const sensors = useGetSensorsQuery();
  const organizations = useGetOrganizationsQuery();

  const extendedSensors = useMemo(() => {
    return (
      sensors.data?.map((s) => {
        const d = devices.data?.find((d) => d.deviceId === s.deviceId);
        return {
          ...s,
          deviceName: d?.name || "",
          deviceTypeName: d?.deviceType?.name || "",
          deviceNotes: d?.notes,
          deviceSiteId: d?.siteId,
          deviceDescription: d?.description,
        } as ExtendedSensor;
      }) || []
    );
  }, [devices.data, sensors.data]);

  const sensorsOnFrontpageOrByType = useMemo(() => {
    if (sensorBrowserState.sensorType) {
      return extendedSensors.filter((s) => s.keyType === sensorBrowserState.sensorType);
    }
    return extendedSensors.filter((s) => s.displayOnFrontPage) || [];
  }, [extendedSensors, sensorBrowserState.sensorType]);

  return useMemo(() => {
    let filteredSensors = sensorsOnFrontpageOrByType;

    if (sensorBrowserState.organizationId) {
      const organization = organizations.data?.find((org) => org.organizationId === sensorBrowserState.organizationId);
      if (organization) {
        filteredSensors = filteredSensors.filter((s) => organization.devices.includes(s.deviceId));
      }
    }

    if (sensorBrowserState.userId) {
      const directAccess = permissions.data?.userDevicePermissions
        .filter((p) => p.userId === sensorBrowserState.userId)
        .map((p) => p.deviceId);

      const userOrganizations = permissions.data?.organizationUserPermissions.filter(
        (p) => p.userId === sensorBrowserState.userId
      );

      const organizationAccess = permissions.data?.organizationDevicePermissions
        .filter((p) => userOrganizations?.find((o) => o.organizationId === p.organizationId))
        .map((p) => p.deviceId);

      filteredSensors = filteredSensors.filter(
        (s) =>
          directAccess?.find((deviceId) => deviceId === s.deviceId) ||
          organizationAccess?.find((deviceId) => deviceId === s.deviceId)
      );
    }

    return filteredSensors;
  }, [
    sensorsOnFrontpageOrByType,
    sensorBrowserState.organizationId,
    sensorBrowserState.userId,
    organizations.data,
    permissions.data?.organizationUserPermissions,
    permissions.data?.userDevicePermissions,
    permissions.data?.organizationDevicePermissions,
  ]);
};

import { ValueEnum } from "../../../store/types";
import { getX, getY } from "../utils";
import { SeriesData } from "../types";
import { GraphState } from "../reducer";
import { getPadding, getRounding } from "./bars";
import { levelUnitToMilliseconds } from "../../../store/utils";

export const renderBars = (
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  graphState: GraphState,
  series: SeriesData
) => {
  if (graphState.levelIntervals.length < 1) {
    return;
  }

  // Measure with standard unit to consistently support months of different length
  const standardUnit = levelUnitToMilliseconds(graphState.level);
  const standardPixelsPerBar =
    getX(graphState, graphState.levelIntervals[0].start.plus(standardUnit).toMillis()) -
    getX(graphState, graphState.levelIntervals[0].start.toMillis());
  const rounding = getRounding(standardPixelsPerBar);
  const padding = getPadding(standardPixelsPerBar);

  ctx.beginPath();
  ctx.fillStyle = series.color;

  series.visibleValues.forEach((value) => {
    if (value._type !== ValueEnum.PLAIN) {
      return;
    }

    const x = getX(graphState, value.startMs) + padding;
    const y = getY(graphState, series.scaleSide, 0);
    const x2 = getX(graphState, value.startMs + standardUnit) - padding;
    const width = x2 - x;
    const height = -graphState.yPixelFactor[series.scaleSide] * value.value;
    try {
      ctx.roundRect(x, y, width, height, [0, 0, rounding, rounding]);
    } catch (err) {
      ctx.rect(x, y, width, height);
    }
  });

  ctx.globalAlpha = 0.9;
  ctx.fill();
};

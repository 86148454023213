import { ValueEnum } from "../../../store/types";
import { getX, getY } from "../utils";
import { SeriesData } from "../types";
import { GraphState } from "../reducer";

export const renderArea = (
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  graphState: GraphState,
  series: SeriesData
) => {
  // Avg line
  ctx.beginPath();

  series.visibleValues.forEach((value) => {
    if (value._type !== ValueEnum.AVG) {
      return;
    }
    const x = getX(graphState, value.timestampMs);
    const y = getY(graphState, series.scaleSide, value.avg);
    if (value.samples) {
      ctx.lineTo(x, y);
    }
  });

  ctx.strokeStyle = series.color;
  ctx.stroke();

  // Min/Max area
  ctx.beginPath();

  series.visibleValues.forEach((value) => {
    if (value._type !== ValueEnum.AVG) {
      return;
    }
    const x = getX(graphState, value.timestampMs);
    const y = getY(graphState, series.scaleSide, value.max);
    if (value.samples) {
      ctx.lineTo(x, y);
    }
  });

  series.visibleValues
    .slice()
    .reverse()
    .forEach((value) => {
      if (value._type !== ValueEnum.AVG) {
        return;
      }
      const x = getX(graphState, value.timestampMs);
      const y = getY(graphState, series.scaleSide, value.min);
      if (value.samples) {
        ctx.lineTo(x, y);
      }
    });

  ctx.closePath();

  ctx.globalAlpha = 0.2;
  ctx.fillStyle = series.color;
  ctx.stroke();
  ctx.fill();
};

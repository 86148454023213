import { Table } from "../../../../../components/Table/Table";
import { useParams } from "react-router-dom";
import { useGetPermissionsQuery, useGetUsersQuery, useSetUsersForOrganizationMutation } from "../../../../../store/api";
import { User } from "../../../../../store/types";
import { useCallback, useMemo } from "react";
import { dateRenderer } from "../../../../../components/Table/cellRenderer";
import { WIDE_COLUMN_WIDTH } from "../../../../../components/constants";
import { ColDef } from "ag-grid-community";
import { LinkButton } from "../../../../../components/LinkButton";
import { getAdminUserProfileRoute } from "../../../../../utils/routes";

export const AdminOrganizationUsersList = () => {
  const permissions = useGetPermissionsQuery();
  const users = useGetUsersQuery();
  const [setUsersForOrganization] = useSetUsersForOrganizationMutation();
  const { orgId } = useParams();

  const isSelected = useCallback(
    (user: User) =>
      !!permissions.data?.organizationUserPermissions.find(
        (perm) => perm.organizationId === orgId && perm.userId === user.userId
      ),
    [orgId, permissions.data?.organizationUserPermissions]
  );

  const columns = useMemo<ColDef[]>(
    () => [
      {
        field: "userId",
        headerName: "ID",
        cellRenderer: (cell: any) => <LinkButton path={getAdminUserProfileRoute(cell.value)} text={cell.value} />,
      },
      {
        field: "userName",
        headerName: "Käyttäjätunnus",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        field: "email",
        headerName: "Sähköposti",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        field: "firstName",
        headerName: "Nimi",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        sort: "desc",
        field: "created",
        headerName: "Luotu",
        cellRenderer: dateRenderer,
        getQuickFilterText: dateRenderer,
      },
    ],
    []
  );

  if (!orgId) return null;

  const saveHandler = async (users: User[]) => {
    await setUsersForOrganization({
      organizationId: orgId,
      userIds: users.map((u) => u.userId),
    });
  };

  return (
    <Table
      rowData={users.data}
      loading={users.isFetching || permissions.isFetching}
      multiSelect
      onSave={saveHandler}
      isSelected={isSelected}
      columns={columns}
    />
  );
};

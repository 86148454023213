import styled from "styled-components";
import { Sensor } from "../../../store/types";
import { Card, Descriptions, DescriptionsProps } from "antd";
import { getTitle } from "./utils";
import { DateTime } from "luxon";
import { humanizeValueStr } from "../../../store/utils";
import { useMemo } from "react";

export const DeviceViewSummaryCard = ({ sensor }: { sensor: Sensor }): JSX.Element | null => {
  const value = useMemo(() => {
    if (!sensor.latestValue || !sensor.keyType) {
      return;
    }
    return humanizeValueStr(sensor.latestValue, 0, sensor.keyType, false);
  }, [sensor.keyType, sensor.latestValue]);

  const items: DescriptionsProps["items"] = [];
  if (value) {
    items.push({ key: "latestValue", label: "Mittarilukema", children: value });
    items.push({
      key: "latestUpdated",
      label: "Aika",
      children: DateTime.fromISO(sensor.latestUpdate || "").toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    });
  }
  if (sensor.meterSerialNumber) {
    items.push({ key: "serialNumber", label: "Sarjanumero", children: sensor.meterSerialNumber });
  }

  return (
    <SummaryCard size={"small"} title={getTitle(sensor)} key={sensor.deviceId + sensor.key}>
      <Descriptions items={items} column={1} />
    </SummaryCard>
  );
};

const SummaryCard = styled(Card)`
  flex: 1 1 0;
  min-width: 200px;
`;

import { useGetCurrentUserQuery, useGetDevicesQuery, useGetSensorsQuery } from "../../store/api";
import { Table } from "../../components/Table/Table";
import { useMemo, useReducer, useRef } from "react";
import { durationRendererISO, durationRendererSeconds } from "../../components/Table/cellRenderer";
import { ValueColumn } from "./ValueColumn";
import { reducer } from "./reducer";
import { getExtendedFromNow } from "../../utils/interval";
import { KeyType, Level, ViewType } from "../../store/types";
import { TimeFrameSelect } from "./TimeFrameSelect";
import { TimeFrameOptions } from "./constants";
import { useVisibleSensors } from "./useVisibleSensors";
import { OrganizationSelect } from "../../components/organizations/OrganizationSelect/OrganizationSelect";
import { ColDef } from "ag-grid-community";
import { useNavigate, useParams } from "react-router-dom";
import { DeviceView } from "./device/DeviceView";
import { Spin } from "antd";
import styled from "styled-components";
import { sensorValuePlainFormatter } from "../../components/Table/valueFormatter";
import { DeviceTypeSelect } from "./DeviceTypeSelect";
import { UserSelect } from "../../components/users/UserSelect/UserSelect";
import { useRealtimeInterval } from "./useRealtimeInterval";
import { getDeviceRoute } from "../../utils/routes";
import { TableState } from "../../components/Table/interfaces";

export const SensorBrowser = () => {
  const currentUser = useGetCurrentUserQuery();
  const devices = useGetDevicesQuery();
  const sensors = useGetSensorsQuery();
  const navigate = useNavigate();
  const { deviceId } = useParams();

  const [sensorBrowserState, sensorBrowserDispatch] = useReducer(reducer, {
    userId: null,
    organizationId: null,
    interval: getExtendedFromNow(24, "hour"),
    viewType: ViewType.Auto,
    level: Level.hour,
    timeframe: "24h",
  });
  const tableState = useRef<TableState>();

  const visibleSensors = useVisibleSensors(sensorBrowserState);
  useRealtimeInterval(sensorBrowserState, sensorBrowserDispatch);

  const valueHeader = useMemo(() => {
    if (sensorBrowserState.highlight) {
      if (sensorBrowserState.timeframe === "24h") {
        return `${sensorBrowserState.highlight.interval.start.toLocaleString({
          month: "numeric",
          day: "numeric",
          weekday: "short",
        })} klo ${sensorBrowserState.highlight.interval.start.hour} - ${
          sensorBrowserState.highlight.interval.start.hour + 1
        }`;
      }
      if (sensorBrowserState.timeframe === "30d") {
        return sensorBrowserState.highlight.interval.start.toLocaleString({
          month: "numeric",
          day: "numeric",
          year: "numeric",
          weekday: "short",
        });
      }
      if (sensorBrowserState.timeframe === "30d-min") {
        return sensorBrowserState.highlight.interval.start.toLocaleString({
          month: "numeric",
          day: "numeric",
          year: "numeric",
          weekday: "short",
        });
      }
      if (sensorBrowserState.timeframe === "12m") {
        return `${sensorBrowserState.highlight.interval.start.monthLong} ${sensorBrowserState.highlight.interval.start.year}`;
      }
      if (sensorBrowserState.timeframe === "20y") {
        return `${sensorBrowserState.highlight.interval.start.year}`;
      }
    }
    return TimeFrameOptions.find((o) => o.value === sensorBrowserState.timeframe)?.label;
  }, [sensorBrowserState]);

  const columns = useMemo<ColDef[]>(
    () => [
      // Hidden fields to make them available in search
      { field: "deviceId", hide: true },
      { field: "deviceDescription", hide: true },
      { field: "deviceNotes", hide: true },
      {
        field: "deviceName",
        headerName: "Laite",
        width: 400,
        cellRenderer: (cell: any) => (
          <Link
            href={getDeviceRoute(cell.data.deviceId)}
            onClick={(e) => {
              e.preventDefault();
              sensorBrowserDispatch({ type: "setHighlight" });
            }}
          >
            <span style={{ textOverflow: "ellipsis" }}>{cell.data.deviceName}</span>
            <span style={{ flexShrink: 0 }}>{cell.data.deviceId}</span>
          </Link>
        ),
      },
      {
        field: "highlight", // There is no such field, but the definition seems to be necessary to avoid destruction of cell renderer
        headerName: valueHeader,
        sortable: false,
        cellRenderer: ValueColumn,
        cellRendererParams: { sensorBrowserState, sensorBrowserDispatch },
        width: 280,
      },
      {
        field: "latestValue",
        headerName: "Mittarilukema",
        sortable: true,
        width: 140,
        valueFormatter: sensorValuePlainFormatter,
        cellStyle: { "text-overflow": "ellipsis", "white-space": "nowrap", overflow: "hidden" },
      },
      {
        field: "deviceSiteId",
        headerName: "Käyttöpaikka",
        width: 140,
      },
      {
        field: "deviceTypeName",
        headerName: "Laitetyyppi",
        width: 140,
      },
      {
        field: "latestUpdate",
        headerName: "Aika mittauksesta",
        cellRenderer: durationRendererISO,
        getQuickFilterText: durationRendererISO,
        width: 200,
      },
      {
        field: "latestInterval",
        headerName: "Mittausväli",
        cellRenderer: durationRendererSeconds,
        getQuickFilterText: durationRendererSeconds,
        width: 140,
      },
    ],
    [valueHeader, sensorBrowserState]
  );

  if (devices.isLoading || sensors.isLoading) {
    return (
      <LoadingLayout>
        <Spin tip="Ladataan" size="large" />
      </LoadingLayout>
    );
  }

  if (deviceId) {
    return (
      <DeviceView
        interval={sensorBrowserState.interval}
        level={sensorBrowserState.level}
        viewType={sensorBrowserState.viewType}
      />
    );
  }

  return (
    <Table
      tableState={tableState}
      getRowId={(params) => params.data?.deviceId + params.data?.key}
      onRowClicked={(event) => {
        sensorBrowserDispatch({ type: "setHighlight" });
        navigate(getDeviceRoute(event.data?.deviceId));
      }}
      rowData={visibleSensors}
      loading={sensors.isFetching || devices.isFetching}
      columns={columns}
      toolbar={
        <>
          <TimeFrameSelect sensorBrowserState={sensorBrowserState} sensorBrowserDispatch={sensorBrowserDispatch} />
          <OrganizationSelect
            defaultOrganizationId={sensorBrowserState.organizationId}
            onSelect={(organizationId: string) =>
              sensorBrowserDispatch({
                type: "setOrganizationId",
                payload: organizationId,
              })
            }
            onClear={() =>
              sensorBrowserDispatch({
                type: "setOrganizationId",
                payload: null,
              })
            }
          />
          {currentUser.data?.isAdmin && (
            <UserSelect
              defaultUserId={sensorBrowserState.userId}
              onSelect={(userId: number) => sensorBrowserDispatch({ type: "setUserId", payload: userId })}
              onClear={() => sensorBrowserDispatch({ type: "setUserId", payload: null })}
            />
          )}
          <DeviceTypeSelect
            defaultSensorType={sensorBrowserState.sensorType}
            onSelect={(sensorType: KeyType | undefined) =>
              sensorBrowserDispatch({
                type: "setSensorType",
                payload: sensorType,
              })
            }
            onClear={() =>
              sensorBrowserDispatch({
                type: "setSensorType",
                payload: undefined,
              })
            }
          />
        </>
      }
    />
  );
};

const Link = styled.a`
  all: unset;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const LoadingLayout = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 25%;
`;

import { useGetSensorsQuery } from "../../store/api";
import { useMemo } from "react";
import { Sensor } from "../../store/types";

export const useDeviceSensors = (deviceId?: string): Sensor[] => {
  const sensors = useGetSensorsQuery();

  return useMemo(() => {
    if (!deviceId) {
      return [];
    }
    return (sensors.data?.filter((s) => s.deviceId === deviceId) || []).sort((a, b) => {
      if (a.key < b.key) {
        return -1;
      }
      if (a.key > b.key) {
        return 1;
      }
      return 0;
    });
  }, [deviceId, sensors.data]);
};

import { Button, Form, DatePicker, Divider } from "antd";
import { InfoCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { OrganizationSelect } from "../../components/organizations/OrganizationSelect/OrganizationSelect";
import { useState } from "react";
import { DateTime } from "luxon";
import { baseUrl } from "../../constants";
import { ContentCard } from "../../utils/styled";
import { useGetOrganizationsQuery } from "../../store/api";

export const Reports = () => {
  const organizations = useGetOrganizationsQuery();
  const [organization, setOrganization] = useState<string>();
  const [start, setStart] = useState<DateTime>();
  const [end, setEnd] = useState<DateTime>();

  const handleSubmit = () => {
    let url;
    if (organization) {
      url = `/api/reports/daily/${start?.toFormat("yyyyMMdd")}-${end?.toFormat("yyyyMMdd")}-${organization}.xlsx`;
    } else {
      url = `/api/reports/daily/${start?.toFormat("yyyyMMdd")}-${end?.toFormat("yyyyMMdd")}.xlsx`;
    }

    window.open(baseUrl + url, "vilkase_report");
  };

  const validRange = () => {
    if (!start || !start.isValid) {
      return false;
    }
    if (!end || !end.isValid) {
      return false;
    }
    if (start > end) {
      return false;
    }
    return true;
  };

  return (
    <Layout>
      <ContentCard title={"Vesimittarien kulutusraportti"}>
        <ContentLayout>
          <Form labelCol={{ span: 7 }} onFinish={handleSubmit} style={{ marginTop: "20px", flexShrink: 0, width: 350 }}>
            <Form.Item label={"Alku"} name={"start"} required>
              <DatePicker
                style={{ width: 200 }}
                onChange={(date) => {
                  if (!date) {
                    setStart(undefined);
                  } else {
                    setStart(DateTime.fromJSDate(date.toDate()));
                  }
                }}
              />
            </Form.Item>
            <Form.Item label={"Loppu"} name={"end"} required>
              <DatePicker
                style={{ width: 200 }}
                onChange={(date) => {
                  if (!date) {
                    setEnd(undefined);
                  } else {
                    setEnd(DateTime.fromJSDate(date.toDate()));
                  }
                }}
              />
            </Form.Item>

            {!!organizations.data?.length && (
              <Form.Item label={"Organisaatio"} name={"organization"}>
                <OrganizationSelect
                  defaultOrganizationId={null}
                  onSelect={setOrganization}
                  onClear={() => setOrganization(undefined)}
                />
              </Form.Item>
            )}

            <Form.Item wrapperCol={{ offset: 7 }}>
              <Button disabled={!validRange()} type="primary" htmlType="submit" icon={<CloudDownloadOutlined />}>
                Lataa raportti
              </Button>
            </Form.Item>
          </Form>
          <Divider type={"vertical"} style={{ height: "unset" }} />
          <InfoColumn>
            <p>
              <InfoCircleOutlined style={{ marginRight: "8px" }} />
              <strong>Ohje raportin lukemiseen</strong>
            </p>
            <p>
              <strong>Kulutus</strong> lasketaan suoraan viimeisen ja ensimmäisen arvon erotuksesta. Tämä ei ole aina
              mahdollista, jos esimerkiksi mittarilukemaa on muutettu kesken jakson. Kulutusjakso alkaa alkupäivänä klo
              00:00 tai viimeisestä lukemasta sitä ennen ja päättyy loppupäivää seuraavana päivänä klo 00:00 tai
              viimeiseen lukemaan sitä ennen. Näin ollen seuraava jakso voidaan aloittaa samasta lukemasta mihin
              edellinen päättyi. Alku voi olla hyvin kaukana menneisyydessä, jos mittari on jäänyt pois käytöstä.
              Loppulukema puuttuu, jos valitun jakson ajalle ei osu yhtään lukemaa.
            </p>
            <p>
              <strong>Arvioitu kulutus</strong> on laskettu alkupäivästä klo 00:00 loppupäivää seuraavaan päivään klo
              00:00. Keskiyön lukemaa tasan klo 00:00 ei ole aina saatavilla, jolloin se arvioidaan lähimmistä lukemista
              ennen ja jälkeen klo 00:00. Samaa algoritmia käytetään myös kuvaajissa. Arvioitu kulutus voidaan laskea
              vaikka mittarilukemaa oltaisiin vaihdettu kesken jakson. Jos mittari on ollut hetkellisesti pois käytöstä,
              niin valitulle jaksolle voidaan silti laskea pieni keskiarvokulutus
            </p>
            <p>
              Raportit ovat dynaamisia otoksia tietokannasta. Laitteet eivät välttämättä ole lähettäneet kaikkea dataa
              valitulta jaksolta. Jos dataa saapuu viiveellä, niin sama raportti tuottaa myöhemmin eri tuloksen.
            </p>
          </InfoColumn>
        </ContentLayout>
      </ContentCard>
    </Layout>
  );
};

const Layout = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

const ContentLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const InfoColumn = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 400px;
  display: flex;
  flex-direction: column;
`;

import React, { CSSProperties, forwardRef } from "react";

export const Canvas = forwardRef<
  HTMLCanvasElement,
  {
    width: number;
    height: number;
    style?: CSSProperties;
  }
>(({ width, height, style }, ref) => {
  return (
    <canvas
      ref={ref}
      width={width * window.devicePixelRatio}
      height={height * window.devicePixelRatio}
      onContextMenu={(event: any) => {
        event?.preventDefault?.();
      }}
      style={{
        ...style,
        width,
        height,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        touchAction: "pan-y", // Allow browser to control scrolling up and down
        userSelect: "none", // No text selection on long press
      }}
    />
  );
});

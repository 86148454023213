import { useParams, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import { EditUser } from "./profile/EditUser";
import { GeneratePassword } from "./password/GeneratePassword";
import { useMemo } from "react";
import { DeleteUser } from "./delete/DeleteUser";

export const AdminUserTabs = () => {
  const { activeTab } = useParams();
  const navigate = useNavigate();

  const tabs = useMemo(
    () => [
      {
        label: "Tiedot",
        key: "profile",
        children: <EditUser />,
      },
      {
        label: "Salasana",
        key: "password",
        children: <GeneratePassword />,
      },
      {
        label: "Poista",
        key: "delete",
        children: <DeleteUser />,
      },
    ],
    []
  );

  return <Tabs onChange={navigate} activeKey={activeTab} items={tabs} />;
};

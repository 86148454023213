import { Level, Sensor, ValueEnum, ValueTypes, ViewType } from "../types";
import { useMemo } from "react";
import { Duration, Interval } from "luxon";
import { getAvgValue, getLMinValue, getPlainValue, getPlainValueForMinimumView, getRawValue } from "../utils";
import { useDebounceNonDefault } from "../../utils/useDebounceNonDefault";
import { useAppSelector } from "../hooks";
import { useFetchValues } from "./useFetchValues";
import { getReduxId } from "./types";

export const useLevelValues = (
  sensor: Sensor,
  level: Level,
  interval: Interval,
  viewType: ViewType,
  nowThreshold?: Duration,
  realtime?: boolean
): ValueTypes[] => {
  const sensorState = useAppSelector((state) => state.values.sensors[getReduxId(sensor)]);

  // Cache values locally from redux, to optimize the type conversions
  // Because redux can only hold plain data types, but we want to use more advanced types from Luxon
  const levelValues = useMemo<ValueTypes[]>(() => {
    const all = sensorState?.values?.[level] || [];
    return all.map((v) => {
      if (v._type === ValueEnum.PLAIN) {
        if (viewType === ViewType.Min) {
          return getPlainValueForMinimumView(v);
        } else {
          return getPlainValue(v);
        }
      }
      if (v._type === ValueEnum.AVG) {
        return getAvgValue(v);
      }
      if (v._type === ValueEnum.LMIN) {
        return getLMinValue(v);
      }
      if (v._type === ValueEnum.RAW) {
        return getRawValue(v);
      }
      throw new Error("Invalid value type", v._type);
    });
  }, [level, sensorState?.values, viewType]);

  const props = useMemo(
    () => ({
      sensor,
      level,
      interval,
    }),
    [sensor, level, interval]
  );
  const debounced = useDebounceNonDefault(props, 200);

  useFetchValues(sensor, debounced?.level, debounced?.interval, viewType, nowThreshold, realtime);

  return levelValues;
};

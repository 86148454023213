export const getRounding = (width: number) => {
  if (width < 10) {
    return 1;
  }
  if (width < 20) {
    return 2;
  }
  return 3;
};

export const getPadding = (pixelsPerBar: number) => {
  if (pixelsPerBar < 10) {
    return 1;
  }

  return Math.max(1, (pixelsPerBar - 20) / 2);
};

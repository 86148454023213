import { Interval } from "luxon";
import { createPortal } from "react-dom";
import { Level, ViewType } from "../../store/types";
import { Highlight, Position, SeriesOptions } from "./types";
import React, { useEffect, useReducer, useRef, RefObject, useMemo, useCallback } from "react";
import { reducer } from "./reducer";
import { Loader } from "./Loader";
import { Canvas } from "./Canvas";
import styled from "styled-components";
import { ControlsToolbarMemo } from "./Controls/ControlsToolbar";
import { useInteraction } from "./useInteraction";
import { useCopy } from "./useCopy";
import { useResize } from "./useResize";
import { useRender } from "./useRender";
import { AnimationFunc } from "./useAnimateAll";
import { useAtom } from "jotai/index";
import { animationTargetAtom } from "./atoms/atoms";

interface GraphProps {
  interval: Interval;
  level: Level;
  lockToNow: boolean;
  setLockToNow: (value: boolean) => void;
  width?: number;
  height: number;
  mode: "tiny" | "normal";
  series: SeriesOptions[];
  onHighlight: (highlight?: Highlight) => void;
  highlight?: Highlight;
  onSetStaticPosition?: (position: Position) => void;
  controlRef?: RefObject<HTMLDivElement>; // Where to render controls with portal
  viewType: ViewType;
  onAnimate?: AnimationFunc;
}

export const Graph = ({
  interval,
  level,
  lockToNow,
  setLockToNow,
  width,
  height,
  mode,
  series,
  onHighlight,
  highlight,
  onSetStaticPosition,
  controlRef,
  viewType,
  onAnimate,
}: GraphProps): JSX.Element => {
  const layoutRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLCanvasElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const underlayRef = useRef<HTMLCanvasElement>(null);
  const [graphState, graphDispatch] = useReducer(reducer, {
    width: width || 1,
    height,
    padding: mode === "tiny" ? { top: 8, bottom: 8, right: 0, left: 0 } : { top: 24, bottom: 45, right: 0, left: 60 },
    mode,
    interval,
    level,
    dragging: false,
    series: series.map((s, idx) => ({
      ...s,
      id: idx,
      visibleValues: [],
      values: {
        [Level.raw]: [],
        [Level.minute]: [],
        [Level.hour]: [],
        [Level.day]: [],
        [Level.month]: [],
        [Level.year]: [],
      },
    })),
    yMinValue: {
      left: 0,
      right: 0,
    },
    yMaxValue: {
      left: 0,
      right: 0,
    },
    yPixelFactor: {
      left: 0,
      right: 0,
    },
    xPixelFactor: 0,
    yAxis: {
      left: {
        start: 0,
        step: 1,
      },
      right: {
        start: 0,
        step: 1,
      },
    },
    levelIntervals: [],
    rawLevelHighlightType: "value",
  });

  const [animationTarget] = useAtom(animationTargetAtom);

  useRender(graphState, overlayRef, canvasRef, underlayRef, highlight);

  useInteraction(
    graphState,
    graphDispatch,
    overlayRef,
    onHighlight,
    lockToNow,
    setLockToNow,
    onSetStaticPosition,
    onAnimate
  );

  const { handleCopy, handleDownload } = useCopy(graphState, series);

  useResize(graphState, graphDispatch, layoutRef, mode);

  useEffect(() => {
    graphDispatch({ type: "setPosition", payload: { level, interval } });
  }, [level, interval]);

  useEffect(() => {
    graphDispatch({ type: "setAnimationTarget", payload: animationTarget });
  }, [animationTarget]);

  const handleScale = useCallback(() => {
    graphDispatch({ type: "resetScale", payload: undefined });
  }, []);

  const animating = useMemo(() => graphState.dragging || !!animationTarget, [graphState.dragging, animationTarget]);

  return (
    <Layout>
      {mode === "normal" &&
        controlRef?.current &&
        createPortal(
          <ControlsToolbarMemo
            onScale={!animating ? handleScale : undefined}
            onCopy={!animating ? handleCopy : undefined}
            onDownload={!animating ? handleDownload : undefined}
          />,
          controlRef.current
        )}
      {graphState.series.map((s) => (
        <Loader key={s.id} series={s} graphState={graphState} viewType={viewType} graphDispatch={graphDispatch} />
      ))}
      <CanvasLayout ref={layoutRef} width={width ? graphState.width : undefined} height={graphState.height}>
        <Canvas ref={overlayRef} width={graphState.width} height={graphState.height} style={{ zIndex: 2 }} />
        <Canvas ref={canvasRef} width={graphState.width} height={graphState.height} style={{ zIndex: 1 }} />
        <Canvas ref={underlayRef} width={graphState.width} height={graphState.height} style={{ zIndex: 0 }} />
      </CanvasLayout>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const CanvasLayout = styled.div<{ width?: number; height: number }>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  height: ${(props) => props.height}px;
`;

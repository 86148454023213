import { useParams, Link, Outlet, useMatch } from "react-router-dom";
import { Breadcrumb } from "antd";
import { useGetOrganizationsQuery } from "../../../store/api";
import { LayoutContentGrid } from "../../../utils/styled";
import { useMemo, useRef } from "react";
import { TableState } from "../../../components/Table/interfaces";

export const AdminOrganization = () => {
  const { orgId } = useParams();
  const organizations = useGetOrganizationsQuery();
  const org = organizations.data?.find((o) => o.organizationId === orgId);
  const newMatch = useMatch("/admin/organizations/new");
  const organizationListTableState = useRef<TableState>();

  const breadcrumbs = useMemo(() => {
    const items = [];
    if (org || newMatch) {
      items.push({ title: <Link to={"../organizations"}>Organisaatiot</Link> });
    } else {
      items.push({ title: "Organisaatiot" });
    }
    if (org) {
      items.push({ title: `${org.organizationId}: ${org.name}` });
    }
    if (newMatch) {
      items.push({ title: "Uusi" });
    }
    return items;
  }, [org, newMatch]);

  return (
    <LayoutContentGrid>
      <Breadcrumb items={breadcrumbs} />
      <Outlet context={organizationListTableState} />
    </LayoutContentGrid>
  );
};

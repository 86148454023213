import { Table } from "../../../components/Table/Table";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useGetOrganizationsQuery } from "../../../store/api";
import { addressRenderer, dateRenderer, orgTypeRenderer } from "../../../components/Table/cellRenderer";
import { WIDE_COLUMN_WIDTH } from "../../../components/constants";
import { MutableRefObject, useMemo } from "react";
import { ColDef } from "ag-grid-community";
import { getAdminOrganizationInfoRoute } from "../../../utils/routes";
import { LinkButton } from "../../../components/LinkButton";
import { TableState } from "../../../components/Table/interfaces";

export const AdminOrganizationList = () => {
  const navigate = useNavigate();
  const organizations = useGetOrganizationsQuery();

  const addHandler = async () => {
    navigate(`new`);
  };

  const tableState = useOutletContext<MutableRefObject<TableState | undefined>>();

  const columns = useMemo<ColDef[]>(
    () => [
      {
        field: "organizationId",
        headerName: "ID",
        cellRenderer: (cell: any) => <LinkButton text={cell.value} path={getAdminOrganizationInfoRoute(cell.value)} />,
        getQuickFilterText: (cell) => cell.value,
      },

      {
        field: "name",
        headerName: "Nimi",
        width: WIDE_COLUMN_WIDTH,
      },

      {
        field: "typeId",
        headerName: "Tyyppi",
        cellRenderer: orgTypeRenderer,
        getQuickFilterText: orgTypeRenderer,
        width: 150,
      },
      {
        field: "description",
        headerName: "Kuvaus",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        field: "businessId",
        headerName: "Y-Tunnus",
      },
      {
        headerName: "Osoite",
        cellRenderer: addressRenderer,
        getQuickFilterText: addressRenderer,
        width: WIDE_COLUMN_WIDTH,
      },
      {
        field: "created",
        headerName: "Luotu",
        sort: "desc",
        cellRenderer: dateRenderer,
        getQuickFilterText: dateRenderer,
      },
    ],
    []
  );

  return (
    <Table
      tableState={tableState}
      rowData={organizations.data}
      loading={organizations.isFetching}
      onAdd={addHandler}
      columns={columns}
    />
  );
};

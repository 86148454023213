import { AnimationFunc } from "./useAnimateAll";
import { useCallback } from "react";
import { useLevels } from "./useLevels";
import { Interval } from "luxon";
import { Level } from "../../store/types";

export const useControls = (
  onAnimate: AnimationFunc,
  interval: Interval,
  level: Level,
  lockToNow: boolean,
  setLockToNow: (value: boolean) => void
) => {
  const { zoomOut, zoomIn } = useLevels(lockToNow, setLockToNow);

  const handlePanLeft = useCallback(() => {
    const start = interval.start.minus(interval.toDuration().toMillis() / 2);
    const end = interval.end.minus(interval.toDuration().toMillis() / 2);
    setLockToNow(false);

    onAnimate(level, {
      interval: Interval.fromDateTimes(start, end),
      level: level,
    });
  }, [interval, level, onAnimate, setLockToNow]);

  const handlePanRight = useCallback(() => {
    const start = interval.start.plus(interval.toDuration().toMillis() / 2);
    const end = interval.end.plus(interval.toDuration().toMillis() / 2);
    setLockToNow(false);

    onAnimate(level, {
      interval: Interval.fromDateTimes(start, end),
      level: level,
    });
  }, [interval, level, onAnimate, setLockToNow]);

  const handleZoomOut = useCallback(() => {
    const next = zoomOut(interval);
    if (!next) {
      return;
    }

    onAnimate(next.level, {
      interval: next.interval,
      level: next.level,
    });
  }, [interval, onAnimate, zoomOut]);

  const handleZoomIn = useCallback(() => {
    const next = zoomIn(interval, interval, true);
    if (!next) {
      return;
    }

    onAnimate(level, {
      interval: next.interval,
      level: next.level,
    });
  }, [interval, level, onAnimate, zoomIn]);

  const handleAnimate = useCallback(
    (nextLevel: Level, nextInterval: Interval) => {
      const animationLevel = nextInterval < interval ? nextLevel : level;
      onAnimate(animationLevel, {
        interval: nextInterval,
        level: nextLevel,
      });
    },
    [interval, level, onAnimate]
  );
  return { handleZoomOut, handleZoomIn, handlePanLeft, handlePanRight, handleAnimate };
};

import { atom } from "jotai";
import { AnimationTarget, Position } from "../types";
import { Level } from "../../../store/types";
import { getExtendedFromNow } from "../../../utils/interval";

const initialPosition = { level: Level.hour, interval: getExtendedFromNow(24, "hour") };

export const positionAtom = atom<Position>(initialPosition);

// This is used outside of animations to provide a position which doesn't cause constant re-renderings
const staticPositionValueAtom = atom<Position>(initialPosition);
export const staticPositionAtom = atom(
  (get) => get(staticPositionValueAtom),
  (get, set, newPosition: Position) => {
    set(positionAtom, newPosition);
    set(staticPositionValueAtom, newPosition);
  }
);
export const animationTargetAtom = atom<AnimationTarget | undefined>(undefined);

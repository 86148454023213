import { useParams } from "react-router-dom";
import { useEditUserMutation, useGetUsersQuery } from "../../../../../store/api";
import { User } from "../../../../../store/types";
import { ContentCard } from "../../../../../utils/styled";
import { Button, Form, Input } from "antd";

export const EditUser = () => {
  const { userId } = useParams();
  const users = useGetUsersQuery();
  const [editUser] = useEditUserMutation();
  const user = users.data?.find((u) => u.userId.toString() === userId);

  const handleSubmit = async (updated: Partial<User>) => {
    if (!user) {
      return;
    }
    editUser({
      userId: user.userId,
      ...updated,
      userName: updated.userName === "" ? null : updated.userName,
      email: updated.email === "" ? null : updated.email,
    });
  };

  if (users.isFetching) return null;

  return (
    <ContentCard title={"Käyttäjän tiedot"}>
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} onFinish={handleSubmit} initialValues={user}>
        <Form.Item label={"Etunimi"} name={"firstName"}>
          <Input />
        </Form.Item>
        <Form.Item label={"Sukunimi"} name={"lastName"}>
          <Input />
        </Form.Item>
        <Form.Item
          validateTrigger={"onSubmit"}
          label={"Sähköposti"}
          name={"email"}
          rules={[{ type: "email", message: "Anna sähköpostiosoite oikeassa muodossa" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Puhelinnumero"}
          name={"phone"}
          validateTrigger={"onSubmit"}
          rules={[{ pattern: /^\+358\d{8,10}$|^$/, message: "Anna puhelinnumero +358 suuntanumerolla" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={"Käyttäjätunnus"} name={"userName"}>
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 5, span: 10 }}>
          <Button type="primary" htmlType="submit">
            Tallenna
          </Button>
        </Form.Item>
      </Form>
    </ContentCard>
  );
};

import { Interval } from "luxon";
import { KeyType2, Level, Sensor, ViewType } from "../../../store/types";
import { Dispatch, SetStateAction, useRef } from "react";
import { GraphType, Highlight } from "../../../components/Graph/types";
import { getColor } from "../../../components/Graph/utils";
import { ContentCard } from "../../../utils/styled";
import { getTitle } from "./utils";
import { AnimationFunc } from "../../../components/Graph/useAnimateAll";
import { NormalGraph } from "../../../components/Graph/NormalGraph";

export const DeviceViewGraphCard = ({
  sensor,
  highlight,
  setHighlight,
  onSetStaticPosition,
  viewType,
  lockToNow,
  setLockToNow,
  onAnimate,
}: {
  sensor: Sensor;
  highlight?: Highlight;
  setHighlight: Dispatch<SetStateAction<Highlight | undefined>>;
  onSetStaticPosition: (p: { interval: Interval; level: Level }) => void;
  viewType: ViewType;
  lockToNow: boolean;
  setLockToNow: (value: boolean) => void;
  onAnimate?: AnimationFunc;
}): JSX.Element | null => {
  const controlRef = useRef<HTMLDivElement>(null);

  return (
    <ContentCard
      size={"small"}
      title={getTitle(sensor)}
      extra={<span ref={controlRef} />}
      key={sensor.deviceId + sensor.key}
    >
      <NormalGraph
        key={viewType}
        lockToNow={lockToNow}
        setLockToNow={setLockToNow}
        height={300}
        series={[
          {
            sensor,
            type: sensor.keyType2 === KeyType2.TimeSeries ? GraphType.Area : GraphType.Bar,
            color: getColor(sensor.keyType),
            scaleSide: "left",
          },
        ]}
        highlight={highlight}
        onHighlight={setHighlight}
        onSetStaticPosition={onSetStaticPosition}
        controlRef={controlRef}
        viewType={viewType}
        onAnimate={onAnimate}
      />
    </ContentCard>
  );
};

import { Level } from "../../../store/types";
import { Interval } from "luxon";

// Space between axis numbers and data space
export const AXIS_PADDING = 10;
export const HIGHLIGHT_PADDING_OVERSIZE = 7;
export const HIGHLIGHT_PADDING = 10;

const DAY_SHORT = {
  1: "M",
  2: "T",
  3: "K",
  4: "T",
  5: "P",
  6: "L",
  7: "S",
};

const DAY_SHORT_2 = {
  1: "Ma",
  2: "Ti",
  3: "Ke",
  4: "To",
  5: "Pe",
  6: "La",
  7: "Su",
};

const MONTH = {
  1: "Tammikuu",
  2: "Helmikuu",
  3: "Maaliskuu",
  4: "Huhtikuu",
  5: "Toukokuu",
  6: "Kesäkuu",
  7: "Heinäkuu",
  8: "Elokuu",
  9: "Syyskuu",
  10: "Lokakuu",
  11: "Marraskuu",
  12: "Joulukuu",
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MONTH_SHORT = {
  1: "T",
  2: "H",
  3: "M",
  4: "H",
  5: "T",
  6: "K",
  7: "H",
  8: "E",
  9: "S",
  10: "L",
  11: "M",
  12: "J",
};

const MONTH_SHORT_2 = {
  1: "Tam",
  2: "Hel",
  3: "Maa",
  4: "Huh",
  5: "Tou",
  6: "Kes",
  7: "Hei",
  8: "Elo",
  9: "Syy",
  10: "Lok",
  11: "Mar",
  12: "Jou",
};

const MONTH_SHORT_3 = {
  1: "Tammi",
  2: "Helmi",
  3: "Maalis",
  4: "Huhti",
  5: "Touko",
  6: "Kesä",
  7: "Heinä",
  8: "Elo",
  9: "Syys",
  10: "Loka",
  11: "Marras",
  12: "Joulu",
};

const CELL_BACKGROUND_GREY = "grey";
const CELL_BACKGROUND_RED = "red";

export const X_AXIS_FORMAT = {
  [Level.raw]: {
    highlight: {
      format: (interval: Interval) => `${interval.start.toFormat("H:mm:ss")}`,
    },
    low: {
      placement: (interval: Interval) => interval.start.toMillis(),
      format: (interval: Interval, pixels: number) => {
        if (pixels > 35) {
          return interval.start.toFormat("H:mm");
        }
        if (interval.start.minute % 2 === 0) {
          return interval.start.toFormat("H:mm");
        }
        return "";
      },
    },
    medium: {
      unit: "hour",
      cellColor: (interval: Interval) => (interval.start.hour % 2 !== 0 ? CELL_BACKGROUND_GREY : null),
    },
    high: {
      format: (interval: Interval, pixels: number) =>
        `${DAY_SHORT_2[interval.start.weekday]} ${interval.start.toFormat("d.L.y")}`,
      unit: "day",
    },
  },
  [Level.minute]: {
    highlight: {
      format: (interval: Interval) => `${interval.start.toFormat("H:mm")} - ${interval.end.toFormat("H:mm")}`,
    },
    low: {
      placement: (interval: Interval) => interval.start.toMillis(),
      format: (interval: Interval, pixels: number) => {
        if (pixels > 4) {
          if (interval.start.minute % 10 === 0) {
            const paddedMinutes = interval.start.minute < 10 ? "0" + interval.start.minute : interval.start.minute;
            return `${interval.start.hour}:${paddedMinutes}`;
          }
          return "";
        }
        if (interval.start.minute % 20 === 0) {
          const paddedMinutes = interval.start.minute < 10 ? "0" + interval.start.minute : interval.start.minute;
          return `${interval.start.hour}:${paddedMinutes}`;
        }
        return "";
      },
    },
    medium: {
      unit: "hour",
      cellColor: (interval: Interval) => (interval.start.hour % 2 !== 0 ? CELL_BACKGROUND_GREY : null),
    },
    high: {
      format: (interval: Interval, pixels: number) => {
        return `${DAY_SHORT_2[interval.start.weekday]} ${interval.start.day}.${interval.start.month}.${
          interval.start.year
        }`;
      },
      unit: "day",
    },
  },
  [Level.hour]: {
    highlight: {
      format: (interval: Interval) => {
        return `${interval.start.hour} - ${interval.end.hour}`;
      },
    },
    low: {
      placement: (interval: Interval) => interval.start.toMillis(),
      format: (interval: Interval, pixels: number) => {
        if (pixels > 20) {
          return `${interval.start.hour}`;
        }
        if (pixels > 5) {
          return interval.start.hour % 6 === 0 ? `${interval.start.hour}` : "";
        }
        return interval.start.hour % 12 === 0 ? `${interval.start.hour}` : "";
      },
    },
    medium: {
      unit: "day",
      cellColor: (interval: Interval) => {
        if (interval.start.weekday === 7) {
          return CELL_BACKGROUND_RED;
        }
        if (interval.start.weekday % 2 === 0) {
          return CELL_BACKGROUND_GREY;
        }
        return null;
      },
    },
    high: {
      format: (interval: Interval, pixels: number) => {
        if (pixels > 100) {
          return `${DAY_SHORT_2[interval.start.weekday]} ${interval.start.day}.${interval.start.month}.${
            interval.start.year
          }`;
        }
        return `${interval.start.day}.${interval.start.month}.${interval.start.year}`;
      },
      unit: "day",
    },
  },
  [Level.day]: {
    highlight: {
      format: (interval: Interval) => `${DAY_SHORT[interval.start.weekday]}${interval.start.day}`,
    },
    low: {
      placement: (interval: Interval) =>
        interval.start.toMillis() + (interval.end.toMillis() - interval.start.toMillis()) / 2,
      format: (interval: Interval, pixels: number) => {
        if (pixels > 23) {
          return `${DAY_SHORT[interval.start.weekday]}${interval.start.day}`;
        }
        if (pixels > 4) {
          return interval.start.weekday === 1 ? `${DAY_SHORT[interval.start.weekday]}${interval.start.day}` : "";
        }
        if (pixels > 2) {
          return interval.start.weekday === 1 ? `${interval.start.day}` : "";
        }
        return "";
      },
    },
    medium: {
      unit: "month",
      cellColor: (interval: Interval) => (interval.start.month % 2 === 0 ? CELL_BACKGROUND_GREY : null),
    },
    high: {
      format: (interval: Interval, pixels: number) => {
        if (pixels > 150) {
          return `${MONTH[interval.start.month]} ${interval.start.year}`;
        } else {
          return `${MONTH_SHORT_2[interval.start.month]} ${interval.start.year}`;
        }
      },
      unit: "month",
    },
  },
  [Level.month]: {
    highlight: {
      format: (interval: Interval) => `${MONTH[interval.start.month]}`,
    },
    low: {
      placement: (interval: Interval) =>
        interval.start.toMillis() + (interval.end.toMillis() - interval.start.toMillis()) / 2,
      format: (interval: Interval, pixels: number) => {
        if (pixels > 62) {
          return `${MONTH[interval.start.month]}`;
        }
        if (pixels > 40) {
          return `${MONTH_SHORT_3[interval.start.month]}`;
        }

        if (pixels > 17) {
          if ([1, 4, 7, 10].includes(interval.start.month)) {
            return `${MONTH_SHORT_3[interval.start.month]}`;
          }
        }
        if (pixels > 12) {
          if ([1, 7].includes(interval.start.month)) {
            return `${MONTH[interval.start.month]}`;
          }
        }
        if (pixels > 7) {
          if ([1, 7].includes(interval.start.month)) {
            return `${MONTH_SHORT_3[interval.start.month]}`;
          }
        }
        if (pixels > 5) {
          if ([1, 7].includes(interval.start.month)) {
            return `${MONTH_SHORT_2[interval.start.month]}`;
          }
        }
        if (interval.start.month === 1) {
          return `${MONTH_SHORT_2[interval.start.month]}`;
        }
        return "";
      },
    },
    medium: {
      unit: "year",
      cellColor: (interval: Interval) => (interval.start.year % 2 !== 0 ? CELL_BACKGROUND_GREY : null),
    },
    high: {
      format: (interval: Interval, pixels: number) => `${interval.start.year}`,
      unit: "year",
    },
  },
  [Level.year]: {
    highlight: {
      format: (interval: Interval) => {
        return interval.start.year.toString();
      },
    },
    low: {
      placement: (interval: Interval) =>
        interval.start.toMillis() + (interval.end.toMillis() - interval.start.toMillis()) / 2,
      format: (interval: Interval, pixels: number) => {
        if (pixels > 40) {
          return interval.start.year.toString();
        }
        return interval.start.year.toString().substring(2);
      },
    },
    medium: {
      unit: "year",
      cellColor: (interval: Interval) => (interval.start.year % 2 !== 0 ? CELL_BACKGROUND_GREY : null),
    },
    high: {
      format: (interval: Interval, pixels: number) => "",
      unit: "year",
    },
  },
};

import { Outlet, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { AdminOrganizationList } from "./view/admin/organizations/AdminOrganizationList";
import { CreateOrganization } from "./view/admin/organizations/new/CreateOrganization";
import { AdminOrganizationTabs } from "./view/admin/organizations/organization/AdminOrganizationTabs";
import { AdminUserList } from "./view/admin/users/AdminUserList";
import { AdminUserTabs } from "./view/admin/users/user/AdminUserTabs";
import { AdminDeviceList } from "./view/admin/devices/AdminDeviceList";
import { AdminDeviceTabs } from "./view/admin/devices/device/AdminDeviceTabs";
import { AppLayout } from "./AppLayout";
import { SensorBrowser } from "./view/devices/SensorBrowser";
import { Reports } from "./view/reports/Reports";
import { UserTabs } from "./view/profile/UserTabs";
import { CreateUser } from "./view/admin/users/new/CreateUser";
import { Logout } from "./view/logout/Logout";
import { Login } from "./view/login/Login";
import { AdminOrganization } from "./view/admin/organizations/AdminOrganization";
import { AdminUser } from "./view/admin/users/AdminUser";
import { AdminDevice } from "./view/admin/devices/AdminDevice";
import { ConfigurationTabs } from "./view/admin/configuration/ConfigurationTabs";
import { DeviceTypes } from "./view/admin/configuration/deviceTypes/DeviceTypes";
import { EditDevice } from "./view/devices/device/settings/EditDevice";
import { useEffect } from "react";
import { devicesBaseRoute } from "./utils/routes";

export const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.length <= 1) {
      navigate(devicesBaseRoute);
    }
  }, [location.pathname.length, navigate]);

  return (
    <Routes>
      <Route path={"login"} element={<Login />} />
      <Route element={<AppLayout />}>
        <Route index element={<SensorBrowser />} />
        <Route path={"devices/:deviceId/settings"} element={<EditDevice />} />
        <Route path={"devices"} element={<SensorBrowser />}>
          <Route path={":deviceId"} element={<Outlet />} />
        </Route>
        <Route path={"reports"} element={<Reports />} />
        <Route path={"profile"} element={<UserTabs />}>
          <Route path={":activeTab"} element={<Outlet />} />
        </Route>
        <Route path={"logout"} element={<Logout />} />
        <Route path={"admin"} element={<Outlet />}>
          <Route path={"organizations"} element={<AdminOrganization />}>
            <Route index element={<AdminOrganizationList />} />
            <Route path={"new"} element={<CreateOrganization />} />
            <Route path={":orgId"} element={<AdminOrganizationTabs />}>
              <Route path={":activeTab"} element={<Outlet />} />
            </Route>
          </Route>
          <Route path={"users"} element={<AdminUser />}>
            <Route index element={<AdminUserList />} />
            <Route path={"new"} element={<CreateUser />} />
            <Route path={":userId"} element={<AdminUserTabs />}>
              <Route path={":activeTab"} element={<Outlet />} />
            </Route>
          </Route>
          <Route path={"devices"} element={<AdminDevice />}>
            <Route index element={<AdminDeviceList />} />
            <Route path={":deviceId"} element={<AdminDeviceTabs />}>
              <Route path={":activeTab"} element={<Outlet />} />
            </Route>
          </Route>
          <Route path={"configuration"} element={<ConfigurationTabs />}>
            <Route path={":deviceTypes"} element={<DeviceTypes />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

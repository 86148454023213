import React, { useCallback, useEffect, useRef } from "react";
import { DateTime, Interval } from "luxon";
import { Level, ValueEnum } from "../../store/types";
import { getX } from "./utils";
import { GraphActions, GraphState } from "./reducer";
import { Highlight, Position } from "./types";
import { sensorLevel2ValueEnum } from "../../store/utils";
import { useLevels } from "./useLevels";
import { AnimationFunc } from "./useAnimateAll";
import { useSetAtom } from "jotai";
import { positionAtom } from "./atoms/atoms";

const DRAG_TREHSOLD = 10;

export const useInteraction = (
  graphState: GraphState,
  graphDispatch: React.Dispatch<GraphActions>,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  onHighlight: (highlight?: Highlight) => void,
  lockToNow: boolean,
  setLockToNow: (value: boolean) => void,
  onSetStaticPosition?: (position: Position) => void,
  onAnimate?: AnimationFunc
) => {
  const setPosition = useSetAtom(positionAtom);

  const pointerPosition = useRef<{ x: number; y: number }>();
  const highlightTimeout = useRef<NodeJS.Timeout>();

  const dragState = useRef<{
    startX: number;
    startY: number;
    startIntervalStartMs: number;
    startIntervalEndMs: number;
    dragging: false | "x" | "y";
  }>();

  const { zoomIn } = useLevels(lockToNow, setLockToNow);

  const detectHighlight = useCallback(
    (x: number, y: number): Highlight | null => {
      if (graphState.mode === "normal") {
        /*  Allow the user to move finger below the graph for better visibility
        if (y < graphState.padding.top || y > graphState.height - graphState.padding.bottom) {
          return null;
        }
        */
        if (x < graphState.padding.left || x > graphState.width - graphState.padding.right) {
          return null;
        }
      }

      const subState = {
        interval: graphState.interval,
        padding: graphState.padding,
        xPixelFactor: graphState.xPixelFactor,
      };

      if (graphState.level === Level.raw) {
        const visibleValues = graphState.series[0].visibleValues;
        const sensor = graphState.series[0].sensor;
        if (!visibleValues?.length || !sensor.keyType2) {
          return null;
        }

        const valueEnum = sensorLevel2ValueEnum(sensor.keyType2, graphState.level);

        if (valueEnum === ValueEnum.LMIN) {
          if (graphState.rawLevelHighlightType === "raw") {
            for (let i = 0; i < visibleValues.length - 1; i++) {
              const thisValue = visibleValues[i];
              const nextValue = visibleValues[i + 1];
              const start = thisValue.startMs + thisValue.interval.toDuration().toMillis() / 2;
              const end = nextValue.startMs + nextValue.interval.toDuration().toMillis() / 2;
              const startX = getX(subState, start);
              const endX = getX(subState, end);
              if (startX <= x && x <= endX) {
                return {
                  interval: Interval.fromDateTimes(thisValue.end, thisValue.end),
                  exact: {
                    value: thisValue,
                    sensor,
                    timestamp: thisValue.end, // TODO verify
                  },
                };
              }
            }
            if (visibleValues.length > 0) {
              const thisValue = visibleValues[visibleValues.length - 1];
              const start = thisValue.startMs + thisValue.interval.toDuration().toMillis() / 2;
              const end = thisValue.endMs;
              const startX = getX(subState, start);
              const endX = getX(subState, end);
              if (startX <= x && x <= endX) {
                return {
                  interval: Interval.fromDateTimes(thisValue.end, thisValue.end),
                  exact: {
                    value: thisValue,
                    sensor,
                    timestamp: thisValue.end, // TODO verify
                  },
                };
              }
            }
          } else if (graphState.rawLevelHighlightType === "value") {
            for (let i = 1; i < visibleValues.length; i++) {
              const prevValue = visibleValues[i - 1];
              const thisValue = visibleValues[i];
              const nextValue = i < visibleValues.length - 1 ? visibleValues[i + 1] : visibleValues[i];
              const start = prevValue.timestampMs + (thisValue.timestampMs - prevValue.timestampMs) / 2;
              const end = thisValue.timestampMs + (nextValue.timestampMs - thisValue.timestampMs) / 2;
              const startX = getX(subState, start);
              const endX = getX(subState, end);
              if (startX <= x && x <= endX) {
                return {
                  interval: Interval.fromDateTimes(thisValue.end, thisValue.end),
                  exact: {
                    value: thisValue,
                    sensor,
                    timestamp: thisValue.timestamp,
                  },
                };
              }
            }
          }
        } else if (valueEnum === ValueEnum.RAW) {
          for (let i = 1; i < visibleValues.length; i++) {
            const prevValue = visibleValues[i - 1];
            const thisValue = visibleValues[i];
            const nextValue = i < visibleValues.length - 1 ? visibleValues[i + 1] : visibleValues[i];
            const start = prevValue.startMs + (thisValue.startMs - prevValue.startMs) / 2;
            const end = thisValue.startMs + (nextValue.startMs - thisValue.startMs) / 2;
            const startX = getX(subState, start);
            const endX = getX(subState, end);
            if (startX <= x && x <= endX) {
              return {
                interval: Interval.fromDateTimes(thisValue.end, thisValue.end),
                exact: {
                  value: thisValue,
                  sensor,
                  timestamp: thisValue.timestamp,
                },
              };
            }
          }
        }
      } else {
        for (let interval of graphState.levelIntervals) {
          const ix = getX(subState, interval.start.toMillis());
          const ix2 = getX(subState, interval.end.toMillis());
          if (ix <= x && x <= ix2) {
            return { interval };
          }
        }
      }
      return null;
    },
    [
      graphState.mode,
      graphState.interval,
      graphState.padding,
      graphState.xPixelFactor,
      graphState.level,
      graphState.width,
      graphState.series,
      graphState.rawLevelHighlightType,
      graphState.levelIntervals,
    ]
  );

  // If the user long clicks without moving the pointer, then we should highlight
  const handleHighlightTimeout = useCallback(
    (x: number, y: number) => {
      clearHighlightTimer();
      if (dragState.current?.dragging) {
        return;
      }

      // Either the user already initiated drag, or wants to just long click for highlight
      dragState.current = undefined;

      const highlight = detectHighlight(x, y);
      if (highlight) {
        onHighlight(highlight);
      }
    },
    [detectHighlight, onHighlight]
  );

  const startHighlightTimer = useCallback(
    (x: number, y: number) => {
      clearHighlightTimer();

      highlightTimeout.current = setTimeout(() => {
        handleHighlightTimeout(x, y);
      }, 500);
    },
    [handleHighlightTimeout]
  );

  const clearHighlightTimer = () => {
    if (!highlightTimeout.current) {
      return;
    }
    clearTimeout(highlightTimeout.current);
    highlightTimeout.current = undefined;
  };

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      pointerPosition.current = { x: event.offsetX, y: event.offsetY };

      // Highlight when pointer has not been down
      if (!dragState.current) {
        const highlight = detectHighlight(event.offsetX, event.offsetY);
        if (highlight) {
          onHighlight(highlight);
        }
        return;
      }

      // TODO Try to cancel any control over pointer move when dragging in y axel to allow scrolling on mobile
      if (dragState.current.dragging === "y") {
        return;
      }
      if (dragState.current.dragging === "x") {
        const diff = dragState.current?.startX - event.offsetX;
        const diffMs = diff / graphState.xPixelFactor;
        const startMs = dragState.current?.startIntervalStartMs + diffMs;
        const endMs = dragState.current?.startIntervalEndMs + diffMs;
        setPosition({
          interval: Interval.fromDateTimes(DateTime.fromMillis(startMs), DateTime.fromMillis(endMs)),
          level: graphState.level,
        });
        return;
      }

      // Start drag if threshold is reached
      if (Math.abs(dragState.current?.startX - event.offsetX) > DRAG_TREHSOLD) {
        dragState.current.dragging = "x";
        graphDispatch({ type: "setDragging", payload: true });
        setLockToNow(false);
      } else if (Math.abs(dragState.current?.startY - event.offsetY) > DRAG_TREHSOLD) {
        dragState.current.dragging = "y";
      }
    },
    [detectHighlight, onHighlight, graphState.xPixelFactor, graphState.level, setPosition, graphDispatch, setLockToNow]
  );

  const handlePointerLeave = useCallback(() => {
    clearHighlightTimer();
    pointerPosition.current = undefined;
    onHighlight();
    if (dragState.current) {
      onSetStaticPosition?.({ interval: graphState.interval, level: graphState.level });
    }
    dragState.current = undefined;
    graphDispatch({ type: "setDragging", payload: false });
  }, [graphDispatch, graphState.interval, graphState.level, onHighlight, onSetStaticPosition]);

  const handlePointerEnter = useCallback(() => {
    clearHighlightTimer();
    if (dragState.current) {
      onSetStaticPosition?.({ interval: graphState.interval, level: graphState.level });
    }
    dragState.current = undefined;
    graphDispatch({ type: "setDragging", payload: false });
  }, [graphDispatch, graphState.interval, graphState.level, onSetStaticPosition]);

  const handlePointerDown = useCallback(
    (e: PointerEvent) => {
      // This is to prevent the following scenario
      // 1. click down
      // 2. drag out of canvas
      // 3. browser selects page content for copy/paste
      e.preventDefault();

      startHighlightTimer(e.offsetX, e.offsetY);

      dragState.current = {
        startX: e.offsetX,
        startY: e.offsetY,
        startIntervalStartMs: graphState.interval.start.toMillis(),
        startIntervalEndMs: graphState.interval.end.toMillis(),
        dragging: false,
      };
      onHighlight();
    },
    [graphState.interval.end, graphState.interval.start, onHighlight, startHighlightTimer]
  );

  const handlePointerUp = useCallback(
    (event: PointerEvent) => {
      if (dragState.current?.dragging) {
        const diff = dragState.current?.startX - event.offsetX;
        const diffMs = diff / graphState.xPixelFactor;
        const startMs = dragState.current?.startIntervalStartMs + diffMs;
        const endMs = dragState.current?.startIntervalEndMs + diffMs;
        onSetStaticPosition?.({
          interval: Interval.fromDateTimes(DateTime.fromMillis(startMs), DateTime.fromMillis(endMs)),
          level: graphState.level,
        });
        dragState.current = undefined;
        graphDispatch({ type: "setDragging", payload: false });
        return;
      }
      dragState.current = undefined;
      graphDispatch({ type: "setDragging", payload: false });

      const highlight = detectHighlight(event.offsetX, event.offsetY);
      if (!highlight?.interval) {
        return;
      }

      // Only zoom in while highlight timer is still running
      if (!highlightTimeout.current) {
        return;
      }
      clearHighlightTimer();

      const next = zoomIn(graphState.interval, highlight.interval, false);
      if (!next) {
        return;
      }
      onAnimate?.(graphState.level, {
        interval: next.interval,
        level: next.level,
      });
    },
    [
      graphDispatch,
      detectHighlight,
      zoomIn,
      graphState.interval,
      graphState.level,
      graphState.xPixelFactor,
      onAnimate,
      onSetStaticPosition,
    ]
  );

  useEffect(() => {
    if (dragState.current) {
      return;
    }
    if (!pointerPosition.current) {
      return;
    }
    const highlight = detectHighlight(pointerPosition.current.x, pointerPosition.current.y);
    onHighlight(highlight || undefined);
  }, [detectHighlight, graphState.interval, onHighlight, setPosition]);

  const handleTouchMove = useCallback((event: TouchEvent) => {
    // Allow the browser to handle touch events at the beginning gesture
    // This way the browser can turn the gesture into a scroll of the page
    if (highlightTimeout.current) {
      return;
    }
    event.preventDefault();
  }, []);

  useEffect(() => {
    const element = canvasRef.current;
    if (!element) {
      return;
    }
    element?.addEventListener("touchmove", handleTouchMove);
    element?.addEventListener("pointerenter", handlePointerEnter);
    element?.addEventListener("pointermove", handlePointerMove);
    element?.addEventListener("pointerleave", handlePointerLeave);
    if (graphState.mode === "normal") {
      element?.addEventListener("pointerdown", handlePointerDown);
      element?.addEventListener("pointerup", handlePointerUp);
    }
    return () => {
      element?.removeEventListener("touchmove", handleTouchMove);
      element?.removeEventListener("pointerenter", handlePointerEnter);
      element?.removeEventListener("pointermove", handlePointerMove);
      element?.removeEventListener("pointerleave", handlePointerLeave);
      if (graphState.mode === "normal") {
        element?.removeEventListener("pointerdown", handlePointerDown);
        element?.removeEventListener("pointerup", handlePointerUp);
      }
    };
  }, [
    canvasRef,
    graphState.mode,
    handlePointerDown,
    handlePointerEnter,
    handlePointerLeave,
    handlePointerMove,
    handlePointerUp,
    handleTouchMove,
  ]);
};

import { Table } from "../../../../../components/Table/Table";
import { useParams } from "react-router-dom";
import {
  useGetPermissionsQuery,
  useGetDevicesQuery,
  useSetDevicesForOrganizationMutation,
} from "../../../../../store/api";
import { Device } from "../../../../../store/types";
import { useCallback, useMemo, useState } from "react";
import { dateRenderer } from "../../../../../components/Table/cellRenderer";
import { WIDE_COLUMN_WIDTH } from "../../../../../components/constants";
import { Button, Input, Modal } from "antd";
import { ColDef, GridApi, IRowNode } from "ag-grid-community";
import { ImportOutlined } from "@ant-design/icons";
import { LinkButton } from "../../../../../components/LinkButton";
import { getAdminDeviceInfoRoute } from "../../../../../utils/routes";

export const AdminOrganizationDevicesList = () => {
  const permissions = useGetPermissionsQuery();
  const devices = useGetDevicesQuery();
  const [setDevicesForOrganization] = useSetDevicesForOrganizationMutation();
  const [importModal, setImportModal] = useState(false);
  const [importUserId, setImportUserId] = useState<null | string>(null);
  const [gridApi, setGridApi] = useState<GridApi>();
  const { orgId } = useParams();

  const isSelected = useCallback(
    (device: Device) =>
      !!permissions.data?.organizationDevicePermissions.find(
        (perm) => perm.organizationId === orgId && perm.deviceId === device.deviceId
      ),
    [orgId, permissions.data?.organizationDevicePermissions]
  );

  const columns = useMemo<ColDef[]>(
    () => [
      {
        field: "deviceId",
        headerName: "ID",
        cellRenderer: (cell: any) => <LinkButton path={getAdminDeviceInfoRoute(cell.value)} text={cell.value} />,
      },
      {
        field: "name",
        headerName: "Nimi",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        field: "description",
        headerName: "Kuvaus",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        sort: "desc",
        field: "created",
        headerName: "Luotu",
        cellRenderer: dateRenderer,
        getQuickFilterText: dateRenderer,
      },
    ],
    []
  );

  if (!orgId) return null;

  const saveHandler = async (devices: Device[]) => {
    await setDevicesForOrganization({
      organizationId: orgId,
      deviceIds: devices.map((d) => d.deviceId),
    });
  };

  return (
    <>
      <Table
        rowData={devices.data}
        loading={devices.isFetching || permissions.isFetching}
        columns={columns}
        multiSelect
        onSave={saveHandler}
        isSelected={isSelected}
        onGridReady={setGridApi}
        toolbar={
          <Button type="primary" icon={<ImportOutlined />} onClick={() => setImportModal(true)}>
            Tuo käyttäjältä
          </Button>
        }
      />

      {importModal && (
        <Modal
          title="Tuo käyttäjältä"
          open={importModal}
          onOk={() => {
            const importedUserDevices = permissions.data?.userDevicePermissions
              .filter((p) => p.userId.toString() === importUserId)
              .map((p) => p.deviceId);
            gridApi?.forEachNode((node: IRowNode<Device>) => {
              node.setSelected(
                node.isSelected() || (!!node.data?.deviceId && !!importedUserDevices?.includes(node.data?.deviceId))
              );
            });

            setImportUserId(null);
            setImportModal(false);
          }}
          onCancel={() => {
            setImportUserId(null);
            setImportModal(false);
          }}
        >
          <p>
            <Input placeholder={"Käyttäjä ID"} onChange={(e) => setImportUserId(e.target.value)} />
          </p>
          <p>
            Käyttäjän laitteet lisätään taulukkoon. Tarkista valinnat taulukosta ja tallenna. Laitteita ei poisteta
            käyttäjältä.
          </p>
        </Modal>
      )}
    </>
  );
};

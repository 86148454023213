import { useGetUsersQuery } from "../../../store/api";
import { useMemo } from "react";
import { Select } from "antd";

const getLabel = (userName: string | null, email: string | null): string => {
  if (!userName) {
    return email as string;
  }
  if (!email) {
    return userName as string;
  }
  return `${userName} / ${email}`;
};

export const UserSelect = ({
  defaultUserId,
  onSelect,
  onClear,
}: {
  defaultUserId: number | null;
  onSelect: (userId: number) => void;
  onClear: () => void;
}): JSX.Element | null => {
  const users = useGetUsersQuery();

  const options = useMemo<{ label: string; value: number }[]>(() => {
    if (!users.data) {
      return [];
    }
    return users.data
      .map((u) => ({
        value: u.userId,
        label: getLabel(u.userName, u.email),
      }))
      .slice()
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
  }, [users.data]);

  if (!options.length) {
    return null;
  }

  return (
    <Select
      defaultValue={options.find((o) => o.value === defaultUserId)?.value}
      showSearch
      placeholder={"Käyttäjä"}
      allowClear
      options={options}
      style={{ width: 400 }}
      filterOption={(input, option) => (option?.label || "").toLowerCase().includes(input.toLowerCase())}
      onSelect={onSelect}
      onClear={onClear}
    />
  );
};

import { useGetSensorsQuery } from "../../store/api";
import { useMemo } from "react";
import { Select } from "antd";
import { humanizeKeyType } from "../../store/utils";
import { KeyType } from "../../store/types";

export const DeviceTypeSelect = ({
  onSelect,
  onClear,
  defaultSensorType,
}: {
  onSelect: (sensorType: KeyType | undefined) => void;
  onClear: () => void;
  defaultSensorType: KeyType | undefined;
}): JSX.Element | null => {
  const sensors = useGetSensorsQuery();

  const options = useMemo<{ label: string; value: number }[]>(() => {
    if (!sensors.data) {
      return [];
    }
    const existingKeyTypes = [
      ...new Set(
        sensors.data.reduce((all, sensor) => {
          if (!sensor.keyType) {
            return all;
          }
          return [...all, sensor.keyType];
        }, [] as number[])
      ),
    ];

    return existingKeyTypes
      .map((keyType) => ({
        value: keyType,
        label: humanizeKeyType(keyType),
      }))
      .slice()
      .sort((a, b) => {
        if (a.value < b.value) {
          return -1;
        }
        if (a.value > b.value) {
          return 1;
        }
        return 0;
      });
  }, [sensors.data]);

  if (!options.length) {
    return null;
  }

  return (
    <Select
      defaultValue={options.find((o) => o.value === defaultSensorType)?.value}
      placeholder={"Sensori"}
      allowClear
      options={options}
      style={{ width: 200 }}
      filterOption={(input, option) => (option?.label || "").toLowerCase().includes(input.toLowerCase())}
      onSelect={onSelect}
      onClear={onClear}
    />
  );
};

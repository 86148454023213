import { useParams, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import { AdminDeviceUsersList } from "./users/AdminDeviceUsersList";
import styled from "styled-components";
import { AdminEditDevice } from "./info/AdminEditDevice";
import { useMemo } from "react";
import { FullSizeLayout } from "../../../../utils/styled";
import { SensorConfiguration } from "./sensors/SensorConfiguration";
import { SessionEditor } from "./sessions/SessionEditor";
import { SettingEditor } from "./settings/SettingEditor";

export const AdminDeviceTabs = () => {
  const { activeTab } = useParams();
  const navigate = useNavigate();
  const tabs = useMemo(
    () => [
      {
        label: "Tiedot",
        key: "info",
        children: <AdminEditDevice />,
      },
      {
        label: "Käyttäjät",
        key: "users",
        children: (
          <FullSizeLayout>
            Lista käyttäjistä jotka on liitetty suoraan laitteeseen. Käyttäjillä voi olla oikeus laitteeseen myös
            organisaation kautta, mutta niitä yhteyksiä ei näy tässä listassa.
            <AdminDeviceUsersList />
          </FullSizeLayout>
        ),
      },
      {
        label: "Sensorit",
        key: "sensors",
        children: <SensorConfiguration />,
      },
      {
        label: "Sessiot",
        key: "sessions",
        children: <SessionEditor />,
      },
      {
        label: "Asetukset",
        key: "settings",
        children: <SettingEditor />,
      },
    ],
    []
  );
  return (
    <Layout>
      <Tabs onChange={navigate} activeKey={activeTab} style={{ height: "100%" }} items={tabs} />
    </Layout>
  );
};

const Layout = styled.div`
  height: 100%;
  .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-tabpane {
    height: 100%;
  }
  display: flex;
  flex-direction: column;
`;

import { DateTimeUnit } from "luxon";
import { getUnitIntervals, getX } from "../utils";
import { GraphState } from "../reducer";
import { AXIS_PADDING, X_AXIS_FORMAT } from "./axis";
import { AXIS_LINE_COLOR, GRAPH_FONT_HEIGHT } from "./constants";

export const renderXAxis = (
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  graphState: GraphState
) => {
  // Render cell backgrounds
  ctx.beginPath();
  ctx.globalAlpha = 0.1;

  const xAxisFormat = X_AXIS_FORMAT[graphState.level];

  const mediumLevelIntervals = getUnitIntervals(xAxisFormat.medium.unit as DateTimeUnit, graphState.interval);
  mediumLevelIntervals.forEach((interval) => {
    const backgroundColor = xAxisFormat.medium.cellColor(interval);
    if (!backgroundColor) {
      return;
    }
    ctx.fillStyle = backgroundColor;
    const startX = getX(graphState, interval.start.toMillis());
    const endX = getX(graphState, interval.end.toMillis());
    ctx.fillRect(
      startX,
      graphState.padding.top,
      endX - startX,
      graphState.height - graphState.padding.top - graphState.padding.bottom
    );
  });
  ctx.fill();

  ctx.beginPath();
  ctx.globalAlpha = 1;
  ctx.strokeStyle = AXIS_LINE_COLOR;
  ctx.textAlign = "center";
  ctx.textBaseline = "top";
  ctx.fillStyle = "black";

  const pixelsPerLowUnit =
    (graphState.width - graphState.padding.left - graphState.padding.right) / graphState.levelIntervals.length;

  graphState.levelIntervals.forEach((interval) => {
    const levelFormat = xAxisFormat.low;
    const text = levelFormat.format(interval, pixelsPerLowUnit);
    const placement = getX(graphState, levelFormat.placement(interval));

    ctx.fillText(text, placement, graphState.height - graphState.padding.bottom + AXIS_PADDING);

    if (text) {
      ctx.moveTo(placement, graphState.height - graphState.padding.bottom);
      ctx.lineTo(placement, graphState.height - graphState.padding.bottom + AXIS_PADDING / 2);
    }
  });

  const highLevelIntervals = getUnitIntervals(xAxisFormat.high.unit as DateTimeUnit, graphState.interval);
  const pixelsPerHighUnit =
    (graphState.width - graphState.padding.left - graphState.padding.right) / highLevelIntervals.length;

  // If we only have one or two high level axis labels, we risk having them out of the screen
  if (highLevelIntervals.length < 3) {
    highLevelIntervals.forEach((interval) => {
      const levelFormat = xAxisFormat.high;
      const text = levelFormat.format(interval, pixelsPerHighUnit / 2);
      const center = interval.start.plus(interval.end.diff(interval.start).milliseconds / 2);
      const width = ctx.measureText(text).width;
      let x = getX(graphState, center.toMillis());
      if (x - width / 2 < graphState.padding.left) {
        ctx.textAlign = "left";
        x = graphState.padding.left;
      } else if (x + width / 2 > graphState.width - graphState.padding.right) {
        ctx.textAlign = "right";
        x = graphState.width - graphState.padding.right;
      } else {
        ctx.textAlign = "center";
      }

      ctx.fillText(
        levelFormat.format(interval, pixelsPerHighUnit / 2),
        x,
        graphState.height - graphState.padding.bottom + AXIS_PADDING + AXIS_PADDING + GRAPH_FONT_HEIGHT
      );

      ctx.moveTo(getX(graphState, interval.start.toMillis()), graphState.padding.top);
      ctx.lineTo(getX(graphState, interval.start.toMillis()), graphState.height - graphState.padding.bottom);
    });
  } else {
    highLevelIntervals.forEach((interval) => {
      const levelFormat = xAxisFormat.high;
      const center = interval.start.plus(interval.end.diff(interval.start).milliseconds / 2);

      ctx.fillText(
        levelFormat.format(interval, pixelsPerHighUnit),
        getX(graphState, center.toMillis()),
        graphState.height - graphState.padding.bottom + AXIS_PADDING + AXIS_PADDING + GRAPH_FONT_HEIGHT
      );

      ctx.moveTo(getX(graphState, interval.start.toMillis()), graphState.padding.top);
      ctx.lineTo(getX(graphState, interval.start.toMillis()), graphState.height - graphState.padding.bottom);
    });
  }
  ctx.stroke();
};

import { useEffect, useState } from "react";

// This returns undefined by default, until the delay has passed
// Not sure if this is actually needed
export const useDebounceNonDefault = <T>(value: T, delay?: number): T | undefined => {
  const [debouncedValue, setDebouncedValue] = useState<T>();

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

import { Select } from "antd";
import { KeyType } from "../../../../store/types";
import { humanizeKeyType } from "../../../../store/utils";
import { useState } from "react";

export const SensorSelect = ({
  onSelect,
  value,
  disabled,
}: {
  onSelect: (keyTypeId: number | null) => void;
  value: KeyType | null;
  disabled?: boolean;
}): JSX.Element | null => {
  const [options] = useState(() =>
    Object.keys(KeyType)
      .filter((type) => !isNaN(Number(type)))
      .map((type) => parseInt(type))
      .map((type) => {
        return {
          label: humanizeKeyType(type),
          value: type,
        };
      })
  );

  return (
    <Select
      disabled={disabled}
      options={options}
      value={value}
      allowClear
      style={{ width: 200 }}
      onSelect={onSelect}
      onClear={() => onSelect(null)}
    />
  );
};

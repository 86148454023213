import { useCallback } from "react";
import { render } from "./render/render";
import { message } from "antd";
import { humanizeKeyType } from "../../store/utils";
import { GraphState } from "./reducer";
import { SeriesOptions } from "./types";

export const useCopy = (graphState: GraphState, series: SeriesOptions[]) => {
  const renderOffscreenBlob = useCallback(async () => {
    const canvas = new OffscreenCanvas(graphState.width * 2, graphState.height * 2);
    render(canvas, graphState, 2, true);
    return await canvas.convertToBlob();
  }, [graphState]);

  const handleCopy = useCallback(async () => {
    const blob = await renderOffscreenBlob();
    if (!blob) {
      return;
    }
    try {
      navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]);
      message.success("Kuva kopioitu leikepöydälle");
    } catch (err) {
      message.error("Selain ei tue kuvan kopiointia leikepöydälle");
    }
  }, [renderOffscreenBlob]);

  const handleDownload = useCallback(async () => {
    const blob = await renderOffscreenBlob();
    if (!blob) {
      return;
    }
    const link = document.createElement("a");
    const sensor = series[0].sensor;
    const sensorTitle = sensor.description.length
      ? `${humanizeKeyType(sensor.keyType)} - ${sensor.description}`
      : humanizeKeyType(sensor.keyType);
    link.download = `${sensor.deviceId} - ${sensorTitle}.png`;
    link.href = URL.createObjectURL(blob);
    link.click();
  }, [renderOffscreenBlob, series]);

  return { handleCopy, handleDownload };
};

import { ViewType } from "../../store/types";
import { Highlight, Position, SeriesOptions } from "./types";
import React, { RefObject } from "react";
import { Graph } from "./Graph";
import { AnimationFunc } from "./useAnimateAll";
import { useAtom } from "jotai/index";
import { positionAtom } from "./atoms/atoms";

interface NormalGraphProps {
  lockToNow: boolean;
  setLockToNow: (value: boolean) => void;
  height: number;
  series: SeriesOptions[];
  onHighlight: (highlight?: Highlight) => void;
  highlight?: Highlight;
  onSetStaticPosition: (position: Position) => void;
  controlRef: RefObject<HTMLDivElement>; // Where to render controls with portal
  viewType: ViewType;
  onAnimate?: AnimationFunc;
}

export const NormalGraph = ({
  lockToNow,
  setLockToNow,
  height,
  series,
  onHighlight,
  highlight,
  onSetStaticPosition,
  controlRef,
  viewType,
  onAnimate,
}: NormalGraphProps): JSX.Element => {
  const [position] = useAtom(positionAtom);
  const { interval, level } = position;
  return (
    <Graph
      key={viewType}
      interval={interval}
      lockToNow={lockToNow}
      setLockToNow={setLockToNow}
      level={level}
      height={height}
      mode={"normal"}
      series={series}
      highlight={highlight}
      onHighlight={onHighlight}
      onSetStaticPosition={onSetStaticPosition}
      controlRef={controlRef}
      viewType={viewType}
      onAnimate={onAnimate}
    />
  );
};

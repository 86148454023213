import { DateTime, Interval, DurationLikeObject } from "luxon";
import { DateTimeUnit } from "luxon/src/datetime";
import { TimeframeType } from "../view/devices/reducer";

export const getFromNow = (duration: DurationLikeObject): Interval => {
  const now = DateTime.now();
  return Interval.fromDateTimes(now.minus(duration), now);
};

export const getExtendedFromNow = (amount: number, unit: DateTimeUnit): Interval => {
  const now = DateTime.now();
  if (now.equals(now.startOf(unit))) {
    return Interval.fromDateTimes(now.minus({ [unit]: amount }), now);
  }
  return Interval.fromDateTimes(now.minus({ [unit]: amount }).startOf(unit), now.endOf(unit).plus({ millisecond: 1 }));
};

export const getExtendedFromNowTimeframe = (timeframe: TimeframeType) => {
  switch (timeframe) {
    case "24h":
      return getExtendedFromNow(24, "hour");
    case "30d":
      return getExtendedFromNow(30, "day");
    case "30d-min":
      return getExtendedFromNow(30, "day");
    case "20y":
      return getExtendedFromNow(20, "year");
    case "12m":
      return getExtendedFromNow(12, "month");
  }
};

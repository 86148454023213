import { useGetCurrentUserQuery, useSetCurrentUserPasswordMutation } from "../../../store/api";
import { ContentCard } from "../../../utils/styled";
import { Button, Form, Input, message } from "antd";
import { useState } from "react";

export const UserPassword = () => {
  const currentUser = useGetCurrentUserQuery();
  const [setPassword] = useSetCurrentUserPasswordMutation();
  const [reset, setReset] = useState(0);

  const handleSubmit = async (password: { newPassword: string; newPassword2: string }) => {
    try {
      await setPassword(password).unwrap();
      message.success("Salasana asetettu onnistuneesti");
    } catch (err: any) {
      if (err.data) {
        message.error(err.data);
      }
    }
    setReset((prev) => prev + 1);
  };

  if (currentUser.isFetching) return null;

  return (
    <ContentCard title={"Uusi salasana"}>
      <p>Kirjoita uusi salasana kaksi kertaa. Salasanan tulee olla vähintään kahdeksan merkkiä pitkä.</p>
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} onFinish={handleSubmit} key={reset}>
        <Form.Item label={"Salasana"} name={"newPassword"}>
          <Input.Password />
        </Form.Item>
        <Form.Item label={"Varmennus"} name={"newPassword2"}>
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 5, span: 10 }}>
          <Button type="primary" htmlType="submit">
            Tallenna
          </Button>
        </Form.Item>
      </Form>
    </ContentCard>
  );
};

import React, { memo } from "react";
import { Button, Tooltip } from "antd";
import { CopyOutlined, DownloadOutlined, ColumnHeightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { isMobileOrTablet } from "../../../utils/mobile";

const ControlsToolbar = ({
  onScale,
  onCopy,
  onDownload,
}: {
  onScale?: () => void;
  onCopy?: () => void;
  onDownload?: () => void;
}): JSX.Element => {
  // Mobile chrome would activate this on click, which is disturbing
  const enabled = !isMobileOrTablet();
  return (
    <Layout>
      <Tooltip title={enabled && "Päivitä asteikko kuvaajan kokoiseksi"}>
        <Button shape="circle" icon={<ColumnHeightOutlined />} onClick={onScale} />
      </Tooltip>
      <Tooltip title={enabled && "Kopioi kuva leikepöydälle"}>
        <Button shape="circle" icon={<CopyOutlined />} onClick={onCopy} />
      </Tooltip>
      <Tooltip title={enabled && "Tallenna kuvatiedosto"}>
        <Button shape="circle" icon={<DownloadOutlined />} onClick={onDownload} />
      </Tooltip>
    </Layout>
  );
};

export const ControlsToolbarMemo = memo(ControlsToolbar);

const Layout = styled.div`
  display: flex;
  gap: 16px;
`;

import { DeviceEditorForm } from "./DeviceEditorForm";
import { useParams, Link } from "react-router-dom";
import { useGetDevicesQuery } from "../../../../store/api";
import { BreadcrumbInCardTitle, ContentCard, LayoutContent } from "../../../../utils/styled";
import { Divider } from "antd";
import { getDeviceName } from "../../../../components/devices/utils";
import { useDeviceSensors } from "../../../../components/devices/useDeviceSensors";
import { SensorConfigurationCard } from "./SensorConfigurationCard";
import { useMemo } from "react";

export const EditDevice = () => {
  const { deviceId } = useParams();
  const devices = useGetDevicesQuery();
  const device = devices.data?.find((d) => d.deviceId === deviceId);
  const deviceSensors = useDeviceSensors(deviceId);
  const breadcrumbs = useMemo(
    () => [
      { title: <Link to={"../devices"}>Mittarit</Link> },
      { title: <Link to={`/devices/${deviceId}`}>{getDeviceName(device)}</Link> },
      { title: "Asetukset" },
    ],

    [device, deviceId]
  );

  if (!device) {
    return null;
  }

  return (
    <LayoutContent>
      <ContentCard title={<BreadcrumbInCardTitle items={breadcrumbs} />}>
        <DeviceEditorForm mode={device?.accessLevel === "admin" ? "admin" : "user"} device={device} />
      </ContentCard>
      <Divider>Sensorit</Divider>
      {deviceSensors.map((sensor) => (
        <SensorConfigurationCard
          key={sensor.key}
          sensor={sensor}
          mode={device?.accessLevel === "admin" ? "admin" : "user"}
        />
      ))}
    </LayoutContent>
  );
};

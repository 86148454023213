import { useParams } from "react-router-dom";
import styled from "styled-components";
import { SensorConfigurationCard } from "../../../../devices/device/settings/SensorConfigurationCard";
import { useDeviceSensors } from "../../../../../components/devices/useDeviceSensors";

export const SensorConfiguration = () => {
  const { deviceId } = useParams();
  const deviceSensors = useDeviceSensors(deviceId);

  return (
    <Layout>
      {deviceSensors.map((sensor) => (
        <SensorConfigurationCard key={sensor.key} sensor={sensor} mode={"admin"} />
      ))}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

import { Level, Value } from "../types";
import { JSInterval } from "../utils";
import { SensorValueType } from "./types";

export const getGaps = (interval: JSInterval, requests?: JSInterval[]): JSInterval[] => {
  if (!requests) {
    return [interval];
  }
  const requestsWithinRange = requests.filter((r) => !(r.end < interval.start || r.start > interval.end));

  if (requestsWithinRange.length === 0) {
    return [interval];
  }

  // add a boundary request to the start
  if (requestsWithinRange[0].start > interval.start) {
    requestsWithinRange.unshift({ start: interval.start, end: interval.start });
  }

  // add a boundary request to the end
  if (requestsWithinRange[requestsWithinRange.length - 1].end < interval.end) {
    requestsWithinRange.push({ start: interval.end, end: interval.end });
  }

  const gaps: JSInterval[] = [];
  for (let i = 0; i < requestsWithinRange.length - 1; i++) {
    if (!(requestsWithinRange[i].end === requestsWithinRange[i + 1].start)) {
      gaps.push({ start: requestsWithinRange[i].end, end: requestsWithinRange[i + 1].start });
    }
  }
  return gaps;
};

export const mergeValues = (existingValues: Value[], newValues: Value[]): Value[] => {
  let iExisting = 0;
  let iNew = 0;
  const result: Value[] = [];
  while (iExisting < existingValues.length || iNew < newValues.length) {
    if (iExisting === existingValues.length) {
      result.push(newValues[iNew]);
      iNew += 1;
      continue;
    }
    if (iNew === newValues.length) {
      result.push(existingValues[iExisting]);
      iExisting += 1;
      continue;
    }
    // Replace old with new
    if (existingValues[iExisting].startMs === newValues[iNew].startMs) {
      result.push(newValues[iNew]);
      iNew += 1;
      iExisting += 1;
      continue;
    }
    // Add the one with lower timestamp
    if (existingValues[iExisting].startMs < newValues[iNew].startMs) {
      result.push(existingValues[iExisting]);
      iExisting += 1;
      continue;
    }
    if (newValues[iNew].startMs < existingValues[iExisting].startMs) {
      result.push(newValues[iNew]);
      iNew += 1;
      continue;
    }
  }
  return result;
};

// Used to find values that need to be possibly reloaded
// Assume that values are sorted
export const getLatestNonZeroValue = (values: Value[]): Value | null => {
  for (let i = values.length - 1; i >= 0; i--) {
    if (values[i].value !== null && values[i].value !== 0) {
      return values[i];
    }
    if (values[i].samples !== null && values[i].samples !== 0) {
      return values[i];
    }
    if (values[i].rawValue !== null && values[i].rawValue !== 0) {
      return values[i];
    }
  }
  return null;
};

export const getFreshRequests = (sensorState: SensorValueType, l: Level) => {
  // Zero values may indicate a value that hasn't been actually received by the backend yet
  const latestNonZeroValue = getLatestNonZeroValue(sensorState.values[l]);
  if (!latestNonZeroValue) {
    return [];
  }

  // Only keep requests from values that should not update anymore
  return sensorState.requests[l].filter((r) => r.end < latestNonZeroValue.startMs).sort((a, b) => a.start - b.start);
};

import { DateTime, DateTimeUnit, Duration, Interval } from "luxon";
import { AvgValue, KeyType, KeyType2, Level, LMinValue, PlainValue, RawValue, Value, ValueEnum } from "./types";
import { LOCALE } from "../constants";
const humanizeDuration = require("humanize-duration");

export interface JSInterval {
  start: number;
  end: number;
}

export const getPlainValue = (value: Value): PlainValue => {
  const start = DateTime.fromMillis(value.startMs);
  const end = DateTime.fromMillis(value.endMs);
  return {
    _type: ValueEnum.PLAIN,
    start: start,
    end: end,
    timestamp: DateTime.fromMillis(value.timestampMs),
    startMs: value.startMs,
    endMs: value.endMs,
    timestampMs: value.timestampMs,
    interval: Interval.fromDateTimes(start, end),
    value: value.value!,
  };
};

export const getPlainValueForMinimumView = (value: Value): PlainValue => {
  const start = DateTime.fromMillis(value.startMs);
  const end = DateTime.fromMillis(value.endMs);
  return {
    _type: ValueEnum.PLAIN,
    start: start,
    end: end,
    timestamp: DateTime.fromMillis(value.timestampMs),
    startMs: value.startMs,
    endMs: value.endMs,
    timestampMs: value.timestampMs,
    interval: Interval.fromDateTimes(start, end),
    value: value.min!,
  };
};

export const getAvgValue = (value: Value): AvgValue => {
  const start = DateTime.fromMillis(value.startMs);
  const end = DateTime.fromMillis(value.endMs);
  return {
    _type: ValueEnum.AVG,
    start: start,
    end: end,
    timestamp: DateTime.fromMillis(value.timestampMs),
    startMs: value.startMs,
    endMs: value.endMs,
    timestampMs: value.timestampMs,
    interval: Interval.fromDateTimes(start, end),
    avg: value.avg!,
    max: value.max!,
    min: value.min!,
    samples: value.samples!,
  };
};

export const getLMinValue = (value: Value): LMinValue => {
  const start = DateTime.fromMillis(value.startMs);
  const end = DateTime.fromMillis(value.endMs);
  return {
    _type: ValueEnum.LMIN,
    start: start,
    end: end,
    timestamp: DateTime.fromMillis(value.timestampMs),
    startMs: value.startMs,
    endMs: value.endMs,
    timestampMs: value.timestampMs,
    interval: Interval.fromDateTimes(start, end),
    value: value.value!,
    rawValue: value.rawValue!,
  };
};

export const getRawValue = (value: Value): RawValue => {
  const start = DateTime.fromMillis(value.startMs);
  const end = DateTime.fromMillis(value.endMs);
  return {
    _type: ValueEnum.RAW,
    start: start,
    end: end,
    timestamp: DateTime.fromMillis(value.timestampMs),
    startMs: value.startMs,
    endMs: value.endMs,
    timestampMs: value.timestampMs,
    interval: Interval.fromDateTimes(start, end),
    value: value.value!,
  };
};

export const sensorLevel2ValueEnum = (keyType2: KeyType2, level: Level): ValueEnum => {
  if (keyType2 === KeyType2.Accumulating) {
    if (level === Level.raw) {
      return ValueEnum.LMIN;
    } else {
      return ValueEnum.PLAIN;
    }
  } else if (keyType2 === KeyType2.TimeSeries) {
    if (level === Level.raw) {
      return ValueEnum.RAW;
    } else {
      return ValueEnum.AVG;
    }
  }
  console.log("Undefined ValueEnum, defaulting to PLAIN");
  return ValueEnum.PLAIN;
};

export const formatNumber = (value: number, decimals: number = 0) => {
  return Intl.NumberFormat(LOCALE, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);
};

export const humanizeValue = (
  value: number,
  reference: number,
  type: KeyType,
  short: boolean = true,
  plain: boolean = false
): [string, string] => {
  if (type === KeyType.Water && short) {
    if (Math.abs(reference) > 9999) {
      return [formatNumber(value / 1000).toString(), "m³"];
    }
    if (Math.abs(reference) > 999) {
      return [formatNumber(value / 1000, 1), "m³"];
    }
    if (Math.abs(reference) > 9) {
      return [formatNumber(value), "L"];
    }
    return [formatNumber(value, 1), "L"];
  } else if (type === KeyType.Water && !short) {
    return [formatNumber(value), "L"];
  } else if (type === KeyType.Voltage) {
    return [formatNumber(value, 1), "V"];
  } else if (type === KeyType.Temperature) {
    return [formatNumber(value, 1), "C"];
  } else if (type === KeyType.WaterLevel) {
    return [formatNumber(value, 1), "m"];
  } else if (type === KeyType.Pressure) {
    if (short) {
      return [formatNumber(value, 1), "bar"];
    } else {
      return [formatNumber(value, 2), "bar"];
    }
  } else if (type === KeyType.Energy && short) {
    if (Math.abs(reference) > 9999999) {
      return [formatNumber(value / 1000000), "MWh"];
    }
    if (Math.abs(reference) > 999999) {
      return [formatNumber(value / 1000000, 1), "MWh"];
    }
    if (Math.abs(reference) > 9999) {
      return [formatNumber(value / 1000), "kWh"];
    }
    if (Math.abs(reference) > 999) {
      return [formatNumber(value / 1000, 1), "kWh"];
    }
    if (Math.abs(reference) > 9) {
      return [formatNumber(value), "Wh"];
    }
    return [formatNumber(value, 1), "Wh"];
  } else if (type === KeyType.Energy && !short) {
    return [formatNumber(value, 1), "Wh"];
  } else if (type === KeyType.Accumulator) {
    const unitStr = short ? "krt" : "kertaa";
    if (Math.abs(reference) > 9) {
      return [formatNumber(value), unitStr];
    } else {
      return [formatNumber(value, 1), unitStr];
    }
  } else if (type === KeyType.TimeSeconds) {
    if (short) {
      if (value >= 60 * 60) {
        return [formatNumber(value / 60 / 60, 0), "t"];
      }
      if (value >= 60) {
        return [formatNumber(value / 60, 0), "m"];
      }
      return [formatNumber(value, 0), "s"];
    }
    if (plain) {
      return [formatNumber(value, 0), "s"];
    }
    const time = humanizeDuration(value * 1000, {
      language: "fi",
      largest: 2,
      units: ["h", "m", "s"],
      round: true,
    });
    return value >= 0 ? [time, ""] : [`-${time}`, ""];
  }

  // Should not come here
  return [value.toString(), "?"];
};

export const humanizeValueStr = (
  value: number,
  reference: number,
  type: KeyType,
  short: boolean = true,
  plain: boolean = false
): string => humanizeValue(value, reference, type, short, plain).join(" ");

export const levelToLuxonUnit = (level: Level): DateTimeUnit => {
  switch (level) {
    case Level.raw:
      return "minute";
    case Level.minute:
      return "minute";
    case Level.hour:
      return "hour";
    case Level.day:
      return "day";
    case Level.month:
      return "month";
    case Level.year:
      return "year";
    default:
      console.log(`Converting to default unit ${level}`);
      return "minute";
  }
};

export const levelUnitToMilliseconds = (level: Level): number => {
  switch (level) {
    case Level.raw:
      return Duration.fromDurationLike({ minute: 1 }).toMillis();
    case Level.minute:
      return Duration.fromDurationLike({ minute: 1 }).toMillis();
    case Level.hour:
      return Duration.fromDurationLike({ hour: 1 }).toMillis();
    case Level.day:
      return Duration.fromDurationLike({ day: 1 }).toMillis();
    case Level.month:
      return Duration.fromDurationLike({ month: 1 }).toMillis();
    case Level.year:
      return Duration.fromDurationLike({ year: 1 }).toMillis();
    default:
      console.log(`Converting to default unit  ${level}`);
      return Duration.fromDurationLike({ minute: 1 }).toMillis();
  }
};

export const humanizeKeyType = (keyType: KeyType | null) => {
  if (keyType === KeyType.Voltage) {
    return "Akkujännite";
  }
  if (keyType === KeyType.Temperature) {
    return "Lämpötila";
  }
  if (keyType === KeyType.Water) {
    return "Vesimittari";
  }
  if (keyType === KeyType.Energy) {
    return "Energia";
  }
  if (keyType === KeyType.WaterLevel) {
    return "Pinnankorkeus";
  }
  if (keyType === KeyType.Pressure) {
    return "Paine";
  }
  if (keyType === KeyType.Accumulator) {
    return "Laskuri";
  }
  if (keyType === KeyType.TimeSeconds) {
    return "Käyntiaika";
  }
  return "";
};

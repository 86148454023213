import { useGetSessionsQuery } from "../../../../../store/api";
import { Sensor, Session } from "../../../../../store/types";
import { useMemo, useState } from "react";
import { ColDef } from "ag-grid-community";
import { Table } from "../../../../../components/Table/Table";
import { Button } from "antd";
import { EditOutlined, WarningOutlined } from "@ant-design/icons";
import { EditModal } from "./EditModal";
import { DeleteModal } from "./DeleteModal";
import { dateTimeRenderer } from "../../../../../components/Table/cellRenderer";

export const SessionTable = ({ sensor }: { sensor: Sensor }) => {
  const sessions = useGetSessionsQuery({ deviceId: sensor.deviceId, key: sensor.key });
  const [selected, setSelected] = useState<Session[]>([]);
  const [modal, setModal] = useState<"edit" | "delete" | undefined>();

  const columns = useMemo<ColDef[]>(
    () => [
      {
        field: "sessionId",
        headerName: "ID",
      },
      {
        field: "startTime",
        headerName: "Alkuhetki",
        cellRenderer: dateTimeRenderer,
        width: 200,
        sort: "asc",
      },
      {
        field: "startValue",
        headerName: "Alkuarvo",
      },
      {
        field: "endTime",
        headerName: "Loppuhetki",
        cellRenderer: dateTimeRenderer,
        width: 200,
      },
      {
        field: "endValue",
        headerName: "Loppuarvo",
      },
      {
        field: "count",
        headerName: "Lukumäärä",
      },
    ],
    []
  );

  return (
    <>
      <Table
        rowData={sessions.data}
        multiSelect
        loading={sessions.isFetching}
        toolbar={
          <>
            <Button
              disabled={!selected.length}
              type={"primary"}
              icon={<EditOutlined />}
              onClick={() => setModal("edit")}
            >
              Muokkaa
            </Button>
            <Button
              disabled={!selected.length}
              type={"primary"}
              danger
              icon={<WarningOutlined />}
              onClick={() => setModal("delete")}
            >
              Poista
            </Button>
          </>
        }
        onSelect={setSelected}
        columns={columns}
      />
      {modal === "edit" && <EditModal sensor={sensor} sessions={selected} onClose={() => setModal(undefined)} />}
      {modal === "delete" && <DeleteModal sensor={sensor} sessions={selected} onClose={() => setModal(undefined)} />}
    </>
  );
};

import { Highlight } from "../types";
import { GraphState } from "../reducer";
import { ValueEnum, ValueTypes } from "../../../store/types";
import { GRAPH_FONT, GRAPH_FONT_HEIGHT } from "./constants";
import { AXIS_PADDING, HIGHLIGHT_PADDING } from "./axis";
import { getTimeText, getValueText } from "./renderHighlightUtils";
import { DateTime } from "luxon";
import { getX } from "../utils";

export const renderHighlightText = (
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  graphState: GraphState,
  highlight: Highlight,
  timestamp: DateTime,
  value?: ValueTypes
) => {
  if (!value) {
    return;
  }

  ctx.font = GRAPH_FONT;

  const { keyType, keyType2 } = graphState.series[0].sensor;
  if (!keyType || !keyType2) {
    return;
  }

  if (graphState.mode === "tiny") {
    return;
  }

  if (value._type === ValueEnum.AVG && !value.samples) {
    return;
  }

  ctx.setLineDash([]);

  ctx.beginPath();
  ctx.textAlign = "center";
  ctx.textBaseline = "top";
  ctx.fillStyle = "black";
  ctx.globalAlpha = 1;

  var x = getX(graphState, timestamp.toMillis());
  const valueText = getValueText(value, keyType, graphState.rawLevelHighlightType);
  const valueTextSize = ctx.measureText(valueText);
  if (x - valueTextSize.width / 2 < graphState.padding.left) {
    ctx.textAlign = "left";
    x = graphState.padding.left;
  }
  if (x + valueTextSize.width / 2 > graphState.width - graphState.padding.right) {
    ctx.textAlign = "right";
    x = graphState.width - graphState.padding.right;
  }
  ctx.fillText(valueText, x, 0);

  ctx.beginPath();
  ctx.textAlign = "center";
  ctx.textBaseline = "top";
  ctx.fillStyle = "white";
  ctx.strokeStyle = "gray";
  ctx.globalAlpha = 1;

  const timeText = getTimeText(graphState.level, highlight);
  const timeTextSize = ctx.measureText(timeText);
  const timeTextBoxWidth = timeTextSize.width + HIGHLIGHT_PADDING * 2;

  if (x - timeTextSize.width / 2 < graphState.padding.left) {
    ctx.textAlign = "left";
    x = graphState.padding.left;
    ctx.fillRect(
      graphState.padding.left - HIGHLIGHT_PADDING,
      graphState.height - graphState.padding.bottom + AXIS_PADDING,
      timeTextBoxWidth,
      GRAPH_FONT_HEIGHT * 2 - AXIS_PADDING
    );
    ctx.strokeRect(
      graphState.padding.left,
      graphState.height - graphState.padding.bottom + GRAPH_FONT_HEIGHT * 2,
      timeTextSize.width,
      0
    );
  } else if (x + timeTextSize.width / 2 > graphState.width - graphState.padding.right) {
    ctx.textAlign = "right";
    x = graphState.width - graphState.padding.right;
    ctx.fillRect(
      graphState.width - graphState.padding.right + HIGHLIGHT_PADDING - timeTextBoxWidth,
      graphState.height - graphState.padding.bottom + AXIS_PADDING,
      timeTextBoxWidth,
      GRAPH_FONT_HEIGHT * 2 - AXIS_PADDING
    );
    ctx.strokeRect(
      graphState.width - graphState.padding.right - timeTextSize.width,
      graphState.height - graphState.padding.bottom + GRAPH_FONT_HEIGHT * 2,
      timeTextSize.width,
      0
    );
  } else {
    ctx.fillRect(
      x - timeTextBoxWidth / 2,
      graphState.height - graphState.padding.bottom + AXIS_PADDING,
      timeTextBoxWidth,
      GRAPH_FONT_HEIGHT * 2 - AXIS_PADDING
    );
    ctx.strokeRect(
      x - timeTextSize.width / 2,
      graphState.height - graphState.padding.bottom + GRAPH_FONT_HEIGHT * 2,
      timeTextSize.width,
      0
    );
  }
  ctx.fillStyle = "black";
  ctx.fillText(timeText, x, graphState.height - graphState.padding.bottom + AXIS_PADDING);
};

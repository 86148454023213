import { useNavigate, useParams } from "react-router-dom";
import { useDeleteUserMutation } from "../../../../../store/api";
import { ContentCard } from "../../../../../utils/styled";
import { Button, message } from "antd";

export const DeleteUser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [deleteUser] = useDeleteUserMutation();

  const handleClick = async () => {
    const userIdNumber = Number(userId);
    if (!userIdNumber) {
      return;
    }
    try {
      await deleteUser(userIdNumber);
      navigate("../");
      message.success("Käyttäjä on poistettu");
    } catch (err) {}
  };

  return (
    <ContentCard title={"Poista käyttäjä"}>
      <p>Käyttäjä poistetaan pysyvästi järjestelmästä.</p>
      <Button type={"primary"} danger onClick={handleClick}>
        Poista käyttäjä
      </Button>
    </ContentCard>
  );
};

import {
  useCompleteEmailVerificationMutation,
  useEditCurrentUserMutation,
  useGetCurrentUserQuery,
  useStartEmailVerificationMutation,
} from "../../../store/api";
import { ContentCard } from "../../../utils/styled";
import { Button, Form, Input, message } from "antd";
import { useCallback, useState } from "react";
import { VerificationCodeModal } from "../../../components/users/VerificationCodeModal/VerificationCodeModal";

export const UserVerificationEmail = () => {
  const currentUser = useGetCurrentUserQuery();
  const [editCurrentUser] = useEditCurrentUserMutation();
  const [startVerification] = useStartEmailVerificationMutation();
  const [completeVerification] = useCompleteEmailVerificationMutation();
  const [valueToVerify, setValueToVerify] = useState<string>();

  const handleStart = useCallback(
    async (form: { email: string }) => {
      try {
        setValueToVerify(form.email);
        await startVerification({ email: form.email }).unwrap();
      } catch (err: any) {
        setValueToVerify(undefined);
        if (err.data) {
          message.error(err.data);
        }
      }
    },
    [startVerification]
  );

  const handleComplete = useCallback(
    async (code: string) => {
      try {
        await completeVerification({ code }).unwrap();
        setValueToVerify(undefined);
        message.success("Sähköposti on tallennettu", 5);
      } catch (err) {}
    },
    [completeVerification]
  );

  const handleSubmit = useCallback(
    async (form: { email: string }) => {
      if (!form.email) {
        await editCurrentUser({ email: null });
      } else {
        await handleStart(form);
      }
    },
    [editCurrentUser, handleStart]
  );

  if (currentUser.isFetching) return null;

  return (
    <>
      <ContentCard title={"Sähköposti"}>
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} onFinish={handleSubmit} initialValues={currentUser.data}>
          <Form.Item
            label={"Sähköpostiosoite"}
            name={"email"}
            validateTrigger={"onSubmit"}
            rules={[{ required: true, message: "Anna sähköpostiosoite oikeassa muodossa", type: "email" }]}
          >
            <Input value={currentUser.data?.email || ""} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5, span: 10 }}>
            <Button type="primary" htmlType="submit">
              Tallenna
            </Button>
          </Form.Item>
        </Form>
      </ContentCard>
      {!!valueToVerify && (
        <VerificationCodeModal
          title={"Varmenna uusi sähköpostiosoite"}
          description={`Tarkista sähköposti osoitteesta ${valueToVerify} ja kirjoita varmennuskoodi tähän. Koodi on voimassa 10 minuuttia.`}
          onCancel={() => setValueToVerify(undefined)}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};

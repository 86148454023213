import { useLogoutMutation } from "../../store/api";
import { useEffect } from "react";

export const Logout = () => {
  const [logout] = useLogoutMutation();

  useEffect(() => {
    const doLogout = async () => {
      await logout();
      window.location.pathname = "/login";
    };
    doLogout();
  }, [logout]);
  return null;
};

import { GraphType } from "../types";
import { GraphState } from "../reducer";
import { renderBars } from "./renderBars";
import { renderArea } from "./renderArea";
import { renderYAxis } from "./renderYAxis";
import { renderXAxis } from "./renderXAxis";
import { GRAPH_FONT } from "./constants";
import { renderLine } from "./renderLine";
import { KeyType2, Level } from "../../../store/types";
import { renderBorders } from "./renderBorders";
import { renderZeroAxis } from "./renderZeroAxis";

export const render = (
  canvas: HTMLCanvasElement | OffscreenCanvas | null,
  graphState: GraphState,
  pixelRatio: number,
  background?: boolean
) => {
  if (!canvas) {
    return;
  }
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D | null;
  if (!ctx) {
    return;
  }

  // @ts-ignore reset canvas
  // eslint-disable-next-line no-self-assign
  canvas.width = canvas.width;
  ctx.scale(pixelRatio, pixelRatio);
  ctx.font = GRAPH_FONT;

  if (background) {
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, graphState.width, graphState.height);
    ctx.beginPath();
  }

  if (graphState.mode === "normal") {
    renderYAxis(ctx, graphState, "left");
    renderBorders(ctx, graphState);

    ctx.save();
    ctx.rect(
      graphState.padding.left,
      0,
      graphState.width - graphState.padding.left - graphState.padding.right,
      graphState.height
    );
    ctx.clip();
    renderXAxis(ctx, graphState);

    ctx.restore();
  } else {
    renderZeroAxis(ctx, graphState);
  }

  ctx.save();
  ctx.rect(
    graphState.padding.left,
    graphState.padding.top,
    graphState.width - graphState.padding.left - graphState.padding.right,
    graphState.height - graphState.padding.top - graphState.padding.bottom
  );
  ctx.clip();

  graphState.series.forEach((series) => {
    ctx.beginPath();
    if (graphState.level === Level.raw) {
      if (series.sensor.keyType2 === KeyType2.Accumulating) {
        renderLine(ctx, graphState, series);
      } else {
        renderLine(ctx, graphState, series);
      }
    } else {
      if (series.type === GraphType.Bar) {
        renderBars(ctx, graphState, series);
      } else if (series.type === GraphType.Area) {
        renderArea(ctx, graphState, series);
      }
    }
  });

  ctx.restore();
};

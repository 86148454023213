import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useGetSettingsQuery } from "../../../../../store/api";
import { SettingEditorCard } from "./SettingEditorCard";
import { useState } from "react";
import { SettingEditorAddSetting } from "./SettingEditorAddSetting";

export const SettingEditor = () => {
  const { deviceId } = useParams();
  const [addModal, setAddModal] = useState(false);

  const settings = useGetSettingsQuery({ deviceId: deviceId! });

  if (!deviceId || settings.isFetching) {
    return null;
  }

  return (
    <>
      <Layout>
        <Toolbar>
          <Button icon={<PlusOutlined />} type={"primary"} onClick={() => setAddModal(true)}>
            Lisää asetus
          </Button>
        </Toolbar>
        {settings.data?.map((s) => (
          <SettingEditorCard key={s.name} setting={s} />
        ))}
      </Layout>
      {addModal && <SettingEditorAddSetting deviceId={deviceId} onClose={() => setAddModal(false)} />}
    </>
  );
};

const Toolbar = styled.div``;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

import { useAddDeviceMutation } from "../../../store/api";
import { InputNumber, Modal } from "antd";
import { useState } from "react";

export const DeviceCreateModal = ({ onCancel }: { onCancel: () => void }) => {
  const [addDevice] = useAddDeviceMutation();
  const [amount, setAmount] = useState<number>(1);

  const okHandler = async () => {
    await addDevice(amount);
    onCancel();
  };

  return (
    <Modal open={true} title={"Generoi uusia laitteita"} onCancel={onCancel} onOk={okHandler}>
      <p>Lukumäärä (max 500)</p>
      <InputNumber min={1} max={500} defaultValue={amount} onChange={(value) => setAmount(value || 1)} />
    </Modal>
  );
};

import { GraphState } from "../reducer";
import { AXIS_LINE_COLOR } from "./constants";

export const renderBorders = (
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  graphState: GraphState
): void => {
  ctx.beginPath();

  ctx.strokeStyle = AXIS_LINE_COLOR;
  ctx.textAlign = "right";
  ctx.textBaseline = "middle";
  ctx.fillStyle = "black";

  ctx.moveTo(graphState.padding.left, graphState.padding.top);
  ctx.lineTo(graphState.width - graphState.padding.right, graphState.padding.top);
  ctx.lineTo(graphState.width - graphState.padding.right, graphState.height - graphState.padding.bottom);
  ctx.lineTo(graphState.padding.left, graphState.height - graphState.padding.bottom);
  ctx.lineTo(graphState.padding.left, graphState.padding.top);

  ctx.stroke();
};

import { Sensor, Level, Value } from "../types";
import { getReduxId, RequestType, ValueState } from "./types";
import { getFreshRequests, mergeValues } from "./utils";
import { AnyAction } from "redux";
import { JSInterval } from "../utils";

export const initialState: ValueState = {
  sensors: {},
};

type FetchIntervalBeginType = {
  type: "fetchIntervalBegin";
  payload: {
    sensor: Sensor;
    level: Level;
    start: number;
    end: number;
  };
};

export const fetchIntervalBegin = (sensor: Sensor, level: Level, interval: JSInterval): FetchIntervalBeginType => ({
  type: "fetchIntervalBegin",
  payload: {
    sensor,
    level,
    start: interval.start,
    end: interval.end,
  },
});

type FetchIntervalSuccessType = {
  type: "fetchIntervalSuccess";
  payload: {
    sensor: Sensor;
    level: Level;
    start: number;
    end: number;
    values: Value[];
  };
};

export const fetchIntervalSuccess = (
  sensor: Sensor,
  level: Level,
  interval: JSInterval,
  values: Value[]
): FetchIntervalSuccessType => ({
  type: "fetchIntervalSuccess",
  payload: {
    sensor,
    level,
    start: interval.start,
    end: interval.end,
    values,
  },
});

type FetchIntervalErrorType = {
  type: "fetchIntervalError";
  payload: {
    sensor: Sensor;
    level: Level;
    start: number;
    end: number;
  };
};
export const fetchIntervalError = (sensor: Sensor, level: Level, interval: JSInterval): FetchIntervalErrorType => ({
  type: "fetchIntervalError",
  payload: {
    sensor,
    level,
    start: interval.start,
    end: interval.end,
  },
});

type Refresh = {
  type: "refresh";
  payload: {
    sensor: Sensor;
  };
};

type RefreshLevel = {
  type: "refreshLevel";
  payload: {
    sensor: Sensor;
    level: Level;
  };
};

/*
  Refresh values indirectly. By removing the underlying requests the system should trigger to download values again.
  Values are not removed, so the user should not notice anything other than new values and updates to existing values
 */
export const refresh = (sensor: Sensor): Refresh => ({
  type: "refresh",
  payload: {
    sensor,
  },
});

export const refreshLevel = (sensor: Sensor, level: Level): RefreshLevel => ({
  type: "refreshLevel",
  payload: {
    sensor,
    level,
  },
});

type ValuePayloadTypes =
  | FetchIntervalBeginType
  | FetchIntervalSuccessType
  | FetchIntervalErrorType
  | Refresh
  | RefreshLevel;

/*
  Written as plain old reducer without Immer to optimize performance
 */
export const valueReducer = (state = initialState, action: AnyAction): ValueState => {
  const { type, payload } = action as ValuePayloadTypes;
  switch (type) {
    case "fetchIntervalBegin": {
      const { sensor, level, start, end } = payload;

      const newOrExistingSensor = state.sensors[getReduxId(sensor)] || {
        deviceId: sensor.deviceId,
        key: sensor.key,
        values: {
          year: [],
          month: [],
          day: [],
          hour: [],
          minute: [],
          raw: [],
        },
        requests: {
          year: [],
          month: [],
          day: [],
          hour: [],
          minute: [],
          raw: [],
        },
      };

      return {
        ...state,
        sensors: {
          ...state.sensors,
          [getReduxId(sensor)]: {
            ...newOrExistingSensor,
            requests: {
              ...newOrExistingSensor.requests,
              [level]: [
                ...newOrExistingSensor.requests[level],
                {
                  start,
                  startISO: new Date(start).toISOString(),
                  end,
                  endISO: new Date(end).toISOString(),
                  loading: true,
                  error: false,
                } as RequestType,
              ].sort((a, b) => a.start - b.start),
            },
          },
        },
      };
    }
    case "fetchIntervalSuccess": {
      const { sensor, level, start, values } = payload;
      const sensorState = state.sensors[getReduxId(sensor)];
      if (!sensorState) {
        console.error("sensor not found, abort", sensor);
        return state;
      }

      const newSortedValues = [...values].sort((a, b) => a.startMs - b.startMs);
      const levelRequests = sensorState.requests[level].map((r) => {
        if (r.start === start) {
          return { ...r, loading: false };
        }
        return r;
      });

      return {
        ...state,
        sensors: {
          ...state.sensors,
          [getReduxId(sensor)]: {
            ...sensorState,
            requests: { ...sensorState.requests, [level]: levelRequests },
            values: {
              ...sensorState.values,
              [level]: mergeValues(sensorState.values[level], newSortedValues),
            },
          },
        },
      };
    }
    case "fetchIntervalError": {
      const { sensor, level, start } = payload;
      const sensorState = state.sensors[getReduxId(sensor)];
      if (!sensorState) {
        console.error("sensor not found, abort", sensor);
        return state;
      }

      const levelRequests = sensorState.requests[level].map((r) => {
        if (r.start === start) {
          return { ...r, loading: false, error: true };
        }
        return r;
      });

      return {
        ...state,
        sensors: {
          ...state.sensors,
          [getReduxId(sensor)]: {
            ...sensorState,
            requests: { ...sensorState.requests, [level]: levelRequests },
          },
        },
      };
    }
    case "refresh": {
      const { sensor } = payload;
      const sensorState = state.sensors[getReduxId(sensor)];
      if (!sensorState) {
        return state;
      }

      return {
        ...state,
        sensors: {
          ...state.sensors,
          [getReduxId(sensor)]: {
            ...sensorState,
            requests: {
              raw: getFreshRequests(sensorState, Level.raw),
              minute: getFreshRequests(sensorState, Level.minute),
              hour: getFreshRequests(sensorState, Level.hour),
              day: getFreshRequests(sensorState, Level.day),
              month: getFreshRequests(sensorState, Level.month),
              year: getFreshRequests(sensorState, Level.year),
            },
          },
        },
      };
    }
    case "refreshLevel": {
      const { sensor, level } = payload;
      const sensorState = state.sensors[getReduxId(sensor)];
      if (!sensorState) {
        return state;
      }

      return {
        ...state,
        sensors: {
          ...state.sensors,
          [getReduxId(sensor)]: {
            ...sensorState,
            requests: {
              ...sensorState.requests,
              [level]: getFreshRequests(sensorState, level),
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

import { Provider } from "jotai";
import { Interval } from "luxon";
import { Level } from "../../../store/types";
import { Position } from "../types";
import { staticPositionAtom } from "./atoms";
import { useHydrateAtoms } from "jotai/utils";

const InitialValues = ({
  level,
  interval,
  children,
}: {
  level: Level;
  interval: Interval;
  children: React.ReactNode;
}) => {
  const position: Position = { interval, level };
  useHydrateAtoms([[staticPositionAtom, position]]);
  return <>{children}</>;
};

export const GraphStateProvider = ({
  level,
  interval,
  children,
}: {
  level: Level;
  interval: Interval;
  children: React.ReactNode;
}) => {
  return (
    <Provider>
      <InitialValues level={level} interval={interval}>
        {children}
      </InitialValues>
    </Provider>
  );
};

import { DateTime, Duration, Interval } from "luxon";
import { useCallback, useMemo } from "react";
import { Level } from "../../store/types";
import { levelToLuxonUnit } from "../../store/utils";

interface LevelConfiguration {
  level: Level;
  duration: Duration;
  lead: Duration;
  mobile: boolean;
  desktop: boolean;
}

const LEVELS: LevelConfiguration[] = [
  {
    level: Level.raw,
    duration: Duration.fromDurationLike({ minutes: 5 }), // 30-300 bars
    lead: Duration.fromDurationLike({ seconds: 30 }),
    mobile: true,
    desktop: true,
  },
  {
    level: Level.minute,
    duration: Duration.fromDurationLike({ hours: 1 }), // 60 bars
    lead: Duration.fromDurationLike({ minutes: 1 }),
    mobile: true,
    desktop: true,
  },
  {
    level: Level.minute,
    duration: Duration.fromDurationLike({ hours: 3 }), // 180 bars
    lead: Duration.fromDurationLike({ minutes: 1 }),
    mobile: false,
    desktop: true,
  },
  {
    level: Level.hour,
    duration: Duration.fromDurationLike({ days: 1 }), // 24 bars
    lead: Duration.fromDurationLike({ hour: 1 }),
    mobile: true,
    desktop: true,
  },
  {
    level: Level.hour,
    duration: Duration.fromDurationLike({ days: 3 }), // 72 bars
    lead: Duration.fromDurationLike({ hour: 1 }),
    mobile: true,
    desktop: false,
  },
  {
    level: Level.hour,
    duration: Duration.fromDurationLike({ days: 7 }), // 148 bars
    lead: Duration.fromDurationLike({ hour: 1 }),
    mobile: false,
    desktop: true,
  },
  {
    level: Level.day,
    duration: Duration.fromDurationLike({ months: 1, days: 1 }), // 31 bars
    lead: Duration.fromDurationLike({ day: 1 }),
    mobile: true,
    desktop: true,
  },
  {
    level: Level.day,
    duration: Duration.fromDurationLike({ months: 3 }), // 90 bars
    lead: Duration.fromDurationLike({ day: 1 }),
    mobile: true,
    desktop: false,
  },
  {
    level: Level.day,
    duration: Duration.fromDurationLike({ months: 6 }), // 180 bars
    lead: Duration.fromDurationLike({ day: 1 }),
    mobile: false,
    desktop: true,
  },
  {
    level: Level.month,
    duration: Duration.fromDurationLike({ years: 1 }), // 12 bars
    lead: Duration.fromDurationLike({ month: 1 }),
    mobile: true,
    desktop: true,
  },
  {
    level: Level.month,
    duration: Duration.fromDurationLike({ years: 5 }), // 60 bars
    lead: Duration.fromDurationLike({ month: 1 }),
    mobile: true,
    desktop: false,
  },
  {
    level: Level.month,
    duration: Duration.fromDurationLike({ years: 10 }), // 120 bars
    lead: Duration.fromDurationLike({ month: 1 }),
    mobile: false,
    desktop: true,
  },
  {
    level: Level.year,
    duration: Duration.fromDurationLike({ years: 20 }), // 20 bars
    lead: Duration.fromDurationLike({ year: 1 }),
    mobile: false,
    desktop: true,
  },
  {
    level: Level.year,
    duration: Duration.fromDurationLike({ years: 10 }), // 10 bars
    lead: Duration.fromDurationLike({ year: 1 }),
    mobile: true,
    desktop: false,
  },
];

export const useLevels = (lockToNow: boolean, setLockToNow: (value: boolean) => void) => {
  const availableLevels = useMemo(() => {
    if (window.innerWidth < 900) {
      return LEVELS.filter((l) => l.mobile);
    }
    return LEVELS.filter((l) => l.desktop);
  }, []);

  const alignToNow = useCallback((interval: Interval, level: Level): Interval => {
    const unit = level === Level.raw ? "second" : levelToLuxonUnit(level);
    const end = DateTime.now()
      .plus({ [unit]: 1 })
      .startOf(unit);
    return Interval.fromDateTimes(end.minus(interval.toDuration()), end);
  }, []);

  const calculatePosition = useCallback(
    (level: LevelConfiguration, interval: Interval, keepLockToNow: boolean) => {
      const center = interval.start.plus(interval.end.diff(interval.start).milliseconds / 2);
      const half = level.duration.toMillis() / 2;
      const start = center.minus(half);
      const end = center.plus(half);

      if (!lockToNow) {
        return { level: level.level, interval: Interval.fromDateTimes(start, end) };
      }
      if (keepLockToNow) {
        return { level: level.level, interval: alignToNow(Interval.fromDateTimes(start, end), level.level) };
      }
      if (end < DateTime.now().minus({ second: 10 })) {
        setLockToNow?.(false);
        return { level: level.level, interval: Interval.fromDateTimes(start, end) };
      }
      return { level: level.level, interval: alignToNow(Interval.fromDateTimes(start, end), level.level) };
    },
    [alignToNow, lockToNow, setLockToNow]
  );

  const zoomIn = useCallback(
    (
      interval: Interval,
      highlight: Interval,
      keepLockToNow: boolean
    ): { level: Level; interval: Interval } | undefined => {
      const next = availableLevels
        .slice()
        .reverse()
        .find((l) => l.duration < interval.toDuration().minus(interval.toDuration().milliseconds / 4));
      if (!next) {
        return;
      }

      return calculatePosition(next, highlight, keepLockToNow);
    },
    [availableLevels, calculatePosition]
  );

  const zoomOut = useCallback(
    (interval: Interval): { level: Level; interval: Interval } | undefined => {
      const next = availableLevels.find((l) => l.duration > interval.toDuration());
      if (!next) {
        return;
      }

      return calculatePosition(next, interval, false);
    },
    [availableLevels, calculatePosition]
  );

  const findClosestIdx = useCallback(
    (duration: Duration): number => {
      const diff = availableLevels.map((l, i) => ({
        diff: Math.abs(l.duration.toMillis() - duration.toMillis()),
        i,
      }));
      diff.sort((a, b) => a.diff - b.diff);
      return diff[0].i;
    },
    [availableLevels]
  );

  return {
    zoomIn,
    zoomOut,
    alignToNow,
    findClosestIdx,
    availableLevels,
  };
};

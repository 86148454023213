import { Table } from "../../../../../components/Table/Table";
import { useParams } from "react-router-dom";
import { useGetPermissionsQuery, useGetUsersQuery, useSetUsersForDeviceMutation } from "../../../../../store/api";
import { User } from "../../../../../store/types";
import { useCallback, useMemo } from "react";
import { dateRenderer } from "../../../../../components/Table/cellRenderer";
import { WIDE_COLUMN_WIDTH } from "../../../../../components/constants";
import { ColDef } from "ag-grid-community";
import { getAdminUserProfileRoute } from "../../../../../utils/routes";
import { LinkButton } from "../../../../../components/LinkButton";

export const AdminDeviceUsersList = () => {
  const permissions = useGetPermissionsQuery();
  const users = useGetUsersQuery();
  const [setUsersForDevice] = useSetUsersForDeviceMutation();
  const { deviceId } = useParams();

  const isSelected = useCallback(
    (user: User) =>
      !!permissions.data?.userDevicePermissions.find(
        (perm) => perm.deviceId === deviceId && perm.userId === user.userId
      ),
    [deviceId, permissions.data?.userDevicePermissions]
  );

  const columns = useMemo<ColDef[]>(
    () => [
      {
        field: "userId",
        headerName: "ID",
        cellRenderer: (cell: any) => <LinkButton text={cell.value} path={getAdminUserProfileRoute(cell.value)} />,
      },
      {
        field: "userName",
        headerName: "Käyttäjätunnus",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        field: "email",
        headerName: "Sähköposti",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        field: "firstName",
        headerName: "Nimi",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        sort: "desc",
        field: "created",
        headerName: "Luotu",
        cellRenderer: dateRenderer,
        getQuickFilterText: dateRenderer,
      },
    ],
    []
  );

  if (!deviceId) return null;

  const saveHandler = async (users: User[]) => {
    await setUsersForDevice({
      deviceId,
      userIds: users.map((u) => u.userId),
    });
  };

  return (
    <Table
      rowData={users.data}
      multiSelect
      loading={users.isFetching || permissions.isFetching}
      columns={columns}
      onSave={saveHandler}
      isSelected={isSelected}
    />
  );
};

import { useCallback, useEffect, useRef, useState } from "react";
import { useLevels } from "../../../components/Graph/useLevels";
import { useAtom } from "jotai/index";
import { staticPositionAtom } from "../../../components/Graph/atoms/atoms";

export const useLockToNow = () => {
  const [staticPosition, setStaticPosition] = useAtom(staticPositionAtom);
  const [lockToNow, setLockToNow] = useState(true);
  const [resetToNow, setResetToNow] = useState(0);
  const prevResetToNow = useRef(resetToNow);
  const { alignToNow } = useLevels(lockToNow, setLockToNow);

  useEffect(() => {
    if (!lockToNow) {
      return;
    }

    // Run the handler in a timeout because otherwise document.hidden
    // may still be set when switching back to this tab again
    const handler = () =>
      setTimeout(() => {
        if (document.hidden) {
          return;
        }
        setResetToNow((prev) => prev + 1);
      }, 100);

    window.addEventListener("focus", handler);
    const timer = setInterval(handler, 10 * 1000);

    return () => {
      window.removeEventListener("focus", handler);
      clearInterval(timer);
    };
  }, [lockToNow]);

  useEffect(() => {
    if (prevResetToNow.current === resetToNow) {
      return;
    }
    prevResetToNow.current = resetToNow;

    const updated = alignToNow(staticPosition.interval, staticPosition.level);
    if (updated.equals(staticPosition.interval)) {
      return;
    }
    setStaticPosition({
      interval: alignToNow(staticPosition.interval, staticPosition.level),
      level: staticPosition.level,
    });
  }, [resetToNow, alignToNow, staticPosition.interval, staticPosition.level, setStaticPosition]);

  const doSetLockToNow = useCallback(
    (value: boolean) => {
      if (value) {
        setStaticPosition({
          interval: alignToNow(staticPosition.interval, staticPosition.level),
          level: staticPosition.level,
        });
      }
      setLockToNow(value);
    },
    [alignToNow, staticPosition.interval, staticPosition.level, setStaticPosition]
  );
  return { lockToNow, doSetLockToNow };
};

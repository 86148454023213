import { Highlight } from "../types";
import { GraphState } from "../reducer";
import { GRAPH_FONT } from "./constants";
import { renderHighlightText } from "./renderHighlightText";
import { renderHighlightValue } from "./renderHighlightValue";

export const renderHighlight = (
  overlayCanvas: HTMLCanvasElement | null,
  underlayCanvas: HTMLCanvasElement | null,
  graphState: GraphState,
  highlight?: Highlight
) => {
  if (!underlayCanvas || !overlayCanvas) {
    return;
  }

  const ctxUnderlay = underlayCanvas.getContext("2d");
  const ctxOverlay = overlayCanvas.getContext("2d");
  if (!ctxUnderlay || !ctxOverlay) {
    return;
  }

  // @ts-ignore reset canvas
  // eslint-disable-next-line no-self-assign
  overlayCanvas.width = overlayCanvas.width;
  // @ts-ignore reset canvas
  // eslint-disable-next-line no-self-assign
  underlayCanvas.width = underlayCanvas.width;

  if (!highlight) {
    return;
  }

  if (highlight?.exact) {
    if (highlight.exact.timestamp < graphState.interval.start || highlight.exact.timestamp > graphState.interval.end) {
      return; // Don't try rendering offscreen highlights
    }
  } else if (!highlight.interval.overlaps(graphState.interval)) {
    return; // Don't try rendering offscreen highlights
  }

  ctxUnderlay.scale(window.devicePixelRatio, window.devicePixelRatio);
  ctxOverlay.scale(window.devicePixelRatio, window.devicePixelRatio);
  ctxOverlay.font = GRAPH_FONT;

  ctxOverlay.save();
  if (graphState.mode === "normal") {
    ctxOverlay.rect(
      graphState.padding.left,
      0,
      graphState.width - graphState.padding.left - graphState.padding.right,
      graphState.height
    );
    ctxOverlay.clip();
  }

  if (!highlight || graphState.animationTarget) {
    return;
  }

  if (highlight.exact && highlight.exact?.sensor !== graphState.series[0].sensor) {
    return;
  }

  const { keyType, keyType2 } = graphState.series[0].sensor;
  if (!keyType || !keyType2) {
    return;
  }
  const value =
    highlight.exact?.value || graphState.series[0].visibleValues.find((v) => v.interval.equals(highlight.interval));

  const highlightCenter = highlight.interval.start.plus(highlight.interval.toDuration().toMillis() / 2);
  const timestamp = highlight.exact?.timestamp || highlightCenter;

  renderHighlightValue(ctxOverlay, ctxUnderlay, graphState, timestamp, value);
  renderHighlightText(ctxOverlay, graphState, highlight, timestamp, value);
};

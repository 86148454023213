import { useNavigate, useParams } from "react-router-dom";
import { Tabs } from "antd";
import { useMemo } from "react";
import { DeviceTypes } from "./deviceTypes/DeviceTypes";

export const ConfigurationTabs = () => {
  const { activeTab } = useParams();
  const navigate = useNavigate();

  const tabs = useMemo(
    () => [
      {
        label: "Laitetyypit",
        key: "deviceTypes",
        children: <DeviceTypes />,
      },
    ],
    []
  );
  return (
    <>
      <Tabs onChange={navigate} activeKey={activeTab || "deviceTypes"} items={tabs} destroyInactiveTabPane />
    </>
  );
};

export const TimeFrameOptions = [
  {
    label: "24 tuntia",
    value: "24h",
  },
  { label: "30 päivää", value: "30d" },
  { label: "30 päivää minimitunti", value: "30d-min" },
  { label: "12 kuukautta", value: "12m" },
  { label: "20 vuotta", value: "20y" },
];

import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useGetSensorsQuery } from "../../../../../store/api";
import { Select } from "antd";
import { useState } from "react";
import { SessionTable } from "./SessionTable";
import { Sensor } from "../../../../../store/types";

export const SessionEditor = () => {
  const { deviceId } = useParams();
  const [sensor, setSensor] = useState<Sensor>();
  const sensors = useGetSensorsQuery();
  const deviceSensors = (sensors.data?.filter((s) => s.deviceId === deviceId) || []).sort((a, b) => {
    if (a.key < b.key) {
      return -1;
    }
    if (a.key > b.key) {
      return 1;
    }
    return 0;
  });

  const options = deviceSensors.map((s) => ({
    label: `${s.key} - ${s.description}`,
    value: s.key,
  }));

  return (
    <Layout>
      <Toolbar>
        <Select
          placeholder={"Sensori"}
          options={options}
          allowClear
          style={{ width: 400 }}
          onChange={(key) => setSensor(deviceSensors.find((s) => s.key === key))}
        />
      </Toolbar>
      {sensor && deviceId && <SessionTable sensor={sensor} />}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Toolbar = styled.div`
  display: flex;
  gap: 10px;
`;

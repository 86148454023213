import { Provider } from "react-redux";
import { store } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import locale from "antd/locale/fi_FI";
import { ConfigProvider } from "antd";

import dayjs from "dayjs";
import "dayjs/locale/fi";
import { ReleaseNotification } from "./ReleaseNotification";

dayjs.locale("fi");

/**
 * Setup some invisible meta structures for the application
 */
export const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider locale={locale}>
          <ReleaseNotification />
          <AppRoutes />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
};

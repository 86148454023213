import { useParams, Link, Outlet, useMatch } from "react-router-dom";
import { Breadcrumb } from "antd";
import { useGetUsersQuery } from "../../../store/api";
import { LayoutContentGrid } from "../../../utils/styled";
import { useMemo, useRef } from "react";
import { TableState } from "../../../components/Table/interfaces";

export const AdminUser = () => {
  const users = useGetUsersQuery();
  const { userId } = useParams();
  const user = users.data?.find((u) => u.userId.toString() === userId);
  const userListTableState = useRef<TableState>();

  const newMatch = useMatch("/admin/users/new");

  const breadcrumbs = useMemo(() => {
    const items = [];
    if (user || newMatch) {
      items.push({ title: <Link to={"../users"}>Käyttäjät</Link> });
    } else {
      items.push({ title: "Käyttäjät" });
    }
    if (user) {
      if (user.userName) {
        items.push({ title: `${user.userId}: ${user.userName}` });
      } else {
        items.push({ title: user.userId });
      }
    }
    if (newMatch) {
      items.push({ title: "Uusi" });
    }
    return items;
  }, [user, newMatch]);

  return (
    <LayoutContentGrid>
      <Breadcrumb items={breadcrumbs} />
      <Outlet context={userListTableState} />
    </LayoutContentGrid>
  );
};

import { useEditCurrentUserMutation, useGetCurrentUserQuery } from "../../../store/api";
import { User } from "../../../store/types";
import { ContentCard } from "../../../utils/styled";
import { Button, Form, Input } from "antd";

export const UserProfile = () => {
  const currentUser = useGetCurrentUserQuery();
  const [editCurrentUser] = useEditCurrentUserMutation();

  const handleSubmit = async (updated: Partial<User>) => {
    editCurrentUser({
      ...updated,
      userName: updated.userName === "" ? null : updated.userName,
    });
  };

  if (currentUser.isFetching) return null;

  return (
    <ContentCard title={"Henkilötiedot"}>
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} onFinish={handleSubmit} initialValues={currentUser.data}>
        <Form.Item label={"Etunimi"} name={"firstName"}>
          <Input />
        </Form.Item>
        <Form.Item label={"Sukunimi"} name={"lastName"}>
          <Input />
        </Form.Item>
        <Form.Item label={"Käyttäjätunnus"} name={"userName"}>
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 5, span: 10 }}>
          <Button type="primary" htmlType="submit">
            Tallenna
          </Button>
        </Form.Item>
      </Form>
    </ContentCard>
  );
};

import { useParams } from "react-router-dom";
import { useGeneratePasswordMutation, useGetUsersQuery } from "../../../../../store/api";
import { Button, message } from "antd";
import { ContentCard } from "../../../../../utils/styled";

export const GeneratePassword = () => {
  const { userId } = useParams();
  const users = useGetUsersQuery();
  const [generatePassword] = useGeneratePasswordMutation();
  const user = users.data?.find((u) => u.userId.toString() === userId);

  if (users.isFetching) return null;

  const handleClick = async () => {
    if (!user) {
      message.error("Käyttäjää ei löydy");
      return;
    }
    try {
      await generatePassword(user).unwrap();
      message.success("Salasana generoitu");
    } catch (err) {}
  };

  return (
    <ContentCard title={"Salasana"}>
      <p>Käyttäjälle luodaan uusi salasana ja lähetetään se sähköpostilla.</p>
      <Button type={"primary"} onClick={handleClick}>
        Luo salasana
      </Button>
    </ContentCard>
  );
};

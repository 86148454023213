import { createRoot } from "react-dom/client";

// Import ag theme before overrides in index.css
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./index.css";
import { setAutoFreeze } from "immer";

import { App } from "./App";
import type {} from "styled-components/cssprop";
import { setDefaultLocale } from "./utils/locale";

setDefaultLocale();

if (process.env.NODE_ENV === "development") {
  console.log("enable auto freeze on Immer");
  setAutoFreeze(true);
} else {
  setAutoFreeze(false);
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

import { OrganizationEditor } from "../../../../components/organizations/OrganizationEditor/OrganizationEditor";
import { useNavigate } from "react-router-dom";
import { Organization } from "../../../../store/types";
import { useAddOrganizationMutation } from "../../../../store/api";
import { ContentCard } from "../../../../utils/styled";

export const CreateOrganization = () => {
  const navigate = useNavigate();
  const [addOrganization] = useAddOrganizationMutation();

  const submitHandler = async (data: Organization) => {
    const result = await addOrganization({
      ...data,
      businessId: data.businessId || null,
    }).unwrap();
    navigate(`../${result.organizationId}`);
  };

  return (
    <ContentCard title={"Uusi organisaatio"}>
      <OrganizationEditor onSubmit={submitHandler} />
    </ContentCard>
  );
};

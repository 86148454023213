import styled from "styled-components";
import { Button, Card, Divider, Form, Input, message } from "antd";
import { useCallback } from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { LoginUser } from "./types";
import { useLoginMutation } from "../../store/api";

export const Login = () => {
  const [form] = Form.useForm();
  const [login] = useLoginMutation();

  const handleLogin = useCallback(
    async (loginUser: LoginUser) => {
      try {
        await login(loginUser).unwrap();
        window.location.pathname = "/devices";
      } catch (err) {
        message.error("Väärä käyttäjätunnus tai salasana");
      }

      form.resetFields();
    },
    [form, login]
  );

  const handleDemoLogin = useCallback(async () => {
    try {
      await login({ username: "demo", password: "demo" }).unwrap();
      window.location.pathname = "/devices";
    } catch (err) {
      message.error("Väärä käyttäjätunnus tai salasana");
    }

    form.resetFields();
  }, [form, login]);

  return (
    <Layout>
      <Logo src="/assets/Vilkase-logo-2017-trnsp.png" alt={"logo"} />
      <Card style={{ maxWidth: "400px" }} bordered={false}>
        <Form onFinish={handleLogin} form={form}>
          <Form.Item name={"username"} rules={[{ required: true, message: "" }]}>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={"Käyttäjätunnus tai sähköpostiosoite"}
            />
          </Form.Item>
          <Form.Item name={"password"} rules={[{ required: true, message: "" }]}>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={"Salasana"}
              type={"password"}
            />
          </Form.Item>
          <Form.Item>
            <Button block type={"primary"} htmlType={"submit"}>
              Kirjaudu sisään
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        <p style={{ textAlign: "center" }}>Voit tutustua palveluun ilman käyttäjätunnuksia</p>
        <Button block onClick={handleDemoLogin}>
          Tutustu palveluun
        </Button>
        <Divider />
        <a href="http://www.vilkase.fi">
          <Button block type={"link"}>
            www.vilkase.fi
          </Button>
        </a>
      </Card>
    </Layout>
  );
};

const Logo = styled.img`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const Layout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .site-form-item-icon {
    color: rgba(0, 0, 0, 0.25);
  }
`;

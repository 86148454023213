import { Level, Sensor, ValueTypes } from "../../store/types";
import { DateTime, Interval } from "luxon";

export enum GraphType {
  Bar = "bar",
  Line = "line",
  Area = "area",
}

export type Padding = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export type ScaleSide = "left" | "right";

export type SeriesOptions = {
  sensor: Sensor;
  type: GraphType;
  color: string;
  scaleSide: ScaleSide;
};

export type AnimationTarget = {
  interval: Interval;
  level: Level;
};

export interface SeriesData extends SeriesOptions {
  id: number;
  visibleValues: ValueTypes[];
  values: {
    [level in Level]: ValueTypes[];
  };
}

export interface Highlight {
  interval: Interval;
  exact?: {
    value: ValueTypes;
    sensor: Sensor;
    timestamp: DateTime;
  };
}

export interface Position {
  interval: Interval;
  level: Level;
}

import { useNavigate, useParams } from "react-router-dom";
import { Tabs } from "antd";
import { useMemo } from "react";
import { UserProfile } from "./info/UserProfile";
import { UserPassword } from "./password/UserPassword";
import { UserVerificationEmail } from "./email/UserVerificationEmail";
import { UserVerificationPhone } from "./phone/UserVerificationPhone";

export const UserTabs = () => {
  const { activeTab } = useParams();
  const navigate = useNavigate();

  const tabs = useMemo(
    () => [
      {
        label: "Henkilötiedot",
        key: "info",
        children: <UserProfile />,
      },
      { label: "Sähköposti", key: "email", children: <UserVerificationEmail /> },
      { label: "Puhelinnumero", key: "phone", children: <UserVerificationPhone /> },
      {
        label: "Salasana",
        key: "password",
        children: <UserPassword />,
      },
    ],
    []
  );
  return (
    <>
      <Tabs onChange={navigate} activeKey={activeTab || "info"} items={tabs} destroyInactiveTabPane />
    </>
  );
};

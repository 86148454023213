import { Form } from "antd";
import { Setting, SettingType } from "../../../../../store/types";
import { SettingEditorCardFormInputString } from "./SettingEditorCardFormInputString";
import { SettingEditorCardFormInputInteger } from "./SettingEditorCardFormInputInteger";
import { LUXON_DATETIME_FORMAT } from "../../../../../constants";
import { DateTime } from "luxon";

export const SettingEditorCardForm = ({
  setting,
  onChange,
  mode,
}: {
  setting: Setting;
  onChange: (value: any) => void;
  mode: "admin" | "user";
}) => {
  return (
    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} initialValues={setting} size={"small"}>
      <Form.Item label={setting.name} name={"value"}>
        {setting.settingType === SettingType.SettingTypeString && (
          <SettingEditorCardFormInputString disabled={mode !== "admin"} value={setting.value} onChange={onChange} />
        )}
        {setting.settingType === SettingType.SettingTypeInteger && (
          <SettingEditorCardFormInputInteger disabled={mode !== "admin"} value={setting.value} onChange={onChange} />
        )}
      </Form.Item>
      <Form.Item label={"Asetusaika"}>
        {DateTime.fromISO(setting.updatedTime).toFormat(LUXON_DATETIME_FORMAT)}
      </Form.Item>
    </Form>
  );
};

export const ORG_TYPE_VESIOSUUSKUNTA = 1;
export const ORG_TYPE_VESILAITOS = 2;
export const ORG_TYPE_YRITYS = 3;
export const ORG_TYPE_YKSITYINEN = 4;
export const ORG_TYPE_MUU = 5;

export const orgTypeString = (typeId: number) => {
  switch (typeId) {
    case ORG_TYPE_YRITYS:
      return "Yritys";
    case ORG_TYPE_YKSITYINEN:
      return "Yksityinen";
    case ORG_TYPE_VESIOSUUSKUNTA:
      return "Vesiosuuskunta";
    case ORG_TYPE_VESILAITOS:
      return "Vesilaitos";
    case ORG_TYPE_MUU:
      return "Muu";
    default:
      return "Muu";
  }
};

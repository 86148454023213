import { KeyType, Level, ValueEnum, ValueTypes } from "../../../store/types";
import { humanizeValueStr } from "../../../store/utils";
import { Highlight, SeriesData } from "../types";
import { X_AXIS_FORMAT } from "./axis";
import { GraphState } from "../reducer";
import { shadeColor } from "../../../utils/colors";
import { getX, getY } from "../utils";

export const getValueText = (value: ValueTypes, keyType: KeyType, rawLevelHighlightType: "value" | "raw"): string => {
  if (value._type === ValueEnum.RAW) {
    return humanizeValueStr(value.value, value.value, keyType, false);
  } else if (value._type === ValueEnum.PLAIN) {
    if (keyType === KeyType.TimeSeconds) {
      return humanizeValueStr(value.value, value.value, keyType, false);
    } else {
      return humanizeValueStr(value.value, value.value, keyType);
    }
  } else if (value._type === ValueEnum.LMIN) {
    if (rawLevelHighlightType === "raw") {
      const valuesString =
        keyType === KeyType.TimeSeconds ? value.rawValue : humanizeValueStr(value.rawValue, 0, keyType);
      return `${valuesString}`;
    } else if (rawLevelHighlightType === "value") {
      const valuesString = keyType === KeyType.TimeSeconds ? value.value : humanizeValueStr(value.value, 0, keyType);
      return `${valuesString}/min`;
    }
  } else if (value._type === ValueEnum.AVG) {
    if (keyType === KeyType.Pressure) {
      return humanizeValueStr(value.avg, value.avg, keyType, false);
    } else {
      return humanizeValueStr(value.avg, value.avg, keyType);
    }
  }
  return "";
};

export const getTimeText = (level: Level, highlight: Highlight): string => {
  if (highlight.exact) {
    return highlight.exact.timestamp.toFormat("H:mm:ss");
  }
  return X_AXIS_FORMAT[level].highlight.format(highlight.interval);
};

export const renderBall = (
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  graphState: GraphState,
  series: SeriesData,
  timestampMs: number,
  value: number
) => {
  ctx.strokeStyle = shadeColor(series.color, -30);
  ctx.fillStyle = shadeColor(series.color, -20);
  const x = getX(graphState, timestampMs);
  const y = getY(graphState, series.scaleSide, value);
  const radius = graphState.mode === "tiny" ? 3 : 4;
  ctx.arc(x, y, radius, 0, 2 * Math.PI);
  ctx.closePath();
  ctx.fill();
};

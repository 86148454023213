import { useParams, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import { EditOrganization } from "./info/EditOrganization";
import { AdminOrganizationUsersList } from "./users/AdminOrganizationUsersList";
import styled from "styled-components";
import { AdminOrganizationDevicesList } from "./devices/AdminOrganizationDevicesList";
import { useMemo } from "react";
import { FullSizeLayout } from "../../../../utils/styled";

export const AdminOrganizationTabs = () => {
  const { activeTab } = useParams();
  const navigate = useNavigate();
  const tabs = useMemo(
    () => [
      {
        label: "Tiedot",
        key: "info",
        children: <EditOrganization />,
      },
      {
        label: "Laitteet",
        key: "devices",
        children: (
          <FullSizeLayout>
            <AdminOrganizationDevicesList />
          </FullSizeLayout>
        ),
      },
      {
        label: "Käyttäjät",
        key: "users",
        children: (
          <FullSizeLayout>
            <AdminOrganizationUsersList />
          </FullSizeLayout>
        ),
      },
    ],
    []
  );

  return (
    <Layout>
      <Tabs onChange={navigate} activeKey={activeTab} style={{ height: "100%" }} items={tabs} />
    </Layout>
  );
};

const Layout = styled.div`
  height: 100%;
  .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-tabpane {
    height: 100%;
  }
`;

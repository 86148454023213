import { useNavigate } from "react-router-dom";
import { useAddUserMutation, useGeneratePasswordMutation } from "../../../../store/api";
import { User } from "../../../../store/types";
import { ContentCard } from "../../../../utils/styled";
import { Button, Form, Input, message } from "antd";

export const CreateUser = () => {
  const navigate = useNavigate();
  const [addUser] = useAddUserMutation();
  const [generatePassword] = useGeneratePasswordMutation();

  const handleSubmit = async (data: User) => {
    const result = await addUser({
      ...data,
      email: data.email || null,
      userName: data.userName || null,
    }).unwrap();

    await generatePassword(result).unwrap();
    navigate(`../${result.userId}`);

    message.success("Käyttäjä on luotu ja salasana on lähetetty sähköpostiin");
  };

  return (
    <ContentCard title={"Uusi käyttäjä"}>
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} onFinish={handleSubmit}>
        <Form.Item label={"Etunimi"} name={"firstName"}>
          <Input />
        </Form.Item>
        <Form.Item label={"Sukunimi"} name={"lastName"}>
          <Input />
        </Form.Item>
        <Form.Item
          label={"Sähköposti"}
          name={"email"}
          required
          validateTrigger={"onSubmit"}
          rules={[{ required: true, message: "Anna sähköpostiosoite oikeassa muodossa", type: "email" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 5, span: 10 }}>
          <Button type="primary" htmlType="submit">
            Tallenna
          </Button>
        </Form.Item>
      </Form>
    </ContentCard>
  );
};

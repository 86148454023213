import { InputNumber } from "antd";

export const SettingEditorCardFormInputInteger = ({
  value,
  onChange,
  disabled,
}: {
  value: any;
  onChange: (value: any) => void;
  disabled?: boolean;
}) => {
  return <InputNumber disabled={disabled} value={value} onChange={(e) => onChange(e)} style={{ width: "200px" }} />;
};

import { DeviceEditorForm } from "../../../../devices/device/settings/DeviceEditorForm";
import { useParams } from "react-router-dom";
import { useGetDevicesQuery } from "../../../../../store/api";
import { ContentCard } from "../../../../../utils/styled";

export const AdminEditDevice = () => {
  const { deviceId } = useParams();
  const devices = useGetDevicesQuery();
  const device = devices.data?.find((d) => d.deviceId === deviceId);

  if (!device) {
    return null;
  }

  return (
    <ContentCard title={"Laitteen tiedot"}>
      <DeviceEditorForm mode="admin" device={device} />
    </ContentCard>
  );
};

import {
  useCompletePhoneNumberVerificationMutation,
  useEditCurrentUserMutation,
  useGetCurrentUserQuery,
  useStartPhoneNumberVerificationMutation,
} from "../../../store/api";
import { ContentCard } from "../../../utils/styled";
import { Button, Form, Input, message } from "antd";
import { useCallback, useState } from "react";
import { VerificationCodeModal } from "../../../components/users/VerificationCodeModal/VerificationCodeModal";

export const UserVerificationPhone = () => {
  const currentUser = useGetCurrentUserQuery();
  const [editCurrentUser] = useEditCurrentUserMutation();
  const [startVerification] = useStartPhoneNumberVerificationMutation();
  const [completeVerification] = useCompletePhoneNumberVerificationMutation();
  const [valueToVerify, setValueToVerify] = useState<string>();

  const handleStart = useCallback(
    async (form: { phone: string }) => {
      try {
        setValueToVerify(form.phone);
        await startVerification({ phone: form.phone }).unwrap();
      } catch (err: any) {
        setValueToVerify(undefined);
      }
    },
    [startVerification]
  );

  const handleComplete = useCallback(
    async (code: string) => {
      try {
        await completeVerification({ code }).unwrap();
        setValueToVerify(undefined);
        message.success("Puhelinnumero on tallennettu", 5);
      } catch (err) {}
    },
    [completeVerification]
  );

  const handleSubmit = useCallback(
    async (form: { phone: string }) => {
      if (!form.phone) {
        await editCurrentUser({ phone: null });
      } else {
        await handleStart(form);
      }
    },
    [editCurrentUser, handleStart]
  );

  if (currentUser.isFetching) return null;

  return (
    <>
      <ContentCard title={"Puhelinnumero"}>
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} onFinish={handleSubmit} initialValues={currentUser.data}>
          <Form.Item
            label={"Puhelinnumero"}
            name={"phone"}
            validateTrigger={"onSubmit"}
            rules={[{ pattern: /^\+358\d{8,10}$|^$/, message: "Anna puhelinnumero +358 suuntanumerolla" }]}
          >
            <Input value={currentUser.data?.phone || ""} placeholder={"+358"} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5, span: 10 }}>
            <Button type="primary" htmlType="submit">
              Tallenna
            </Button>
          </Form.Item>
        </Form>
      </ContentCard>
      {!!valueToVerify && (
        <VerificationCodeModal
          title={"Varmenna uusi puhelinnumero"}
          description={`Tarkista tekstiviesti numerosta ${valueToVerify} ja kirjoita varmennuskoodi tähän. Koodi on voimassa 10 minuuttia.`}
          onCancel={() => setValueToVerify(undefined)}
          onComplete={handleComplete}
        />
      )}
    </>
  );
};

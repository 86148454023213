import { Level, Sensor, ViewType } from "../types";
import { useEffect, useRef, useState } from "react";
import { DateTime, Duration, Interval } from "luxon";
import { useIsMounted } from "../../utils/useIsMounted";
import { fetchValues } from "./thunk";
import { useAppDispatch } from "../hooks";
import { baseUrl } from "../../constants";
import { refresh } from "./reducer";
import { api, useReloadSensorMutation } from "../api";

export const useFetchValues = (
  sensor: Sensor,
  level?: Level,
  interval?: Interval,
  viewType?: ViewType,
  nowThreshold?: Duration,
  realtime?: boolean
) => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  const [reloadSensor] = useReloadSensorMutation();
  const [refreshCounter, setRefreshCounter] = useState(0);
  const prevFetchCounter = useRef(0);

  useEffect(() => {
    if (!isMounted()) {
      return;
    }
    if (!level || !interval || !viewType) {
      return;
    }

    // Refresh is triggered by sse when backend has new data
    const isRefresh = refreshCounter !== prevFetchCounter.current;
    prevFetchCounter.current = refreshCounter;

    // Request a wider amount of values so that they exist in advance when panning left/right
    const extendedInterval = Interval.fromDateTimes(
      interval.start.minus(interval.toDuration()),
      interval.end.plus(interval.toDuration())
    );
    dispatch(fetchValues(sensor, level, extendedInterval, isRefresh ? undefined : nowThreshold));
    if (isRefresh) {
      reloadSensor({ deviceId: sensor.deviceId, key: sensor.key });
    }
  }, [dispatch, isMounted, level, nowThreshold, refreshCounter, interval, reloadSensor, viewType, sensor]);

  useEffect(() => {
    if (!realtime || !isMounted()) {
      return;
    }
    const eventSource = new EventSource(`${baseUrl}/api/devices/${sensor.deviceId}/keys/${sensor.key}/values/wait`, {
      withCredentials: true,
    });
    eventSource.onopen = (event) => {
      console.log("eventSource onOpen", new Date().toLocaleString());
    };
    eventSource.onerror = (event) => {
      console.log("eventSource error", new Date().toLocaleString());
    };
    eventSource.onmessage = (event) => {
      console.log("eventSource onMessage", new Date().toLocaleString());
      if (document.hidden) {
        return;
      }
      dispatch(refresh(sensor));
      const updateTimestamp = DateTime.now().minus({ second: 1 }).toISO();
      dispatch(
        api.util.updateQueryData("getDevices", undefined, (draft) => {
          const device = draft.find((d) => d.deviceId === sensor.deviceId);
          if (!device) {
            return;
          }
          device.latestConnection = updateTimestamp;
        })
      );
      setRefreshCounter((prev) => prev + 1);
    };

    // Run the handler in a timeout because otherwise document.hidden
    // may still be set when switching back to this tab again
    const handler = () =>
      setTimeout(() => {
        if (document.hidden) {
          return;
        }
        setRefreshCounter((prev) => prev + 1);
      }, 100);
    window.addEventListener("focus", handler);

    return () => {
      eventSource?.close();
      window.removeEventListener("focus", handler);
    };
  }, [dispatch, isMounted, realtime, reloadSensor, sensor, sensor.deviceId, sensor.key]);
};

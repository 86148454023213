import { Sensor, Session } from "../../../../../store/types";
import { Button, Input, message, Modal } from "antd";
import { useCallback, useState } from "react";
import { useDeleteSessionMutation } from "../../../../../store/api";

export const DeleteModal = ({
  sessions,
  onClose,
  sensor,
}: {
  sessions: Session[];
  onClose: () => void;
  sensor: Sensor;
}) => {
  const [verification, setVerification] = useState("");

  const [deleteSession] = useDeleteSessionMutation();
  const handleEdit = useCallback(async () => {
    try {
      for (const s of sessions) {
        await deleteSession({ keyId: s.keyId, sessionId: s.sessionId });
      }
      message.success("Poisto suoritettu");
    } catch (err) {
      console.log("Delete session: ", err);
    }
    onClose();
  }, [deleteSession, onClose, sessions]);

  return (
    <Modal
      open
      title={"Poista lukemia"}
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose}>Peruuta</Button>
          <Button onClick={handleEdit} disabled={verification !== sensor.deviceId} type={"primary"} danger>
            Poista
          </Button>
        </>
      }
    >
      <p>
        Valitut sessiot poistetaan laitteen <b>{sensor.deviceId}</b> sensorilta <b>{sensor.key}</b>
      </p>
      <p>Kirjoita laitteen ID varmennukseksi</p>
      <Input placeholder={"LaiteID"} onChange={(e) => setVerification(e.target.value)} />
    </Modal>
  );
};

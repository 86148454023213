import { useCallback, useEffect } from "react";
import { GraphActions, GraphState } from "./reducer";

export const useResize = (
  graphState: GraphState,
  graphDispatch: React.Dispatch<GraphActions>,
  layoutRef: React.RefObject<HTMLDivElement>,
  mode: "normal" | "tiny"
) => {
  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const size = entries[0].contentBoxSize[0];
      graphDispatch({
        type: "setSize",
        payload: [size.inlineSize, size.blockSize],
      });
    },
    [graphDispatch]
  );

  useEffect(() => {
    if (mode !== "normal" || !layoutRef.current) {
      return;
    }

    const observer = new ResizeObserver(handleResize);
    const layout = layoutRef.current;
    observer.observe(layout);
    return () => {
      observer.unobserve(layout);
    };
  }, [handleResize, layoutRef, mode]);
};

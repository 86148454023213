import { Checkbox, Divider, Form, Input, InputNumber, Space } from "antd";
import { DateTime } from "luxon";
import { Sensor } from "../../../../store/types";
import { SensorSelect } from "./SensorSelect";
import styled from "styled-components";

export const SensorConfigurationCardForm = ({
  sensor,
  onChange,
  mode,
}: {
  sensor: Sensor;
  onChange: (sensor: Sensor) => void;
  mode: "admin" | "user";
}) => {
  return (
    <Layout>
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} initialValues={sensor} size={"small"}>
        <Form.Item label={"Tyyppi"}>
          <SensorSelect
            disabled={mode !== "admin"}
            value={sensor.keyType}
            onSelect={(keyType) => onChange({ ...sensor, keyType })}
          />
        </Form.Item>

        <Form.Item label={"Kuvaus"}>
          <Input
            disabled={mode !== "admin"}
            value={sensor.description}
            onChange={(e) => onChange({ ...sensor, description: e.target.value })}
          />
        </Form.Item>

        <Form.Item label={"Mittarin sarjanumero"}>
          <Input
            value={sensor.meterSerialNumber}
            onChange={(e) => onChange({ ...sensor, meterSerialNumber: e.target.value })}
          />
        </Form.Item>

        <Form.Item label={"Viimeisin arvo"} tooltip={"Ilman lukumuunnosta"}>
          {sensor.latestUpdate && (
            <>
              <b>{sensor.latestValueRaw}</b> @ 
              {DateTime.fromISO(sensor.latestUpdate || "").toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
            </>
          )}
        </Form.Item>

        {mode === "admin" && (
          <Form.Item
            label={"Lukumuunnos"}
            name={"sumTerm"}
            tooltip={
              "Lopullinen arvo = (Arvo + Summatermi) × Kerrointermi. Tämä vaikuttaa kaikkiin menneisiin sekä tuleviin lukemiin"
            }
          >
            <Space>
              <InputNumber
                addonBefore={"+"}
                value={sensor.sumTerm}
                onChange={(e) => {
                  if (e != null) {
                    onChange({ ...sensor, sumTerm: e });
                  }
                }}
              />
              <InputNumber
                addonBefore={"×"}
                value={sensor.factorTerm}
                onChange={(e) => {
                  if (e != null) {
                    onChange({ ...sensor, factorTerm: e });
                  }
                }}
              />
            </Space>
          </Form.Item>
        )}
      </Form>
      <RightPanel>
        <Divider type="vertical" style={{ height: "100%" }} />
        <Checkboxes>
          {mode === "admin" && (
            <Checkbox
              checked={sensor.displayOnFrontPage}
              onChange={(e) => onChange({ ...sensor, displayOnFrontPage: e.target.checked })}
            >
              Näytä etusivulla
            </Checkbox>
          )}
          {mode === "admin" && (
            <Checkbox
              checked={sensor.smartAlert}
              onChange={(e) => onChange({ ...sensor, smartAlert: e.target.checked })}
            >
              Älykkäät hälytykset
            </Checkbox>
          )}
        </Checkboxes>
      </RightPanel>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightPanel = styled.div`
  display: flex;
  width: 250px;
  gap: 8px;
`;

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

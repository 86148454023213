import { OrganizationEditor } from "../../../../../components/organizations/OrganizationEditor/OrganizationEditor";
import { useParams } from "react-router-dom";
import { useEditOrganizationMutation, useGetOrganizationsQuery } from "../../../../../store/api";
import { Organization } from "../../../../../store/types";
import { ContentCard } from "../../../../../utils/styled";

export const EditOrganization = () => {
  const { orgId } = useParams();
  const organizations = useGetOrganizationsQuery();
  const [editOrganization] = useEditOrganizationMutation();
  const org = organizations.data?.find((o) => o.organizationId === orgId);

  const submitHandler = async (updated: Organization) => {
    editOrganization({
      ...org,
      ...updated,
      businessId: updated.businessId || null,
    });
  };

  if (organizations.isFetching) return null;

  return (
    <ContentCard title={"Organisaation tiedot"}>
      <OrganizationEditor onSubmit={submitHandler} initialValues={org} />
    </ContentCard>
  );
};

import { Interval } from "luxon";
import { Level, Sensor, ViewType } from "../../../store/types";
import { ContentCard } from "../../../utils/styled";
import { DeviceViewGraphCard } from "./DeviceViewGraphCard";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { DeviceViewIntervalSelect } from "./DeviceViewIntervalSelect";
import { Highlight } from "../../../components/Graph/types";
import { useAnimateAll } from "../../../components/Graph/useAnimateAll";
import { staticPositionAtom } from "../../../components/Graph/atoms/atoms";
import { useAtom } from "jotai";
import { useLockToNow } from "./useLockToNow";

export const DeviceViewGraphs = ({
  visibleSensors,
  viewType,
}: {
  visibleSensors: Sensor[];
  viewType: ViewType;
}): JSX.Element | null => {
  const [highlight, setHighlight] = useState<Highlight>();
  const [localViewType, setLocalViewType] = useState(viewType);
  const [staticPosition, setStaticPosition] = useAtom(staticPositionAtom);

  const handleStaticPosition = useCallback(
    (p: { interval: Interval; level: Level }) => {
      if (p.level !== Level.day) {
        setLocalViewType(ViewType.Auto);
      }
      setStaticPosition(p);
    },
    [setStaticPosition]
  );

  const onAnimate = useAnimateAll(handleStaticPosition);
  const { lockToNow, doSetLockToNow } = useLockToNow();

  return (
    <>
      <Toolbar data-testid={"toolbar"}>
        <ContentCard size={"small"}>
          <DeviceViewIntervalSelect
            staticPosition={staticPosition}
            onSetStaticPosition={handleStaticPosition}
            viewType={localViewType}
            setViewType={setLocalViewType}
            lockToNow={lockToNow}
            setLockToNow={doSetLockToNow}
            onAnimate={onAnimate}
          />
        </ContentCard>
      </Toolbar>
      {visibleSensors?.map((sensor) => (
        <DeviceViewGraphCard
          key={sensor.deviceId + sensor.key}
          sensor={sensor}
          highlight={highlight}
          setHighlight={setHighlight}
          onSetStaticPosition={handleStaticPosition}
          viewType={localViewType}
          lockToNow={lockToNow}
          setLockToNow={doSetLockToNow}
          onAnimate={onAnimate}
        />
      ))}
    </>
  );
};

const Toolbar = styled.div`
  @media (min-height: 450px) {
    position: sticky;
  }

  top: 0;
  z-index: 10;
  display: flex;
  gap: 8px;
`;

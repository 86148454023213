import { DateTime } from "luxon";
import { Device } from "../../../store/types";
import { Descriptions } from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import { useState } from "react";
import styled from "styled-components";

export const DeviceViewDescription = ({ device }: { device: Device }): JSX.Element | null => {
  const address = [device?.address, device?.address2].filter(Boolean).join(", ");
  const [noteSummary, setNoteSummary] = useState(() => {
    const lines = device.notes.split("\n");
    if (lines.length > 1) {
      return lines[0] + " ...";
    }
    return undefined;
  });

  return (
    <Descriptions>
      <DescriptionsItem label={"Kuvaus"}>{device.description}</DescriptionsItem>
      <DescriptionsItem label={"Laitetyyppi"}>{device.deviceType?.name}</DescriptionsItem>
      <DescriptionsItem label={"Viimeisin yhteys"}>
        {device.latestConnection &&
          DateTime.fromISO(device.latestConnection || "").toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
      </DescriptionsItem>
      <DescriptionsItem label={"Osoite"}>{address}</DescriptionsItem>
      <DescriptionsItem label={"Käyttöpaikka"}>{device.siteId}</DescriptionsItem>
      <DescriptionsItem label={"Muistiinpanot"} style={{ whiteSpace: "pre-line" }}>
        {noteSummary ? (
          <NoteSummary onClick={() => setNoteSummary(undefined)}>{noteSummary}</NoteSummary>
        ) : (
          device.notes
        )}
      </DescriptionsItem>
    </Descriptions>
  );
};

const NoteSummary = styled.span`
  cursor: pointer;
`;

import { useLocation, useNavigate } from "react-router-dom";
import { useGetCurrentUserQuery } from "./store/api";
import { Menu } from "antd";
import {
  RocketOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  FileTextOutlined,
  LineChartOutlined,
  LogoutOutlined,
  LinkOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { DEMO_USER_ID } from "./components/constants";
import {
  adminOrganizationsBaseRoute,
  adminUserBaseRoute,
  adminDeviceBaseRoute,
  adminConfigurationBaseRoute,
  devicesBaseRoute,
  reportsRoute,
  profileBaseRoute,
} from "./utils/routes";
import { useMemo } from "react";
import { ItemType } from "antd/lib/menu/interface";

export const AppMenu = ({ narrowLayout, close }: { narrowLayout: boolean; close?: () => void }) => {
  const currentUser = useGetCurrentUserQuery();
  const navigate = useNavigate();

  const location = useLocation();

  const items = useMemo(() => {
    const all = [
      {
        label: (
          <a href={devicesBaseRoute} onClick={(e) => e.preventDefault()}>
            Mittarit
          </a>
        ),
        key: devicesBaseRoute,
        icon: <LineChartOutlined />,
      },
      {
        label: (
          <a href={reportsRoute} onClick={(e) => e.preventDefault()}>
            Raportit
          </a>
        ),
        key: reportsRoute,
        icon: <FileTextOutlined />,
      },
      ...(currentUser.data?.userId !== DEMO_USER_ID
        ? [
            {
              label: (
                <a href={profileBaseRoute} onClick={(e) => e.preventDefault()}>
                  Profiili
                </a>
              ),
              key: profileBaseRoute,
              icon: <UserOutlined />,
            },
          ]
        : []),
      {
        type: "divider",
      },
      {
        label: "Kirjaudu ulos",
        key: "logout",
        icon: <LogoutOutlined />,
      },
      {
        label: (
          <a href={"https://www.vilkase.fi"} target="_blank" rel="noreferrer">
            www.vilkase.fi
          </a>
        ),
        key: "www.vilkase.fi",
        icon: <LinkOutlined />,
      },
    ];

    if (currentUser.data?.isAdmin) {
      all.push(
        // @ts-ignore
        ...[
          {
            type: "divider",
          },
          {
            label: "Admin",
            key: "admin",
            type: "group",
          },
          {
            label: (
              <a href={adminOrganizationsBaseRoute} onClick={(e) => e.preventDefault()}>
                Organisaatiot
              </a>
            ),
            key: adminOrganizationsBaseRoute,
            icon: <ShopOutlined />,
          },
          {
            label: (
              <a href={adminUserBaseRoute} onClick={(e) => e.preventDefault()}>
                Käyttäjät
              </a>
            ),
            key: adminUserBaseRoute,
            icon: <TeamOutlined />,
          },
          {
            label: (
              <a href={adminDeviceBaseRoute} onClick={(e) => e.preventDefault()}>
                Laitteet
              </a>
            ),
            key: adminDeviceBaseRoute,
            icon: <RocketOutlined />,
          },
          {
            label: (
              <a href={adminConfigurationBaseRoute} onClick={(e) => e.preventDefault()}>
                Asetukset
              </a>
            ),
            key: adminConfigurationBaseRoute,
            icon: <SlidersOutlined />,
          },
        ]
      );
    }
    return all;
  }, [currentUser.data?.isAdmin, currentUser.data?.userId]);

  const selectedKey = useMemo(() => {
    const selectedItem = items.find((item) => item?.key && location.pathname.startsWith(item.key));
    if (!selectedItem) {
      return null;
    }
    return selectedItem.key as string;
  }, [items, location.pathname]);

  if (currentUser.isLoading) {
    return null;
  }

  if (narrowLayout) {
    return (
      <Menu
        selectedKeys={selectedKey ? [selectedKey] : undefined}
        items={items as ItemType[]}
        onClick={({ key }) => {
          close?.();
          if (key === "www.vilkase.fi") {
            return;
          } else {
            navigate(key);
          }
        }}
      />
    );
  }

  return (
    <SideLayout>
      <Menu
        selectedKeys={selectedKey ? [selectedKey] : undefined}
        items={items as ItemType[]}
        style={{ backgroundColor: "var(--background-color)" }}
        onClick={({ key }) => {
          if (key === "www.vilkase.fi") {
            return;
          } else {
            navigate(key);
          }
        }}
      />
    </SideLayout>
  );
};

const SideLayout = styled.div`
  width: 200px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 10px;
`;

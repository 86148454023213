import { Level, Sensor, ValueTypes, ViewType } from "../types";
import { useMemo } from "react";
import { Duration, Interval } from "luxon";
import { useLevelValues } from "./useLevelValues";

export const useValues = (
  sensor: Sensor,
  level: Level,
  interval: Interval,
  viewType: ViewType,
  nowThreshold?: Duration
): ValueTypes[] => {
  const levelValues = useLevelValues(sensor, level, interval, viewType, nowThreshold);
  return useMemo(() => {
    return levelValues.filter((v) => interval.overlaps(v.interval));
  }, [interval, levelValues]);
};

import { Input } from "antd";

export const SettingEditorCardFormInputString = ({
  value,
  onChange,
  disabled,
}: {
  value: any;
  onChange: (value: any) => void;
  disabled?: boolean;
}) => {
  return (
    <Input disabled={disabled} value={value} onChange={(e) => onChange(e.target.value)} style={{ width: "200px" }} />
  );
};

import { GraphType } from "../types";
import { GraphState } from "../reducer";
import { Level, ValueEnum, ValueTypes } from "../../../store/types";
import { renderBars } from "./renderBars";
import { shadeColor } from "../../../utils/colors";
import { renderBall } from "./renderHighlightUtils";
import { DateTime } from "luxon";
import { getX } from "../utils";

export const renderHighlightValue = (
  ctxOverlay: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  ctxUnderlay: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  graphState: GraphState,
  timestamp: DateTime,
  value?: ValueTypes
) => {
  const { keyType, keyType2 } = graphState.series[0].sensor;
  if (!keyType || !keyType2) {
    return;
  }

  const x = getX(graphState, timestamp.toMillis());
  const yTop = graphState.mode === "normal" ? graphState.padding.top : 0;
  const yBottom = graphState.mode === "normal" ? graphState.height - graphState.padding.bottom : graphState.height;

  ctxUnderlay.strokeStyle = "gray";
  ctxUnderlay.beginPath();
  ctxUnderlay.setLineDash([2, 4]);
  ctxUnderlay.moveTo(x, yTop);
  ctxUnderlay.lineTo(x, yBottom);
  ctxUnderlay.closePath();
  ctxUnderlay.stroke();

  if (!value) {
    return;
  }

  graphState.series.forEach((series) => {
    ctxOverlay.beginPath();

    if (graphState.level === Level.raw) {
      if (value._type === ValueEnum.RAW) {
        renderBall(ctxOverlay, graphState, series, value.timestampMs, value.value);
      }
      if (value._type === ValueEnum.LMIN) {
        renderBall(ctxOverlay, graphState, series, value.timestampMs, value.value);
      }
    } else {
      if (series.type === GraphType.Bar) {
        const limitedSeries = {
          ...series,
          visibleValues: [value],
          color: shadeColor(series.color, -30),
        };
        renderBars(ctxOverlay, graphState, limitedSeries);
      } else if (series.type === GraphType.Area) {
        if (value._type === ValueEnum.AVG) {
          if (!value.samples) {
            return;
          }
          renderBall(ctxOverlay, graphState, series, value.timestampMs, value.avg);
        } else {
          console.log("unsupported value type for area graph");
        }
      }
    }
  });
};

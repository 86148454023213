import { Table } from "../../../components/Table/Table";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useGetUsersQuery } from "../../../store/api";
import { dateRenderer } from "../../../components/Table/cellRenderer";
import { WIDE_COLUMN_WIDTH } from "../../../components/constants";
import { MutableRefObject, useMemo } from "react";
import { ColDef } from "ag-grid-community";
import { LinkButton } from "../../../components/LinkButton";
import { getAdminUserProfileRoute } from "../../../utils/routes";
import { TableState } from "../../../components/Table/interfaces";

export const AdminUserList = () => {
  const navigate = useNavigate();
  const users = useGetUsersQuery();

  const tableState = useOutletContext<MutableRefObject<TableState | undefined>>();

  const addHandler = async () => {
    navigate(`new`);
  };

  const columns = useMemo<ColDef[]>(
    () => [
      {
        field: "userId",
        headerName: "ID",
        cellRenderer: (cell: any) => <LinkButton path={getAdminUserProfileRoute(cell.value)} text={cell.value} />,
      },
      {
        field: "userName",
        headerName: "Käyttäjätunnus",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        field: "email",
        headerName: "Sähköposti",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        field: "firstName",
        headerName: "Nimi",
        width: WIDE_COLUMN_WIDTH,
      },
      {
        sort: "desc",
        field: "created",
        headerName: "Luotu",
        cellRenderer: dateRenderer,
        getQuickFilterText: dateRenderer,
      },
    ],
    []
  );

  return (
    <Table
      tableState={tableState}
      rowData={users.data}
      loading={users.isFetching}
      onAdd={addHandler}
      columns={columns}
    />
  );
};

import { Sensor } from "../../store/types";
import { humanizeValueStr } from "../../store/utils";

export const sensorValuePlainFormatter = (data: { data: Sensor; value: any }) => {
  if (!data.data.keyType) {
    return "";
  }
  if (data.data.latestValue === null) {
    return "";
  }
  return humanizeValueStr(data.value, 1, data.data.keyType, false, true);
};

import { Input, Modal } from "antd";
import { useEffect, useRef } from "react";

const CODE_LENGTH = 6;

export const VerificationCodeModal = ({
  title,
  description,
  onCancel,
  onComplete,
}: {
  title: string;
  description: string;
  onCancel: () => void;
  onComplete: (code: string) => Promise<void>;
}) => {
  const inputRef = useRef<any>();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Modal open title={title} onCancel={onCancel} footer={null}>
      <p>{description}</p>
      <Input.OTP ref={inputRef} length={CODE_LENGTH} onChange={onComplete} />
    </Modal>
  );
};

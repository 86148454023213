import { Level, Sensor, Value } from "../types";

export const getSensorReduxId = (deviceId: string, key: string) => `${deviceId}/${key}`;
export const getReduxId = (sensor: Sensor) => getSensorReduxId(sensor.deviceId, sensor.key);

export type RequestType = {
  start: number;
  end: number;
  startISO: string; // for debugging
  endISO: string;
  loading: boolean;
  error: boolean;
};

export type SensorValueType = {
  deviceId: string;
  key: string;
  values: {
    [level in Level]: Value[];
  };
  requests: {
    [level in Level]: RequestType[];
  };
};

export type ValueState = {
  sensors: {
    [deviceIdAndKey: string]: SensorValueType;
  };
};

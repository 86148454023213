import { AvgValue, KeyType2, PlainValue, Sensor } from "../../store/types";
import { useValues } from "../../store/values/useValues";
import { humanizeValueStr } from "../../store/utils";
import { SensorBrowserActions, SensorBrowserState } from "./reducer";
import styled from "styled-components";
import { GraphType, Highlight } from "../../components/Graph/types";
import { getColor } from "../../components/Graph/utils";
import { Dispatch, useCallback } from "react";
import { ONE_MINUTE } from "../../store/values/constants";
import { TinyGraph } from "../../components/Graph/TinyGraph";

const ValueColumnTotal = ({
  sensor,
  sensorBrowserState,
}: {
  sensor: Sensor;
  sensorBrowserState: SensorBrowserState;
}): JSX.Element => {
  const values = useValues(
    sensor,
    sensorBrowserState.level,
    sensorBrowserState.highlight?.interval || sensorBrowserState.interval,
    sensorBrowserState.viewType,
    ONE_MINUTE
  ) as PlainValue[];
  const total = values.reduce((prev, curr) => prev + curr.value, 0);
  if (values.length === 0 || !sensor.keyType) {
    return <> </>;
  }

  return <>{humanizeValueStr(total, total, sensor.keyType)}</>;
};

const ValueColumnAvg = ({
  sensor,
  sensorBrowserState,
}: {
  sensor: Sensor;
  sensorBrowserState: SensorBrowserState;
}): JSX.Element => {
  const values = useValues(
    sensor,
    sensorBrowserState.level,
    sensorBrowserState.highlight?.interval || sensorBrowserState.interval,
    sensorBrowserState.viewType,
    ONE_MINUTE
  ) as AvgValue[];

  if (values.length === 0 || !sensor.keyType) {
    return <> </>;
  }
  const value = values[0];
  if (!sensorBrowserState.highlight?.interval.equals(value.interval)) {
    return <> </>;
  }
  if (!value.samples) {
    return <> </>;
  }

  return <>{humanizeValueStr(value.avg, value.avg, sensor.keyType)}</>;
};

export const ValueColumn = ({
  data,
  sensorBrowserState,
  sensorBrowserDispatch,
}: {
  data: Sensor;
  sensorBrowserState: SensorBrowserState;
  sensorBrowserDispatch: Dispatch<SensorBrowserActions>;
}): JSX.Element | null => {
  const sensor = data;

  const handleHighlight = useCallback(
    (highlight?: Highlight) => sensorBrowserDispatch({ type: "setHighlight", payload: highlight }),
    [sensorBrowserDispatch]
  );

  return (
    <Layout>
      <TinyGraph
        key={sensorBrowserState.viewType}
        interval={sensorBrowserState.interval}
        level={sensorBrowserState.level}
        width={150}
        height={41}
        series={[
          {
            sensor,
            type: sensor.keyType2 === KeyType2.TimeSeries ? GraphType.Area : GraphType.Bar,
            color: getColor(sensor.keyType),
            scaleSide: "left",
          },
        ]}
        viewType={sensorBrowserState.viewType}
        onHighlight={handleHighlight}
        highlight={sensorBrowserState.highlight}
      />
      {sensor.keyType2 === KeyType2.Accumulating && (
        <ValueColumnTotal sensorBrowserState={sensorBrowserState} sensor={sensor} />
      )}
      {sensor.keyType2 === KeyType2.TimeSeries && (
        <ValueColumnAvg sensorBrowserState={sensorBrowserState} sensor={sensor} />
      )}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
`;

import { Interval } from "luxon";
import { KeyType, Level, ViewType } from "../../store/types";
import { Highlight } from "../../components/Graph/types";

export type TimeframeType = "24h" | "30d" | "30d-min" | "12m" | "20y";

export type SensorBrowserState = {
  organizationId: string | null;
  userId: number | null;
  interval: Interval;
  level: Level;
  timeframe: TimeframeType;
  highlight?: Highlight;
  viewType: ViewType;
  sensorType?: KeyType;
};

export type SensorBrowserActions =
  | {
      type: "setOrganizationId";
      payload: string | null;
    }
  | {
      type: "setUserId";
      payload: number | null;
    }
  | {
      type: "setTimeframe";
      payload: { interval: Interval; level: Level; timeframe: TimeframeType; viewType: ViewType };
    }
  | {
      type: "setHighlight";
      payload?: Highlight;
    }
  | { type: "setSensorType"; payload?: KeyType };

export const reducer = (state: SensorBrowserState, action: SensorBrowserActions): SensorBrowserState => {
  const { type, payload } = action;
  switch (type) {
    case "setOrganizationId":
      return {
        ...state,
        organizationId: payload,
      };
    case "setUserId":
      return {
        ...state,
        userId: payload,
      };
    case "setTimeframe":
      const { interval, level, timeframe, viewType } = payload;
      return {
        ...state,
        interval,
        level,
        timeframe,
        viewType,
      };
    case "setHighlight":
      // optimize re-render
      if (payload && state.highlight?.interval.equals(payload?.interval)) {
        return state;
      }
      return {
        ...state,
        highlight: payload,
      };
    case "setSensorType":
      return { ...state, sensorType: payload };
    default:
      return state;
  }
};

import { Interval } from "luxon";
import { Level, ViewType } from "../../store/types";
import { Highlight, SeriesOptions } from "./types";
import React from "react";
import { Graph } from "./Graph";

interface TinyGraphProps {
  interval: Interval;
  level: Level;
  width: number;
  height: number;
  series: SeriesOptions[];
  onHighlight: (highlight?: Highlight) => void;
  highlight?: Highlight;
  viewType: ViewType;
}

export const TinyGraph = ({
  interval,
  level,
  width,
  height,
  series,
  onHighlight,
  highlight,
  viewType,
}: TinyGraphProps): JSX.Element => {
  return (
    <Graph
      interval={interval}
      level={level}
      lockToNow={false}
      setLockToNow={() => undefined}
      height={height}
      width={width}
      mode={"tiny"}
      series={series}
      onHighlight={onHighlight}
      viewType={viewType}
      highlight={highlight}
    />
  );
};

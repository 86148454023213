import { ContentCard } from "../../../../../utils/styled";
import { Button, message, Tag, Modal } from "antd";
import { Setting, SETTING_TYPE_NAME } from "../../../../../store/types";
import { useCallback, useState } from "react";
import { SettingEditorCardForm } from "./SettingEditorCardForm";
import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import styled from "styled-components";
import { useDeleteSettingMutation, useEditSettingMutation } from "../../../../../store/api";
import { DateTime } from "luxon";
const { confirm } = Modal;

export const SettingEditorCard = ({ setting }: { setting: Setting }) => {
  const [dirty, setDirty] = useState<Setting>();
  const [editSetting] = useEditSettingMutation();
  const [deleteSetting] = useDeleteSettingMutation();

  // Use this to reset the internal state of the form on cancel
  const [reset, setReset] = useState(0);

  const handleReset = useCallback(() => {
    setDirty(undefined);
    setReset((prev) => prev + 1);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!dirty) {
      return;
    }
    try {
      await editSetting({ deviceId: setting.deviceId, name: setting.name, value: dirty.value }).unwrap();
      handleReset();
      message.success("Asetus tallennettu");
    } catch (err) {
      console.error("Error saving setting", err);
    }
  }, [dirty, editSetting, handleReset, setting.deviceId, setting.name]);

  const handleDelete = useCallback(() => {
    confirm({
      title: "Asetuksen poistaminen",
      icon: <ExclamationCircleFilled />,
      content: "Asetuksen poistamisesta ei mene mitään tietoa laitteelle. Haluatko varmasti jatkaa?",
      onOk: async () => {
        try {
          await deleteSetting({ deviceId: setting.deviceId, name: setting.name });
          message.success("Asetus poistettu");
        } catch (err) {
          console.error(err);
        }
      },
      okType: "danger",
    });
  }, [deleteSetting, setting.deviceId, setting.name]);

  const receivedByDevice =
    setting.deviceReadTime && DateTime.fromISO(setting.deviceReadTime) > DateTime.fromISO(setting.updatedTime);

  return (
    <ContentCard
      title={SETTING_TYPE_NAME[setting.settingType]}
      extra={
        <span style={{ display: "flex", gap: "10px" }}>
          {receivedByDevice ? (
            <BigTag icon={<ClockCircleOutlined />} color="success">
              Tallennettu laitteelle
            </BigTag>
          ) : (
            <BigTag icon={<CheckCircleOutlined />} color="default">
              Odottaa laitetta
            </BigTag>
          )}
          <Button danger onClick={handleDelete}>
            Poista
          </Button>
          <Button disabled={!dirty} onClick={handleReset}>
            Peruuta
          </Button>
          <Button disabled={!dirty} onClick={handleSubmit} type="primary" htmlType="submit">
            Tallenna
          </Button>
        </span>
      }
    >
      <SettingEditorCardForm
        key={reset}
        setting={dirty || setting}
        onChange={(value: any) => {
          setDirty({ ...setting, value });
        }}
        mode={"admin"}
      />
    </ContentCard>
  );
};

const BigTag = styled(Tag)`
  display: flex;
  align-items: center;
`;

import styled from "styled-components";
import { Card, Breadcrumb } from "antd";

export const FullSizeLayout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentCard = styled(Card)`
  width: 100%;
`;

ContentCard.defaultProps = {
  bordered: false,
};

export const LayoutContentGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  margin-top: 33px;
`;

export const LayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 33px;
  padding-bottom: 33px;
`;

export const BreadcrumbInCardTitle = styled(Breadcrumb)`
  padding-left: 4px;
`;

import { CheckOutlined } from "@ant-design/icons";
import { orgTypeString } from "../organizations/constants";
import { DateTime, Duration } from "luxon";
const humanizeDuration = require("humanize-duration");

export const dateRenderer = (cell: { value: any }): string =>
  cell.value ? DateTime.fromISO(cell.value).toLocaleString() : "";

export const dateTimeRenderer = (cell: { value: any }): string =>
  cell.value ? DateTime.fromISO(cell.value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : "";

export const checkRenderer = (cell: any) => (cell.value ? <CheckOutlined /> : null);

export const addressRenderer = (cell: any): string =>
  cell.data.address1 + (cell.data.address1 && cell.data.address2 && ", ") + cell.data.address2;

export const orgTypeRenderer = (cell: any): string => orgTypeString(cell.value);

export const durationRendererISO = (cell: { value: any }): string => {
  if (!cell.value) {
    return "";
  }
  const diffMs = DateTime.fromISO(cell.value).diffNow().milliseconds;
  return humanizeDuration(diffMs, {
    language: "fi",
    largest: 2,
    units: ["y", "mo", "w", "d", "h", "m"],
    round: true,
  });
};

export const durationRendererSeconds = (cell: { value: any }): string => {
  if (!cell.value) {
    return "";
  }
  const diffMs = Duration.fromMillis(cell.value * 1000).milliseconds;
  return humanizeDuration(diffMs, {
    language: "fi",
    largest: 1,
    units: ["y", "mo", "w", "d", "h", "m", "s"],
    round: true,
  });
};

export const LinkCell = ({ children, href }: { children: React.ReactNode; href: string }): JSX.Element => (
  <a
    style={{
      width: "100%",
      height: "100%",
      display: "block",
      paddingLeft: 17,
      paddingRight: 17,
    }}
    href={href}
  >
    {children}
  </a>
);

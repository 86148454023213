import { humanizeValue } from "../../../store/utils";
import { GraphState } from "../reducer";
import { ScaleSide } from "../types";
import { getY } from "../utils";
import { AXIS_PADDING } from "./axis";
import { KeyType2, Level } from "../../../store/types";
import { AXIS_LINE_COLOR } from "./constants";

export const renderYAxis = (
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  graphState: GraphState,
  scaleSide: ScaleSide
): void => {
  ctx.beginPath();

  ctx.strokeStyle = AXIS_LINE_COLOR;
  ctx.textAlign = "right";
  ctx.textBaseline = "middle";
  ctx.fillStyle = "black";

  const series = graphState.series.find((s) => s.scaleSide === scaleSide);
  if (!series || !series.sensor.keyType) {
    return;
  }

  const maxSteps = 10;

  for (
    let yValue = graphState.yAxis[scaleSide].start;
    yValue < graphState.yMaxValue[scaleSide];
    yValue += graphState.yAxis[scaleSide].step
  ) {
    const yPixel = getY(graphState, scaleSide, yValue);
    if (yPixel > graphState.height - graphState.padding.bottom) {
      // too low to draw
      continue;
    }
    ctx.moveTo(graphState.padding.left, yPixel);
    ctx.lineTo(graphState.width - graphState.padding.right, yPixel);

    // only write the zero marker if needed but always write all other axis markers
    if (yValue !== 0 || (yValue === 0 && graphState.yAxis[scaleSide].start < 0)) {
      const [value] = humanizeValue(yValue, graphState.yAxis[scaleSide].step * maxSteps, series.sensor.keyType);
      ctx.fillText(value, graphState.padding.left - AXIS_PADDING, yPixel, graphState.padding.left - AXIS_PADDING);
    }
  }

  ctx.stroke();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [_, unit] = humanizeValue(
    graphState.yAxis[scaleSide].step * maxSteps,
    graphState.yAxis[scaleSide].step * maxSteps,
    series.sensor.keyType
  );

  if (graphState.level === Level.raw && series.sensor.keyType2 === KeyType2.Accumulating) {
    unit = `${unit}/min`;
  }

  ctx.beginPath();
  ctx.textAlign = "right";
  ctx.textBaseline = "top";
  ctx.fillText(unit, graphState.padding.left - AXIS_PADDING, 0);
};

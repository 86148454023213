import { Sensor, Session } from "../../../../../store/types";
import { Button, InputNumber, message, Modal } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useMultiplySessionMutation } from "../../../../../store/api";

export const EditModal = ({
  sessions,
  onClose,
  sensor,
}: {
  sessions: Session[];
  onClose: () => void;
  sensor: Sensor;
}) => {
  const [factor, setFactor] = useState<number | null>(0);

  const valid = useMemo(() => {
    if (!factor) {
      return false;
    }
    if (factor > 100 || factor < -100) {
      return false;
    }
    if (factor > -0.01 && factor < 0.01) {
      return false;
    }
    return true;
  }, [factor]);

  const [multiplySession] = useMultiplySessionMutation();
  const handleEdit = useCallback(async () => {
    try {
      if (!factor) {
        return;
      }
      for (const s of sessions) {
        await multiplySession({ keyId: s.keyId, sessionId: s.sessionId, factor });
      }
      message.success("Muokkaus suoritettu");
    } catch (err) {
      console.log("Multiply sessions: ", err);
    }
    onClose();
  }, [factor, multiplySession, onClose, sessions]);

  return (
    <Modal
      open
      title={"Muokkaa lukemia"}
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose}>Peruuta</Button>
          <Button onClick={handleEdit} disabled={!valid} type={"primary"}>
            Muokkaa
          </Button>
        </>
      }
    >
      <p>
        Muokataan laitteen <b>{sensor.deviceId}</b> sensorin <b>{sensor.key}</b> lukemia
      </p>
      <p>
        Valittujen sessioiden lukemat kerrotaan kerrointermillä. Kertoimen on oltava välillä 100 .. -100 ja vähintään
        0.01 .. -0.01
      </p>
      <p>
        <b>HUOM</b> Muokkaus kirjoittaa lukemien arvot uusiksi tietokantaan. Tarkoitettu vain historiallisten sessioden
        muokkaamiseen. tietokantaan. Älä koskaan muokkaa keskeneräistä sessiota.
      </p>
      <InputNumber addonBefore={"×"} defaultValue={factor as number} onChange={setFactor} />
    </Modal>
  );
};

export const devicesBaseRoute = "/devices";
export const getDeviceRoute = (deviceId: string) => `${devicesBaseRoute}/${deviceId}`;

export const reportsRoute = "/reports";

export const profileBaseRoute = "/profile";

// Admin routes
export const adminDeviceBaseRoute = "/admin/devices";
export const getAdminDeviceRoute = (deviceId: string) => `${adminDeviceBaseRoute}/${deviceId}`;
export const getAdminDeviceInfoRoute = (deviceId: string) => `${getAdminDeviceRoute(deviceId)}/info`;

export const adminOrganizationsBaseRoute = "/admin/organizations";
export const getAdminOrganizationRoute = (organizationId: string) => `${adminOrganizationsBaseRoute}/${organizationId}`;
export const getAdminOrganizationInfoRoute = (organizationId: string) =>
  `${getAdminOrganizationRoute(organizationId)}/info`;

export const adminUserBaseRoute = "/admin/users";
export const getAdminUserRoute = (userId: string) => `${adminUserBaseRoute}/${userId}`;
export const getAdminUserProfileRoute = (userId: string) => `${getAdminUserRoute(userId)}/profile`;

export const adminConfigurationBaseRoute = "/admin/configuration";

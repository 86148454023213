import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export const LinkButton = ({ path, text }: { path: string; text: string }) => {
  const navigate = useNavigate();
  return (
    <Button
      block
      type="link"
      href={path}
      onClick={(e) => {
        e.preventDefault();
        navigate(path);
      }}
      style={{ textDecoration: "underline" }}
    >
      {text}
    </Button>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import { Button, Input } from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { ColDef, GetRowIdFunc, GridApi, GridPreDestroyedEvent, IRowNode, RowClickedEvent } from "ag-grid-community";

import { useDebounce } from "../../utils/useDebounce";
import { isMobileOrTablet } from "../../utils/mobile";
import { TableState } from "./interfaces";

export const Table = <T extends unknown>({
  rowData,
  multiSelect,
  counterText,
  onAdd,
  loading,
  onSave,
  isSelected,
  onSelect,
  toolbar,
  onGridReady,
  onRowClicked,
  columns,
  getRowId,
  tableState,
}: {
  rowData: T[] | undefined;
  counterText?: string;
  multiSelect?: boolean;
  isSelected?: (item: T) => boolean;
  onSelect?: (item: T[]) => void;
  onAdd?: () => void;
  onSave?: (item: T[]) => Promise<void>;
  loading?: boolean;
  toolbar?: React.ReactNode | React.ReactNode[];
  onGridReady?: (api: GridApi) => void;
  onRowClicked?: (event: RowClickedEvent<T>) => void;
  columns: ColDef<T>[];
  getRowId?: GetRowIdFunc<T>;

  // Parent component may persist the state of the grid in case it is not constantly rendered
  tableState?: React.MutableRefObject<TableState | undefined>;
}) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rowCount, setRowCount] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);
  const [saving, setSaving] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [search, setSearch] = useState(tableState?.current?.quickSearch);
  const debouncedSearch = useDebounce(search, 300);

  const updateData = () => {
    if (!gridApi) {
      return;
    }
    onSelect?.(gridApi.getSelectedRows());
    setRowCount(gridApi.getDisplayedRowCount() || 0);
    setSelectedCount(gridApi.getSelectedRows().length || 0);
  };

  const selectedComparator = useCallback(
    (a: any, b: any, nodeA: IRowNode<T>, nodeB: IRowNode<T>, isDescending: boolean) => {
      if (nodeA.isSelected() === nodeB.isSelected()) {
        return 0;
      }
      if (isDescending) {
        if (nodeA.isSelected()) {
          return -1;
        } else {
          return 1;
        }
      } else {
        if (nodeB.isSelected()) {
          return 1;
        } else {
          return -1;
        }
      }
    },
    []
  );

  // Mark rows as selected at specific points such as creation.
  // Note that this does not allow the table to work as controlled element together with onSelect,
  // because that would create a loop
  useEffect(() => {
    if (!isSelected || loading) {
      return;
    }
    gridApi?.forEachNode((node) => {
      node.setSelected(!!isSelected?.(node.data));
    });
  }, [gridApi, isSelected, loading]);

  const handleSave = async () => {
    if (!gridApi || !onSave) return;
    setSaving(true);
    await onSave(gridApi.getSelectedRows());
    setSaving(false);
    setDirty(false);
  };

  useEffect(() => {
    if (loading || saving) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [loading, saving, gridApi]);

  // It seems that some column fields may override initialState,
  // so strip those away
  // NOTE hopefully this would be handled by ag grid itself
  const columnsWithoutConflict = useMemo(() => {
    if (!tableState?.current) {
      return columns;
    }
    return columns.map((column) => ({
      ...column,

      // Set these to undefined, so that they can be set by initialState instead
      sort: undefined,
    }));
  }, [columns, tableState]);

  const columnDefs = useMemo<ColDef<T>[]>(() => {
    if (multiSelect) {
      return [
        {
          headerName: "Valinta",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          width: 140,
          comparator: selectedComparator,
        },
        ...columnsWithoutConflict,
      ];
    }
    return columnsWithoutConflict;
  }, [multiSelect, columnsWithoutConflict, selectedComparator]);

  const autoFocus = useMemo(() => !isMobileOrTablet(), []);

  const handleGridPreDestroyed = useCallback(
    (event: GridPreDestroyedEvent<T>) => {
      if (!tableState) {
        return;
      }
      tableState.current = {
        gridState: { partialColumnState: true, sort: event.state.sort, scroll: event.state.scroll },
        quickSearch: debouncedSearch,
      };
    },
    [debouncedSearch, tableState]
  );

  return (
    <Layout>
      <Toolbar>
        <SearchInput
          defaultValue={tableState?.current?.quickSearch}
          autoFocus={autoFocus}
          allowClear
          placeholder={"haku"}
          onChange={(e) => setSearch(e.target.value)}
        />
        {onAdd && <Button type="primary" icon={<PlusOutlined />} onClick={onAdd} />}
        {onSave && (
          <Button type="primary" onClick={handleSave} loading={saving} disabled={!dirty} icon={<SaveOutlined />}>
            Tallenna
          </Button>
        )}
        {toolbar}
        <span>
          {counterText || "Rivejä"}: <strong>{rowCount}</strong>
        </span>
        {multiSelect && (
          <span>
            Valittu: <strong>{selectedCount}</strong>
          </span>
        )}
      </Toolbar>
      <AgGridReact
        initialState={tableState?.current?.gridState}
        onGridPreDestroyed={handleGridPreDestroyed}
        animateRows
        quickFilterText={debouncedSearch}
        getRowId={getRowId}
        onRowClicked={onRowClicked}
        rowStyle={onRowClicked && { cursor: "pointer" }}
        className="ag-theme-alpine"
        rowData={rowData}
        onGridReady={(params) => {
          setGridApi(params.api);
          onGridReady?.(params.api);
        }}
        enableCellTextSelection
        ensureDomOrder
        onModelUpdated={updateData}
        onFirstDataRendered={updateData}
        onSelectionChanged={() => {
          setDirty(true);
          updateData();
        }}
        rowSelection="multiple"
        suppressRowClickSelection
        defaultColDef={{
          sortable: true,
          suppressMovable: true,
          resizable: true,
          wrapText: true,
          autoHeight: true,
          width: 120,
        }}
        columnDefs={columnDefs}
        includeHiddenColumnsInQuickFilter
      />
    </Layout>
  );
};

const SearchInput = styled(Input)`
  max-width: 300px;
  background-color: white !important; // Fix transparent focus background bug in antd
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 10px;
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
`;

import { Interval } from "luxon";
import { Level, ValueTypes } from "../../store/types";
import { AnimationTarget, Padding, SeriesData } from "./types";
import produce from "immer";
import {
  getLevelIntervals,
  getVisibleValues,
  getXPixelFactor,
  getYAxisSteps,
  getYMinMax,
  getYPixelFactor,
} from "./utils";

export type GraphState = {
  width: number;
  height: number;
  padding: Padding;
  interval: Interval;
  animationTarget?: AnimationTarget;
  dragging: boolean;
  level: Level;
  series: SeriesData[];
  mode: "tiny" | "normal";
  yPixelFactor: {
    left: number;
    right: number;
  };
  xPixelFactor: number;
  yMinValue: {
    left: number;
    right: number;
  };
  yMaxValue: {
    left: number;
    right: number;
  };
  yAxis: {
    left: {
      start: number;
      step: number;
    };
    right: {
      start: number;
      step: number;
    };
  };
  levelIntervals: Interval[];
  rawLevelHighlightType: "value" | "raw";
};

export type GraphActions =
  | {
      type: "setDragging";
      payload: boolean;
    }
  | {
      type: "setPosition";
      payload: { level?: Level; interval?: Interval };
    }
  | {
      type: "setSize";
      payload: [number, number];
    }
  | {
      type: "setValues";
      payload: {
        id: number;
        level: Level;
        values: ValueTypes[];
      };
    }
  | {
      type: "setAnimationTarget";
      payload?: AnimationTarget;
    }
  | {
      type: "resetScale";
      payload: undefined;
    };

export const reducer = (state: GraphState, action: GraphActions): GraphState => {
  const { type, payload } = action;
  switch (type) {
    case "resetScale":
      return produce(state, (draft) => {
        draft.xPixelFactor = getXPixelFactor(draft.interval, draft.padding, draft.width);

        draft.series.forEach((s) => (s.visibleValues = getVisibleValues(s.values[draft.level], draft.interval)));

        const [leftMin, leftMax] = getYMinMax(draft.series, "left");
        draft.yMinValue["left"] = leftMin;
        draft.yMaxValue["left"] = leftMax;
        draft.yPixelFactor["left"] = getYPixelFactor(leftMin, leftMax, draft.height, draft.padding);

        const [rightMin, rightMax] = getYMinMax(draft.series, "right");
        draft.yMinValue["right"] = rightMin;
        draft.yMaxValue["right"] = rightMax;
        draft.yPixelFactor["right"] = getYPixelFactor(rightMin, rightMax, draft.height, draft.padding);
      });
    case "setSize":
      return produce(state, (draft) => {
        draft.width = payload[0];
        draft.height = payload[1];

        draft.xPixelFactor = getXPixelFactor(draft.interval, draft.padding, draft.width);

        draft.series.forEach((s) => (s.visibleValues = getVisibleValues(s.values[draft.level], draft.interval)));

        const [leftMin, leftMax] = getYMinMax(draft.series, "left");
        draft.yMinValue["left"] = leftMin;
        draft.yMaxValue["left"] = leftMax;
        draft.yPixelFactor["left"] = getYPixelFactor(leftMin, leftMax, draft.height, draft.padding);

        const [rightMin, rightMax] = getYMinMax(draft.series, "right");
        draft.yMinValue["right"] = rightMin;
        draft.yMaxValue["right"] = rightMax;
        draft.yPixelFactor["right"] = getYPixelFactor(rightMin, rightMax, draft.height, draft.padding);
      });
    case "setPosition":
      if (!payload.level && !payload.interval) {
        return state;
      }
      const level = payload.level ? payload.level : state.level;
      const interval = payload.interval ? payload.interval : state.interval;

      const resetYAxis = {
        yAxis: {
          left: {
            start: 0,
            step: 1,
          },
          right: {
            start: 0,
            step: 1,
          },
        },
        yPixelFactor: {
          left: 0,
          right: 0,
        },
        yMinValue: {
          left: 0,
          right: 0,
        },
        yMaxValue: {
          left: 0,
          right: 0,
        },
      };
      return {
        ...state,
        ...(state.level !== level && resetYAxis),
        level: level,
        interval: interval,
        levelIntervals: getLevelIntervals(level, interval, level === state.level ? state.levelIntervals : undefined),
        xPixelFactor: getXPixelFactor(interval, state.padding, state.width),
        series: state.series.map((s) => ({
          ...s,
          visibleValues: getVisibleValues(s.values[level], interval),
        })),
      };

    case "setValues":
      return produce(state, (draft) => {
        draft.series[payload.id].values[payload.level] = payload.values;

        draft.xPixelFactor = getXPixelFactor(state.interval, state.padding, state.width);

        draft.series.forEach((s) => (s.visibleValues = getVisibleValues(s.values[draft.level], draft.interval)));

        const scaleSide = draft.series[payload.id].scaleSide;

        // Don't update y axis while animating
        if (!state.animationTarget || state.yPixelFactor[scaleSide] === 0) {
          const [min, max] = getYMinMax(draft.series, draft.series[payload.id].scaleSide);

          // Never decrease the axis range automatically
          if (min < draft.yMinValue[scaleSide]) {
            draft.yMinValue[scaleSide] = min;
          }
          if (max > draft.yMaxValue[scaleSide]) {
            draft.yMaxValue[scaleSide] = max;
          }

          draft.yPixelFactor[scaleSide] = getYPixelFactor(
            draft.yMinValue[scaleSide],
            draft.yMaxValue[scaleSide],
            draft.height,
            draft.padding
          );

          draft.yAxis.left = getYAxisSteps(draft, "left");
          draft.yAxis.right = getYAxisSteps(draft, "right");
        }

        return draft;
      });
    case "setAnimationTarget":
      return produce(state, (draft) => {
        draft.animationTarget = payload;

        // reset y axis at the end of animation
        if (!draft.animationTarget) {
          const [leftMin, leftMax] = getYMinMax(draft.series, "left");

          // Never decrease the axis range automatically
          if (leftMin < draft.yMinValue.left) {
            draft.yMinValue.left = leftMin;
          }
          if (leftMax > draft.yMaxValue.left) {
            draft.yMaxValue.left = leftMax;
          }
          draft.yPixelFactor.left = getYPixelFactor(
            draft.yMinValue.left,
            draft.yMaxValue.left,
            draft.height,
            draft.padding
          );
          draft.yAxis.left = getYAxisSteps(draft, "left");

          const [rightMin, rightMax] = getYMinMax(draft.series, "right");

          // Never decrease the axis range automatically
          if (rightMin < draft.yMinValue.right) {
            draft.yMinValue.right = rightMin;
          }
          if (rightMax > draft.yMaxValue.right) {
            draft.yMaxValue.right = rightMax;
          }

          draft.yPixelFactor.right = getYPixelFactor(
            draft.yMinValue.right,
            draft.yMaxValue.right,
            draft.height,
            draft.padding
          );
          draft.yAxis.right = getYAxisSteps(draft, "right");
        }
      });

    case "setDragging":
      // reset y-axis at the end of animation
      return produce(state, (draft) => {
        draft.dragging = payload;

        if (!draft.dragging) {
          const [leftMin, leftMax] = getYMinMax(draft.series, "left");

          // Never decrease the axis range automatically
          if (leftMin < draft.yMinValue.left) {
            draft.yMinValue.left = leftMin;
          }
          if (leftMax > draft.yMaxValue.left) {
            draft.yMaxValue.left = leftMax;
          }
          draft.yPixelFactor.left = getYPixelFactor(
            draft.yMinValue.left,
            draft.yMaxValue.left,
            draft.height,
            draft.padding
          );
          draft.yAxis.left = getYAxisSteps(draft, "left");

          const [rightMin, rightMax] = getYMinMax(draft.series, "right");

          // Never decrease the axis range automatically
          if (rightMin < draft.yMinValue.right) {
            draft.yMinValue.right = rightMin;
          }
          if (rightMax > draft.yMaxValue.right) {
            draft.yMaxValue.right = rightMax;
          }

          draft.yPixelFactor.right = getYPixelFactor(
            draft.yMinValue.right,
            draft.yMaxValue.right,
            draft.height,
            draft.padding
          );
          draft.yAxis.right = getYAxisSteps(draft, "right");
        }
      });

    default:
      return state;
  }
};

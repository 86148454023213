import { Settings } from "luxon";
import { LOCALE } from "../constants";

declare module "luxon" {
  interface TSSettings {
    throwOnInvalid: true;
  }
}

export const setDefaultLocale = () => {
  Settings.throwOnInvalid = true;
  console.log("Current time zone on this machine is ", Intl.DateTimeFormat().resolvedOptions().timeZone);
  if (!["Europe/Helsinki", "Europe/Athens"].includes(Intl.DateTimeFormat().resolvedOptions().timeZone)) {
    console.log("Setting timezone manually to Europe/Helsinki. Performance might degrade");
    Settings.defaultZone = "Europe/Helsinki";
  }
  Settings.defaultLocale = LOCALE;
};

import React, { useEffect } from "react";
import { GraphActions, GraphState } from "./reducer";
import { SeriesData } from "./types";
import { ONE_MINUTE } from "../../store/values/constants";
import { useFetchValues } from "../../store/values/useFetchValues";
import { useLevelValues } from "../../store/values/useLevelValues";
import { ViewType } from "../../store/types";

export const Loader = ({
  series,
  graphState,
  graphDispatch,
  viewType,
}: {
  series: SeriesData;
  graphState: GraphState;
  graphDispatch: React.Dispatch<GraphActions>;
  viewType: ViewType;
}) => {
  // This is to make immediate requests for animation target
  useFetchValues(
    series.sensor,
    graphState.animationTarget?.level,
    graphState.animationTarget?.interval,
    viewType,
    ONE_MINUTE
  );

  // This goes trough debouncer to smooth out requests on scroll
  const values = useLevelValues(
    series.sensor,
    graphState.level,
    graphState.interval,
    viewType,
    ONE_MINUTE,
    graphState.mode === "normal"
  );

  // Copy values from redux to local graph state
  useEffect(() => {
    graphDispatch({
      type: "setValues",
      payload: { id: series.id, values: values, level: graphState.level },
    });
  }, [graphDispatch, graphState.level, series.id, values]);

  return null;
};

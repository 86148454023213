import React, { useRef } from "react";
import { render } from "./render/render";
import { GraphState } from "./reducer";
import { renderHighlight } from "./render/renderHighlight";
import { Highlight } from "./types";

export const useRender = (
  graphState: GraphState,
  overlayRef: React.RefObject<HTMLCanvasElement>,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  underlayRef: React.RefObject<HTMLCanvasElement>,
  highlight?: Highlight
) => {
  const prevState = useRef<GraphState | undefined>(undefined);
  const prevHighlight = useRef<Highlight | undefined>(undefined);

  // Wait until graph state has been somewhat initialized before considering to render
  if (graphState.xPixelFactor) {
    // Render directly without effect
    if (graphState.animationTarget) {
      // Only react to interval changes during animations
      if (prevState.current?.interval !== graphState.interval) {
        // Animation should already run within animation frame
        render(canvasRef.current, graphState, window.devicePixelRatio);
      }
    } else {
      if (prevState.current !== graphState) {
        window.requestAnimationFrame(() => {
          render(canvasRef.current, graphState, window.devicePixelRatio);
        });
      }

      if (prevHighlight.current !== highlight) {
        window.requestAnimationFrame(() => {
          renderHighlight(overlayRef.current, underlayRef.current, graphState, highlight);
        });
      }
    }
    prevState.current = graphState;
    prevHighlight.current = highlight;
  }
};

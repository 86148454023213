import { Form, Input, message, Modal, Select } from "antd";
import { SETTING_TYPE_DEFAULT, SETTING_TYPE_NAME, SettingType } from "../../../../../store/types";
import { SettingEditorCardFormInputString } from "./SettingEditorCardFormInputString";
import { SettingEditorCardFormInputInteger } from "./SettingEditorCardFormInputInteger";
import { useCallback, useState } from "react";
import { useAddSettingMutation } from "../../../../../store/api";

export const SettingEditorAddSetting = ({ deviceId, onClose }: { deviceId: string; onClose: () => void }) => {
  const [settingType, setSettingType] = useState<SettingType>();
  const [name, setName] = useState("");
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [addSetting] = useAddSettingMutation();

  const settingTypeOptions = Object.values(SettingType)
    .filter((v) => !isNaN(Number(v)))
    .map((type) => ({
      value: type,
      label: SETTING_TYPE_NAME[type as unknown as SettingType],
    }));

  const handleSubmit = useCallback(async () => {
    if (!name || !settingType) {
      return;
    }

    setLoading(true);
    try {
      await addSetting({ deviceId, settingType, name, value }).unwrap();
      message.success("Asetus tallennettu");
      onClose();
    } catch (err) {
      console.error("Error saving setting", err);
    }
    setLoading(false);
  }, [addSetting, deviceId, name, onClose, settingType, value]);

  return (
    <Modal open title={"Lisää uusi asetus"} onCancel={onClose} onOk={handleSubmit} confirmLoading={loading}>
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} size={"small"}>
        <Form.Item label={"Tyyppi"} name={"value"}>
          <Select
            options={settingTypeOptions}
            style={{ width: 200 }}
            onSelect={(type: SettingType) => {
              setSettingType(type);
              setValue(SETTING_TYPE_DEFAULT[type] as any);
            }}
          />
        </Form.Item>
        <Form.Item label={"Nimi"}>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        {!!settingType && (
          <Form.Item label={"Arvo"}>
            {settingType === SettingType.SettingTypeString && (
              <SettingEditorCardFormInputString value={value} onChange={(value) => setValue(value)} />
            )}
            {settingType === SettingType.SettingTypeInteger && (
              <SettingEditorCardFormInputInteger value={value} onChange={(value) => setValue(value)} />
            )}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
